////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PropertiesAddForm.jsx                        //
//  Application:  Properties                              //
//  Option: For add Property                              //
//  Developer: NP                                         //
//  Date: 2022-02-22                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form } from "react-bootstrap";
// import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import PropertyTypeService from "../../services/PropertyTypeService";
import TimezoneService from "../../services/TimezoneService";
import PropertyService from "../../services/PropertyService";
import BrandService from "../../services/BrandService";
import PropertySuitabilityService from "../../services/PropertySuitabilityService";
import SmokingTypeService from "../../services/SmokingTypeService";
import LeedBuildingService from "../../services/LeedBuildingService";
import LocationTypeService from "../../services/LocationTypeService";
import CityService from "../../services/CityService";
import CommServedService from "../../services/CommServedService";
import OwnershipTypeService from "../../services/OwnershipTypeService";
import ParkingTypeService from "../../services/ParkingTypeService";
import propertyLogo from "./../../assets/images/property_placeholder.png";
import MasterDataService from "../../services/MasterDataService";
import UserService from "../../services/UserService";
import Alerts from "../common/Alerts";
// import infosize from "../../assets/images/Info.svg";
import FileWidget from "../fileUploader/FileWidget";
import Select from "react-select";
import propertyImg from "./../../assets/images/PropPlaceMainImg.png";
import PhoneInput from "react-phone-input-2";
import DatePicker from "react-datepicker";
import Moment from "moment";
import ClubService from "../../services/ClubService";
export class PropertiesAddForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      deletedFileList: [],
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      propertyList: [],
      timeZoneList: [],
      newTimeZoneList: [],
      selectedTimeZoneList: [],
      brandList: [],
      newBrandList: [],
      newClubList: [],
      selectedBrandList: [],
      selectedClubList: [],
      suitabilityList: [],
      parkingTypeList: [],
      newParkingTypeList: [],
      selectedParkingTypeList: [],
      ownershipTypeList: [],
      newOwnershipTypeList: [],
      ownershipTypeListTotal: 0,
      propertyListTotal: 0,
      brandListTotaL: 0,
      locationTypeListTotal: 0,
      commServedListTotal: 0,
      leedBuildingListTotal: 0,
      smokingTypeListTotal: 0,
      parkingTypeListTotal: 0,
      cityListTotal: 0,
      timeZoneListTotal: 0,
      selectedOwnershipTypeList: [],
      leedBuildingList: [],
      newLeedBuildingList: [],
      selectedLeedBuildingList: [],
      lockTypeList: global.lockTypeList,
      selectedLockTypeList: [],
      imgPropUrl: propertyImg,
      errors: {},
      slug: this.props.match.params.slug,
      property_slug: this.props.match.params.slug,
      view: this.props.match.params.view
        ? this.props.match.params.view
        : "Basic",
      isSubmit: false,
      property_logo: "",
      property_main_image: "",
      imageUrl: propertyLogo,
      status_id: false,
      sort_order: "",
      // checkbox
      // observer_day_ligth_saving: false,
      international_property: false,
      full_listing: false,
      insurance_required: false,
      affordable_financed: false,
      pet_allowed: false,
      children_friendly: false,
      infant_friendly: false,
      affordable_component_check: false,
      allow_direct_booking: true,
      appear_on_oh: true,
      //radio
      ada_compliant: 2,
      //multi select
      suitability_id: [],
      newSuitabilityList: [],
      selectedSuitabilityList: [],
      location_type_id: [],
      newLocationTypeList: [],
      selectedLocationTypeList: [],
      communities_served_id: [],
      newCommServedList: [],
      selectedCommServedList: [],
      affordable_type_id: [],
      confirm_back: 0,
      yearList: [],
      descShow: 0,
      descShowRules: 0,
      domain_name: "",
      pms_property_name: "",
      street_1: "",
      street_2: "",
      locationTypeList: [],
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      metroList: [],
      selectedMetroList: [],
      newMetroList: [],
      subMetroList: [],
      newSubMetroList: [],
      selectedSubMetroList: [],
      minimumAgeList: [],
      newMinimumAgeList: [],
      selectedMinimumAgeList: [],
      // orion_haus_account_manager: "",
      // orion_haus_email: "",
      // orion_haus_phone_number: "",
      // property_manager: "",
      // property_manager_email: "",
      // property_manager_phone_number: "",
      orion_haus_account_manager_slug: "",
      property_manager_slug: "",
      accountManagerList: [],
      newAccountManagerList: [],
      selectedAccountManagerList: [],
      selectedPropertyManagerList: [],
      property_office_data: [
        {
          day: 1,
          label: "Monday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 2,
          label: "Tuesday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 3,
          label: "Wednesday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 4,
          label: "Thursday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 5,
          label: "Friday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 6,
          label: "Saturday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
        {
          day: 7,
          label: "Sunday",
          start_time: "00:00 AM",
          end_time: "00:00 PM",
          closed: 0,
        },
      ],
      ayList: global.dayList,
      housekeeping_window_hour: 0,
      check_in_time: new Date(),
      check_out_time: new Date(),
      noiseaware_org_id: "",
      property_notes: "",
    };
    this.propertyLogo = React.createRef();
    this.propertyImg = React.createRef();
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateProperty = this.saveOrUpdateProperty.bind(this);
    this.onLogoImageChange = this.onLogoImageChange.bind(this);
    // this.onMainImageChange = this.onMainImageChange.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  async componentDidMount() {
    this.setState({ confirm_back: 0 });
    this.getOwnershipTypeData();
    this.getPropertyData();
    this.getBrandData();
    this.getClubData();
    this.getCommServedData();
    this.getTimezoneData();
    this.getSuitabilityData();
    this.getParkingTypeData();
    this.getCityData();
    this.getLocationTypeData();
    this.getLeedBuildingData();
    this.getSmokingTypeData();
    this.getMinimumAgeData();
    this.getUserList();
  }

  async getUserList() {
    //  this.setState({ showSpinner: true, loading: true });
    var accountManagerList = [];

    let res = await UserService.getUserDropdown(
      "roleTitle=OH_MANAGER&roleTitle=PROPERTY_MANAGER"
    );
    if (global.successStatus.includes(res.status)) {
      accountManagerList = res.data ? res.data : [];
    }
    const newAccountManagerList = accountManagerList.map(
      ({ slug, first_name, last_name }) => ({
        value: slug,
        label: first_name + " " + last_name,
      })
    );
    this.setState({
      newAccountManagerList: newAccountManagerList,
      accountManagerList: accountManagerList,
    });
    //  this.setState({ showSpinner: false, loading: false });
  }

  handleChangeAccountManagerList(value) {
    let orion_haus_account_manager_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        orion_haus_account_manager_slug.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedAccountManagerList: value,
      orion_haus_account_manager_slug: state_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangePropertyManagerList(value) {
    let property_manager_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_manager_slug.push(item.value);
      });
    }
    const state_id_value = value !== null ? value.value : [];
    this.setState({
      selectedPropertyManagerList: value,
      property_manager_slug: state_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  // onMainImageChange = async (event) => {
  //   if (event.target.files[0] !== undefined) {
  //     this.setState({ showSpinner: true });
  //     const fileData = new FormData();
  //     fileData.append(
  //       "property_main_image",
  //       event.target.files[0],
  //       event.target.files[0].name
  //     );
  //     let res = await PropertyService.uploadPropertyMainImg(fileData);
  //     if (global.successStatus.includes(res.status)) {
  //       this.setState({
  //         property_main_image: res.data.fileName,
  //         imgPropUrl: res.data.filePath,
  //       });
  //       this.setState({ showSpinner: false });
  //     } else if (res.status === 413) {
  //       this.setState({
  //         showSpinner: false,
  //         showAlertModal: true,
  //         alertModalType: "error",
  //         alertModalTitle: "Error!",
  //         alertModalMessage: "Content Too Large",
  //       });
  //     } else {
  //       let alertMessage = "";
  //       if (res.data.result.errorDetail !== null) {
  //         let errors = {};
  //         res.data.errorCode = "Validation Failed";
  //         res.data.result.errorDetail.forEach((item) => {
  //           errors[item.errorField] = item.errorMessage[0];
  //           // alertMessage += item.errorMessage[0]+'\n';
  //           res.data.errorMessage = "";
  //         });
  //         this.setState({ errors: errors });
  //       }
  //       this.setState({
  //         showSpinner: false,
  //         showAlertModal: true,
  //         alertModalType: "error",
  //         alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
  //         alertModalMessage:
  //           alertMessage !== "" ? alertMessage : res.data.message,
  //       });
  //     }
  //     setTimeout(
  //       () => this.setState({ showAlertModal: false }),
  //       global.alert_close_time
  //     );
  //   }
  // };

  onLogoImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "property_logo",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await PropertyService.uploadPropertyLogo(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          property_logo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  };

  async getPropertyData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var propertyList = [];
    var propertyListTotal = 0;
    let res = await PropertyTypeService.getPropertyType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      propertyList = res.data ? res.data : [];
      propertyListTotal = propertyList ? propertyList.length : 0;
    }
    const newPropertyList =
      propertyListTotal > 0
        ? propertyList.map(({ slug, property_type_name }) => ({
            value: slug,
            label: property_type_name,
          }))
        : [];
    this.setState({
      propertyList: propertyList,
      newPropertyList: newPropertyList,
      propertyListTotal: propertyListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getLocationTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var locationTypeList = [];
    var locationTypeListTotal = 0;
    let res = await LocationTypeService.getLocationType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      locationTypeList = res.data ? res.data : [];
      locationTypeListTotal = locationTypeList ? locationTypeList.length : 0;
    }
    const newLocationTypeList =
      locationTypeListTotal > 0
        ? locationTypeList.map(({ slug, location_type_name }) => ({
            value: slug,
            label: location_type_name,
          }))
        : [];
    this.setState({
      locationTypeList: locationTypeList,
      newLocationTypeList: newLocationTypeList,
      locationTypeListTotal: locationTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getOwnershipTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var ownershipTypeList = [];
    var ownershipTypeListTotal = 0;
    let res = await OwnershipTypeService.getOwnership(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      ownershipTypeList = res.data ? res.data : [];
      ownershipTypeListTotal = ownershipTypeList ? ownershipTypeList.length : 0;
    }
    const newOwnershipTypeList =
      ownershipTypeListTotal > 0
        ? ownershipTypeList.map(({ slug, ownership_type_name }) => ({
            value: slug,
            label: ownership_type_name,
          }))
        : [];
    this.setState({
      ownershipTypeList: ownershipTypeList,
      newOwnershipTypeList: newOwnershipTypeList,
      ownershipTypeListTotal: ownershipTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getTimezoneData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var timeZoneList = [];
    var timeZoneListTotal = 0;
    let res = await TimezoneService.getTimezone(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      timeZoneList = res.data ? res.data : [];
      timeZoneListTotal = timeZoneList ? timeZoneList.length : 0;
    }
    const newTimeZoneList =
      timeZoneListTotal > 0
        ? timeZoneList.map(({ slug, timezone_name }) => ({
            value: slug,
            label: timezone_name,
          }))
        : [];
    this.setState({
      timeZoneList: timeZoneList,
      newTimeZoneList: newTimeZoneList,
      timeZoneListTotal: timeZoneListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getBrandData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var brandList = [];
    var brandListTotaL = 0;
    let res = await BrandService.getBrandList(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      brandList = res.data ? res.data : [];
      brandListTotaL = brandList ? brandList.length : 0;
    }
    const newBrandList =
      brandListTotaL > 0
        ? brandList.map(({ slug, brand_name }) => ({
            value: slug,
            label: brand_name,
          }))
        : [];

    this.setState({
      brandList: brandList,
      newBrandList: newBrandList,
      brandListTotaL: brandListTotaL,
    });
    this.setState({ showSpinner: false, loading: false });
  }
  async getClubData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var clubList = [];
    var clubListTotaL = 0;
    let res = await ClubService.getClubist(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      clubList = res.data ? res.data : [];
      clubListTotaL = clubList ? clubList.length : 0;
    }
    const newClubList =
      clubListTotaL > 0
        ? clubList.map(({ slug, club_name }) => ({
            value: slug,
            label: club_name,
          }))
        : [];

    this.setState({
      clubList: clubList,
      newClubList: newClubList,
      clubListTotaL: clubListTotaL,
    });
    this.setState({ showSpinner: false, loading: false });
  }
  async getSuitabilityData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var suitabilityList = [];

    let res = await PropertySuitabilityService.getPropertySuitability(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      suitabilityList = res.data ? res.data : [];
    }
    const newSuitabilityList = suitabilityList.map(
      ({ slug, suitability_name }) => ({
        value: slug,
        label: suitability_name,
      })
    );
    this.setState({
      suitabilityList: suitabilityList,
      newSuitabilityList: newSuitabilityList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getParkingTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var parkingTypeList = [];
    var parkingTypeListTotal = 0;
    let res = await ParkingTypeService.getParkingType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      parkingTypeList = res.data ? res.data : [];
      parkingTypeListTotal = parkingTypeList ? parkingTypeList.length : 0;
    }
    const newParkingTypeList =
      parkingTypeListTotal > 0
        ? parkingTypeList.map(({ slug, parking_type_name }) => ({
            value: slug,
            label: parking_type_name,
          }))
        : [];
    this.setState({
      parkingTypeList: parkingTypeList,
      newParkingTypeList: newParkingTypeList,
      parkingTypeListTotal: parkingTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getMinimumAgeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var minimumAgeList = [];
    var minimumAgeListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=minimum_age",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      minimumAgeList = res.data ? res.data : [];
      minimumAgeListTotal = minimumAgeList ? minimumAgeList.length : 0;
    }
    const newMinimumAgeList =
      minimumAgeListTotal > 0
        ? minimumAgeList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      minimumAgeList: minimumAgeList,
      minimumAgeListTotal: minimumAgeListTotal,
      newMinimumAgeList: newMinimumAgeList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getLeedBuildingData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var leedBuildingList = [];
    var leedBuildingListTotal = 0;
    let res = await LeedBuildingService.getLeedBuilding(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      leedBuildingList = res.data ? res.data : [];
      leedBuildingListTotal = leedBuildingList ? leedBuildingList.length : 0;
    }
    const newLeedBuildingList =
      leedBuildingListTotal > 0
        ? leedBuildingList.map(({ slug, leed_building_name }) => ({
            value: slug,
            label: leed_building_name,
          }))
        : [];
    this.setState({
      leedBuildingList: leedBuildingList,
      newLeedBuildingList: newLeedBuildingList,
      leedBuildingListTotal: leedBuildingListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCommServedData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var commServedList = [];
    var commServedListTotal = 0;
    let res = await CommServedService.getCommServed(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      commServedList = res.data ? res.data : [];
      commServedListTotal = commServedList ? commServedList.length : 0;
    }
    const newCommServedList =
      commServedListTotal > 0
        ? commServedList.map(({ slug, communities_served_name }) => ({
            value: slug,
            label: communities_served_name,
          }))
        : [];
    this.setState({
      commServedList: commServedList,
      newCommServedList: newCommServedList,
      commServedListTotal: commServedListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getSmokingTypeData(queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var smokingTypeList = [];
    var smokingTypeListTotal = 0;
    let res = await SmokingTypeService.getSmokingType(
      "is_dropdown=1" + (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      smokingTypeList = res.data ? res.data : [];
      smokingTypeListTotal = smokingTypeList ? smokingTypeList.length : 0;
    }
    const newSmokingTypeList =
      smokingTypeListTotal > 0
        ? smokingTypeList.map(({ slug, smoking_type_name }) => ({
            value: slug,
            label: smoking_type_name,
          }))
        : [];
    this.setState({
      smokingTypeList: smokingTypeList,
      newSmokingTypeList: newSmokingTypeList,
      smokingTypeListTotal: smokingTypeListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getCityData(stateValue, countyValue, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var cityList = [];
    var cityListTotal = 0;
    let res = await CityService.getCity(
      "is_dropdown=1&state_slug=" + stateValue + "&county_slug=" + countyValue,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      cityList = res.data ? res.data : [];
      cityListTotal = cityList ? cityList.length : 0;
    }
    const newCityList =
      cityListTotal > 0
        ? cityList.map(({ slug, city_name }) => ({
            value: slug,
            label: city_name,
          }))
        : [];
    this.setState({
      cityList: cityList,
      newCityList: newCityList,
      cityListTotal: cityListTotal,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  async getAllRealetedData(slug, queryString = "") {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await CityService.getDataByCitySlug(
      slug,
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.country_id = res.data.countryData
        ? res.data.countryData.country.slug
        : "";
      resData.country_name = res.data.countryData
        ? res.data.countryData.country.country_name
        : "";
      var selectedCountryList = [];
      selectedCountryList = res.data.countryData.country
        ? [
            {
              value: res.data.countryData.country
                ? res.data.countryData.country.slug
                : "",
              label: res.data.countryData.country
                ? res.data.countryData.country.country_name
                : "",
            },
          ]
        : "";
      resData.county_id = res.data.county ? res.data.county.slug : "";
      var selectedCountyList = [];
      selectedCountyList = res.data.county
        ? [
            {
              value: res.data.county.slug ? res.data.county.slug : "",
              label: res.data.county.county_name
                ? res.data.county.county_name
                : "",
            },
          ]
        : "";
      resData.state_id = res.data.state ? res.data.state.slug : "";
      resData.state_name = res.data.state ? res.data.state.state_name : "";
      var selectedStateList = [];
      selectedStateList = res.data.state
        ? [
            {
              value: res.data.state.slug ? res.data.state.slug : "",
              label: res.data.state.state_name ? res.data.state.state_name : "",
            },
          ]
        : "";
    }
    this.setState(resData);
    this.setState({
      selectedStateList: selectedStateList,
      selectedCountyList: selectedCountyList,
      selectedCountryList: selectedCountryList,
    });
    this.setState({ showSpinner: false, loading: false });
  }

  saveOrUpdateProperty = async (e) => {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      // if (this.state.status_id === true ? this.validator.allValid() && this.customValidate() : !this.validator.errorMessages.property_name) {
      // if (!this.validator.errorMessages.property_name) {
      this.setState({ showSpinner: true, isSubmit: true });

      const FileWidgetObj = new FileWidget();
      const output = await FileWidgetObj.fileUploadFun("register_from");

      this.state.communities_served_id = this.state.selectedCommServedList
        ? this.state.selectedCommServedList.map(function (obj) {
            return obj.value;
          })
        : "";

      let multiCommServedString = this.state.communities_served_id
        ? this.state.communities_served_id.join(",")
        : "";

      this.state.location_type_id = this.state.selectedLocationTypeList
        ? this.state.selectedLocationTypeList.map(function (obj) {
            return obj.value;
          })
        : "";

      let multiLocationTypeString = this.state.location_type_id
        ? this.state.location_type_id.join(",")
        : "";

      let inputData = {
        tab: "basic",
        ownership_type_slug: this.state.ownership_type_id,
        brand_slug: this.state.brand_id,
        club_slug: this.state.club_id,
        communities_served_slug: multiCommServedString,
        property_name: this.state.property_name,
        aka_name: this.state.aka_name,
        domain_name: this.state.domain_name,
        pms_property_name: this.state.pms_property_name,
        lease_inquiry_url: this.state.lease_inquiry_url,
        property_type_slug: this.state.property_type_id,
        noiseaware_org_id: this.state.noiseaware_org_id,
        property_notes: this.state.property_notes,
        street_1: this.state.street_1,
        street_2: this.state.street_2,
        city_slug: this.state.city_id,
        zip_code: this.state.zip_code,
        country_slug: this.state.country_id,
        county_slug: this.state.county_id,
        state_slug: this.state.state_id,
        asset_managers: this.state.asset_managers,
        property_phone_number: this.state.property_phone_number,
        dial_code: this.state.dial_code,
        property_email: this.state.property_email,
        property_manager_name: this.state.property_manager_name,
        maintenance_email: this.state.maintenance_email,
        invoice_email: this.state.invoice_email,
        check_in_time: this.state.check_in_time
          ? Moment(this.state.check_in_time, "YYYY-MM-DD h:m:s A").format(
              "hh:mm A"
            )
          : "",
        check_out_time: this.state.check_out_time
          ? Moment(this.state.check_out_time, "YYYY-MM-DD h:m:s A").format(
              "hh:mm A"
            )
          : "",
        housekeeping_window_hour: this.state.housekeeping_window_hour
          ? this.state.housekeeping_window_hour
          : "",

        latitude: this.state.latitude,
        longitude: this.state.longitude,
        google_map_link: this.state.google_map_link,
        location_type_slug: multiLocationTypeString,
        timezone_slug: this.state.timezone_id,
        // observer_day_ligth_saving: this.state.observer_day_ligth_saving === true ? 1 : 2,
        insurance_required: this.state.insurance_required === true ? 1 : 0,
        international_property:
          this.state.international_property === true ? 1 : 2,

        year_value: this.state.year_value,
        leed_building_slug: this.state.leed_building_id,
        ada_compliant: this.state.ada_compliant,
        smoking_type_slug: this.state.smoking_type_id,
        parking_type_slug: this.state.parking_type_id,
        digital_lock: this.state.digital_lock === true ? 1 : 2,
        minimum_age: this.state.minimum_age ? this.state.minimum_age : "",

        affordable_financed: 0,
        affordable_component_check:
          this.state.affordable_component_check === true ? 1 : 0,
        total_affordable_unit: this.state.total_affordable_unit
          ? this.state.total_affordable_unit
          : "",

        allow_direct_booking: this.state.allow_direct_booking === true ? 1 : 0,
        appear_on_oh: this.state.appear_on_oh === true ? 1 : 0,

        // tax_id: this.state.tax_id,
        // full_listing: this.state.full_listing === true ? 1 : 2,
        // home_page_title: this.state.home_page_title,
        // suitability_slug: multiSuitabilityString,
        pet_allowed: this.state.pet_allowed === true ? 1 : 0,
        children_friendly: this.state.children_friendly === true ? 1 : 0,
        infant_friendly: this.state.infant_friendly === true ? 1 : 0,

        property_logo: this.state.property_logo,
        property_main_image: this.state.property_main_image,
        status_id: this.state.status_id === true ? 1 : 2, //hide status id for now
        // status_id: 2,
        sort_order: this.state.sort_order,
        availableFiles: output.response,
        deletedFiles: this.state.deletedFileList,

        // orion_haus_account_manager: this.state.orion_haus_account_manager,
        // orion_haus_email: this.state.orion_haus_email,
        // orion_haus_phone_number: this.state.orion_haus_phone_number,
        // property_manager: this.state.property_manager,
        // property_manager_email: this.state.property_manager_email,
        // property_manager_phone_number: this.state.property_manager_phone_number,
        orion_haus_account_manager_slug:
          this.state.orion_haus_account_manager_slug,
        // property_manager_slug: this.state.property_manager_slug,
        lock_type: this.state.lock_type ? this.state.lock_type : "",
        orionhaus_name: this.state.orionhaus_name
          ? this.state.orionhaus_name
          : "",
        orionhaus_email: this.state.orionhaus_email
          ? this.state.orionhaus_email
          : "",
        orionhaus_phone_number: this.state.orionhaus_phone_number
          ? this.state.orionhaus_phone_number
          : "",
      };

      let res = await PropertyService.createProperty(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        setTimeout(
          () => this.props.history.push("/properties"),
          global.redirect_time
        );
      } else {
        // let alertMessage = "";
        if (res.data.result.errorDetail) {
          let errors = {};
          res.data.result.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        // this.setState({
        //   showAlertModal: true,
        //   alertModalType: "error",
        //   alertModalTitle: res.data.message ? res.data.message : "Error!",
        //   alertModalMessage:
        //     alertMessage !== "" ? alertMessage : res.data.message,
        // });
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      //   this.validator.showMessageFor("property_name");
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event, index) => {
    let input = this.state.input;
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    value = event.target.type === "radio" ? parseInt(value) : value;

    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input: input });
    let property_office_data1 = this.state.property_office_data;
    if (
      event.target.name === "start_time" ||
      event.target.name === "end_time" ||
      event.target.name === "closed"
    ) {
      // let k = event.target.getAttribute("data-key");
      property_office_data1[index][event.target.name] = value;
    }
    this.setState({
      property_office_data: property_office_data1,
      // housekeeping_window_hour: hours,
    });

    // Validate
    this.validator.showMessageFor(event.target.name);
    this.customValidate();
    this.setState({ confirm_back: 1 });
  };

  changeHandlerCheckInTime(date) {
    this.setState({ check_in_time: date });
    var a = Moment(date, "YYYY-MM-DD h:m:s A").format("DD/MM/YYYY HH:mm:ss");
    var b = Moment(this.state.check_out_time, "YYYY-MM-DD h:m:s A").format(
      "DD/MM/YYYY HH:mm:ss"
    );

    var diff = Moment.utc(
      Moment(b, "DD/MM/YYYY HH:mm:ss").diff(Moment(a, "DD/MM/YYYY HH:mm:ss"))
    ).format("HH");
    var hwh = 24 - diff;
    if (!isNaN(hwh)) {
      this.setState({ housekeeping_window_hour: hwh });
    }
  }

  changeHandlerCheckOutTime(date) {
    this.setState({ check_out_time: date });
    var a = Moment(this.state.check_in_time, "YYYY-MM-DD h:m:s A").format(
      "DD/MM/YYYY HH:mm:ss"
    );
    var b = Moment(date, "YYYY-MM-DD h:m:s A").format("DD/MM/YYYY HH:mm:ss");

    var diff = Moment.utc(
      Moment(b, "DD/MM/YYYY HH:mm:ss").diff(Moment(a, "DD/MM/YYYY HH:mm:ss"))
    ).format("HH");
    var hwh = 24 - diff;
    if (!isNaN(hwh)) {
      this.setState({ housekeeping_window_hour: hwh });
    }
  }

  handleChangeSmokingTypeList(value) {
    let smoking_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        smoking_type_id.push(item.value);
      });
    }
    const smoking_type_id_value = value !== null ? value.value : [];
    this.setState({
      selectedSmokingTypeList: value,
      smoking_type_id: smoking_type_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeLockTypeList(value) {
    let lock_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        lock_type.push(item.value);
      });
    }
    this.setState({
      selectedLockTypeList: value,
      lock_type: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  onChangePhone(value, country, e, formattedValue) {
    //console.log(value, country, e, formattedValue);
    this.setState({ property_manager_phone_number: value });
    this.setState({ dial_code: country.dialCode });
  }
  handleChangeLocationTypeList(value) {
    let location_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        location_type_id.push(item.value);
      });
    }
    this.setState({
      selectedLocationTypeList: value,
      location_type_id: location_type_id,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeMinimumAgeList(value) {
    let minimum_age = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        minimum_age.push(item.value);
      });
    }
    const minimum_age_value = value !== null ? value.value : [];
    this.setState({
      selectedMinimumAgeList: value,
      minimum_age: minimum_age_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCityList(value) {
    let city_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        city_id.push(item.value);
      });
    }
    if (value !== null) {
      this.getAllRealetedData(value.value);
    }
    const city_id_value = value !== null ? value.value : [];
    this.setState({
      selectedCityList: value,
      city_id: city_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeCommServedList(value) {
    let communities_served_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        communities_served_id.push(item.value);
      });
    }
    this.setState({
      selectedCommServedList: value,
      communities_served_id: communities_served_id,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeOwnershipTypeList(value) {
    let ownership_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        ownership_type_id.push(item.value);
      });
    }
    const ownership_type_id_value = value !== null ? value.value : [];
    this.setState({
      selectedOwnershipTypeList: value,
      ownership_type_id: ownership_type_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeLeedBuildingList(value) {
    let leed_building_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        leed_building_id.push(item.value);
      });
    }
    const leed_building_id_value = value !== null ? value.value : [];
    this.setState({
      selectedLeedBuildingList: value,
      leed_building_id: leed_building_id_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangePropertyList(value) {
    let property_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        property_type_id.push(item.value);
      });
    }
    this.setState({
      selectedPropertyList: value,
      property_type_id: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeBrandList(value) {
    let brand_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        brand_id.push(item.value);
      });
    }
    this.setState({
      selectedBrandList: value,
      brand_id: value.value,
    });
    this.setState({ confirm_back: 1 });
  }
  handleChangeClubList(value) {
    let club_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        club_id.push(item.value);
      });
    }
    this.setState({
      selectedClubList: value,
      club_id: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeParkingTypeList(value) {
    let parking_type_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        parking_type_id.push(item.value);
      });
    }
    this.setState({
      selectedParkingTypeList: value,
      parking_type_id: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeTimeZoneList(value) {
    let timezone_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        timezone_id.push(item.value);
      });
    }
    this.setState({
      selectedTimeZoneList: value,
      timezone_id: value.value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeSuitabilityList(value) {
    let suitability_id = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        suitability_id.push(item.value);
      });
    }
    this.setState({
      selectedSuitabilityList: value,
      suitability_id: suitability_id,
    });
    this.setState({ confirm_back: 1 });
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("property_name");
    }
  }

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.year_value !== undefined &&
      input.year_value !== "" &&
      input.year_value !== null &&
      input.year_value.length < 4
    ) {
      isValid = false;
      errors["year_value"] = "The year built must be at least 4 numbers.";
    }
    if (
      input.description !== undefined &&
      input.description !== "" &&
      input.description !== null &&
      input.description.length > 100000
    ) {
      isValid = false;
      errors["description"] =
        "The property description character limit has been exceed.";
    }
    if (
      input.property_rules !== undefined &&
      input.property_rules !== "" &&
      input.property_rules !== null &&
      input.property_rules.length > 100000
    ) {
      isValid = false;
      errors["property_rules"] =
        "The property rules character limit has been exceed.";
    }
    this.setState({ errors: errors });
    return isValid;
  }

  handleChange(event) {
    if (event.target.name === "suitability_id") {
      this.setState({
        suitability_id: Array.from(
          event.target.selectedOptions,
          (item) => item.value
        ),
      });
    }
    if (event.target.name === "location_type_id") {
      this.setState({
        location_type_id: Array.from(
          event.target.selectedOptions,
          (item) => item.value
        ),
      });
    }
    if (event.target.name === "communities_served_id") {
      this.setState({
        communities_served_id: Array.from(
          event.target.selectedOptions,
          (item) => item.value
        ),
      });
    }
    if (event.target.name === "affordable_type_id") {
      this.setState({
        affordable_type_id: Array.from(
          event.target.selectedOptions,
          (item) => item.value
        ),
      });
    }
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  removePhoto() {
    this.setState({ property_logo: "", imageUrl: "" });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container>
          {/* <div className="row row-xs">
            <div className="col-md-10 mg-t-10 mg-b-10 pd-t-10 pd-b-10">
              <div className="backpage">
                <Link
                  onClick={() => {
                    if (this.state.confirm_back === 1)
                      if (
                        window.confirm("Do you want to exit without saving?")
                      )
                        this.props.history.push("/properties");
                      else return false;
                    else this.props.history.push("/properties");
                  }}
                >
                  Properties
                </Link>
                <span> / </span>
                <span>Property</span>
              </div>
            </div>
            <div className="col-md-2 mt-3">
              <p className="d-flex justify-content-end">
                <span className="back-parent d-flex align-items-center">
                  <Link
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.props.history.push("/properties");
                        else return false;
                      else this.props.history.push("/properties");
                    }}
                  >
                    <img src={BackButton} alt="" className="back-button" />
                  </Link>
                  <Link
                    onClick={() => {
                      if (this.state.confirm_back === 1)
                        if (
                          window.confirm("Do you want to exit without saving?")
                        )
                          this.props.history.push("/properties");
                        else return false;
                      else this.props.history.push("/properties");
                    }}
                  >
                    Back
                  </Link>
                </span>
              </p>
            </div>
          </div> */}
        </Container>

        <Container>
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0">
              <div className="col-md-12 mg-t-10 mg-b-10 pd-t-10 pd-b-10"></div>
              {/* <div className="prop-head pd-l-15">
                  <aside className="d-flex">
                  </aside>
                </div> */}
              <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-xs-l-0 pd-lg-l-0 pd-xs-r-0 pd-lg-r-0 ">
                <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                  <h3>Add Property</h3>
                  <div className="d-flex">
                    <button
                      type="button"
                      className="btn-success-outline-small"
                      onClick={() => {
                        if (this.state.confirm_back === 1)
                          if (
                            window.confirm(
                              "Do you want to exit without saving?"
                            )
                          )
                            this.props.history.push("/properties");
                          else return false;
                        else this.props.history.push("/properties");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn-success ml-3"
                      onClick={this.saveOrUpdateProperty}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
              <div className="scrolling-carousel addproperty pd-xs-l-0 pd-lg-l-15">
                <div className="dashboardRightcard background-none pt-0 pl-0 pr-0 ">
                  {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
                  <div className="d-flex justify-content-end">
                    {/* <Link to={'/properties/update/'+this.props.property_slug+"/basic"} ><button className="btn-success-outline-small"><img src={editIcon}/>Edit</button></Link> */}
                  </div>

                  <div className="col-md-12 pd-l-30 pd-r-30 mg-t-0">
                    <Alerts
                      show={this.state.showAlertModal}
                      type={this.state.alertModalType}
                      title={this.state.alertModalTitle}
                      message={this.state.alertModalMessage}
                    />
                  </div>

                  <div className="m-0">
                    <form id="register_from">
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                        <h3 className="mg-l-5 background-head">Basic</h3>
                      </div>
                      <div className="row align-items-center pd-xs-b-0 pd-lg-b-30pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-15">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4- mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                          <div className="fileUpload d-flex flex-wrap justify-content-between">
                            <span>
                              <label className="hand-cursor">
                                <input
                                  onChange={this.onLogoImageChange}
                                  type="file"
                                  name="property_logo"
                                />
                                {this.state.imageUrl ? (
                                  <img src={this.state.imageUrl} alt="Logo" />
                                ) : (
                                  <img src={propertyLogo} alt="Logo" />
                                )}
                              </label>
                            </span>

                            <ul className="ruLink">
                              <li>
                                <label>
                                  <input
                                    onChange={this.onLogoImageChange}
                                    type="file"
                                    name="property_logo"
                                  />
                                </label>
                                Change Logo
                              </li>
                            </ul>
                          </div>
                          {/* {this.state.errors.property_logo ? (
                            <div className="text-danger">
                              {this.state.errors.property_logo}
                            </div>
                          ) : (
                            this.validator.message(
                              "property_logo",
                              this.state.property_logo,
                              "required",
                              { className: "text-danger" }
                            )
                          )} */}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-4- mg-xs-t-5 mg-lg-t-5 mg-md-t-0 pd-l-0 pd-r-0 pd-r-0 mg-xs-b-0 mg-lg-b-15">
                          <div className="row mg-xs-0 flex-wrap">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                              Property Name/DBA{" "}
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="property_name"
                                value={this.state.property_name}
                                maxLength="50"
                              />
                              {this.state.errors.property_name ? (
                                <div className="text-danger">
                                  {this.state.errors.property_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "property name/dba",
                                  this.state.property_name,
                                  "required|max:50",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4- mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                              <label className="form-label text-left pd-b-5">
                                Property SKU
                                {/* <span className="text-danger">*</span> */}
                              </label>
                              <Form.Control
                                onChange={this.changeHandler}
                                type="text"
                                name="aka_name"
                                value={this.state.aka_name}
                                maxLength="3"
                                onKeyPress={
                                  global.onKeyPressEvent.alphaNumValidation
                                }
                              />
                              {this.state.errors.aka_name ? (
                                <div className="text-danger">
                                  {this.state.errors.aka_name}
                                </div>
                              ) : (
                                this.validator.message(
                                  "property_sku",
                                  this.state.aka_name,
                                  "required|alpha_num_dash|max:3",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row align-items-center pd-lg-b-30 pd-xs-b-0 pd-lg-l-30 pd-xs-l-15 pd-lg-r-30 pd-xs-r-15 border-bottom-0 pd-t-0">
                        <div className="col-md-3">
                          <p className="hand-cursor text-danger" onClick={this.removePhoto}>Remove</p>
                        </div>
                      </div> */}
                      <div className="row align-items-baseline pd-lg-b-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-15">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Property Type{" "}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedPropertyList}
                            options={this.state.newPropertyList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangePropertyList(value)
                            }
                            defaultValue={this.state.selectedPropertyList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {this.state.errors.property_type_id ? (
                            <div className="text-danger">
                              {this.state.errors.property_type_id}
                            </div>
                          ) : (
                            this.validator.message(
                              "property_type",
                              this.state.property_type_id,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Ownership Type
                            <span className="optional">(Optional)</span>
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedOwnershipTypeList}
                            options={this.state.newOwnershipTypeList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeOwnershipTypeList(value)
                            }
                            defaultValue={this.state.selectedOwnershipTypeList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {/* <select
                    className=""
                    onChange={this.changeHandler}
                    name="ownership_type_id"
                    value={this.state.ownership_type_id}
                    >
                    <option key="" value="">
                        Select Ownership Type
                    </option>
                    {this.state.ownershipTypeList
                        // .sort((a, b) => (a.label > b.label ? 1 : -1))
                        .map((option) => (
                        <option key={option.slug} value={option.slug}>
                            {option.ownership_type_name}
                        </option>
                        ))}
                    </select> */}
                          {/* {this.state.errors.ownership_type_id ? (
                            <div className="text-danger">
                              {this.state.errors.ownership_type_id}
                            </div>
                          ) : (
                            this.validator.message(
                              "ownership_type",
                              this.state.ownership_type_id,
                              "required",
                              { className: "text-danger" }
                            )
                          )} */}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Brand
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedBrandList}
                            options={this.state.newBrandList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeBrandList(value)
                            }
                            defaultValue={this.state.selectedBrandList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />

                          {/* <select
                        className=""
                        onChange={this.changeHandler}
                        name="brand_id"
                        value={this.state.brand_id}

                        >
                        <option key="" value="">
                            Select Brand
                        </option>
                          {this.state.brandList
                            // .sort((a, b) => (a.label > b.label ? 1 : -1))
                            .map((option) => (
                            <option
                                key={option.slug}
                                value={option.slug}
                            >
                                {option.brand_name}
                            </option>
                            ))}
                        </select> */}
                          {this.state.errors.brand_id ? (
                            <div className="text-danger">
                              {this.state.errors.brand_id}
                            </div>
                          ) : (
                            this.validator.message(
                              "brand",
                              this.state.brand_id,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Club
                            <span className="optional">(Optional)</span>
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedClubList}
                            options={this.state.newClubList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeClubList(value)
                            }
                            defaultValue={this.state.selectedClubList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                          <label className="form-label text-left pd-b-5">
                            PMS Property Name
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            name="pms_property_name"
                            value={this.state.pms_property_name}
                            className="form-control max_width_100"
                            id="inlineFormInput"
                            type="text"
                            autoComplete="off"
                          />
                          {this.state.errors.pms_property_name ? (
                            <div className="text-danger">
                              {this.state.errors.pms_property_name}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Location Type{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <Select
                            isMulti
                            styles={customStyles}
                            menuPlacement="auto"
                            className="multiselect"
                            value={this.state.selectedLocationTypeList}
                            options={this.state.newLocationTypeList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeLocationTypeList(value)
                            }
                            defaultValue={this.state.selectedLocationTypeList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {this.state.errors.location_type_id ? (
                            <div className="text-danger">
                              {this.state.errors.location_type_id}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Class Type
                            <span className="optional">(Optional)</span>
                          </label>
                          <Select
                            isMulti
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedCommServedList}
                            options={this.state.newCommServedList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeCommServedList(value)
                            }
                            defaultValue={this.state.selectedCommServedList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {this.state.errors.communities_served_id ? (
                            <div className="text-danger">
                              {this.state.errors.communities_served_id}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            LEED Certified
                            <span className="optional">(Optional)</span>
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedLeedBuildingList}
                            options={this.state.newLeedBuildingList}
                            onChange={(value) =>
                              this.handleChangeLeedBuildingList(value)
                            }
                            defaultValue={this.state.selectedLeedBuildingList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />

                          {this.state.errors.leed_building_id ? (
                            <div className="text-danger">
                              {this.state.errors.leed_building_id}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Year Built
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            name="year_value"
                            value={this.state.year_value}
                            className="form-control max_width_100"
                            id="inlineFormInput"
                            type="text"
                            minLength="4"
                            maxLength="4"
                            autoComplete="off"
                            onKeyPress={global.onKeyPressEvent.numberValidation}
                          />
                          {this.state.errors.year_value ? (
                            <div className="text-danger">
                              {this.state.errors.year_value}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Smoking Type
                            <span className="optional">(Optional)</span>
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedSmokingTypeList}
                            options={this.state.newSmokingTypeList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeSmokingTypeList(value)
                            }
                            defaultValue={this.state.selectedSmokingTypeList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />

                          {this.state.errors.smoking_type_id ? (
                            <div className="text-danger">
                              {this.state.errors.smoking_type_id}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {/* <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Parking Type
                            <span className="optional">(Optional)</span>
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedParkingTypeList}
                            options={this.state.newParkingTypeList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeParkingTypeList(value)
                            }
                            defaultValue={this.state.selectedParkingTypeList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />

                          {this.state.errors.parking_type_id ? (
                            <div className="text-danger">
                              {this.state.errors.parking_type_id}
                            </div>
                          ) : (
                            ""
                          )}
                        </div> */}
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15 ">
                          <label className="form-label text-left pd-b-5">
                            Minimum Age to Rent
                            <span className="optional">(Optional)</span>
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedMinimumAgeList}
                            options={this.state.newMinimumAgeList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeMinimumAgeList(value)
                            }
                            defaultValue={this.state.selectedMinimumAgeList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />

                          {this.state.errors.minimum_age ? (
                            <div className="text-danger">
                              {this.state.errors.minimum_age}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30">
                          <label className="form-label text-left pd-b-5">
                            Domain Name
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            name="domain_name"
                            value={this.state.domain_name}
                            className="form-control max_width_100"
                            id="inlineFormInput"
                            type="text"
                            // minLength="4"
                            // maxLength="4"
                            autoComplete="off"
                            // onKeyPress={
                            //   global.onKeyPressEvent.numberValidation
                            // }
                          />
                          {/* {this.state.errors.domain_name ? (
                            <div className="text-danger">
                              {this.state.errors.domain_name}
                            </div>
                          ) : (
                            this.validator.message(
                              "domain_name",
                              this.state.domain_name,
                              "required",
                              { className: "text-danger" }
                            )
                          )} */}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Lease Inquiry Url
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            name="lease_inquiry_url"
                            value={this.state.lease_inquiry_url}
                            className="form-control max_width_100"
                            id="inlineFormInput"
                            type="text"
                            autoComplete="off"
                            // onKeyPress={global.onKeyPressEvent.numberValidation}
                          />
                          {this.state.errors.lease_inquiry_url ? (
                            <div className="text-danger">
                              {this.state.errors.lease_inquiry_url}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Lock Type{" "}
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedLockTypeList}
                            options={this.state.lockTypeList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeLockTypeList(value)
                            }
                            defaultValue={this.state.selectedLockTypeList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {this.state.errors.lock_type ? (
                            <div className="text-danger">
                              {this.state.errors.lock_type}
                            </div>
                          ) : (
                            this.validator.message(
                              "lock_type",
                              this.state.lock_type,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-0 d-flex align-items-start affortable pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                          <div className="mg-xs-b-15 mg-lg-b-0">
                            <ul className="formcheck-section addcheckproperty mg-b-0">
                              <li>
                                <label
                                  htmlFor="affordable_component_check"
                                  className="form-label text-left pd-b-5 hand-cursor"
                                >
                                  Affordable
                                </label>
                                <Form.Check
                                  name="affordable_component_check"
                                  type={"checkbox"}
                                  id={`affordable_component_check`}
                                  label={""}
                                  className={"ckbox"}
                                  onChange={this.changeHandler}
                                  checked={
                                    this.state.affordable_component_check
                                  }
                                />
                              </li>
                            </ul>
                          </div>
                          {this.state.affordable_component_check ? (
                            <div className="mg-xs-b-15 mg-lg-b-0 w-100 pd-xs-r-15">
                              <label className="form-label text-left pd-b-5 ">
                                Total Affordable Units
                              </label>
                              <Form.Control
                                type="text"
                                onChange={this.changeHandler}
                                name="total_affordable_unit"
                                value={this.state.total_affordable_unit}
                                maxLength="50"
                              />
                              {this.state.errors.total_affordable_unit ? (
                                <div className="text-danger">
                                  {this.state.errors.total_affordable_unit}
                                </div>
                              ) : (
                                this.validator.message(
                                  "total_affordable_unit",
                                  this.state.total_affordable_unit,
                                  "numeric|min:0,num",
                                  { className: "text-danger" }
                                )
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-lg-t-5 mg-xs-t-0 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-lg-b-15 mg-xs-b-0 d-flex align-items-baseline">
                          <ul className="formcheck-section addcheckproperty mg-b-0">
                            <li>
                              <label
                                htmlFor="insurance_required"
                                className="form-label text-left pd-b-5 hand-cursor"
                              >
                                Renter’s insurance required
                              </label>
                              <Form.Check
                                name="insurance_required"
                                type={"checkbox"}
                                id={`insurance_required`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.insurance_required}
                              />
                            </li>
                            <li>
                              <label
                                htmlFor="pet_allowed"
                                className="form-label text-left pd-b-5 hand-cursor"
                              >
                                Pet Friendly
                              </label>
                              <Form.Check
                                name="pet_allowed"
                                type={"checkbox"}
                                id={`pet_allowed`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.pet_allowed}
                              />
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-lg-t-5 mg-xs-t-0 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-lg-b-15 mg-xs-b-0 d-flex align-items-baseline">
                          <ul className="formcheck-section addcheckproperty mg-b-0">
                            <li>
                              <label
                                htmlFor="children_friendly"
                                className="form-label text-left pd-b-5 hand-cursor"
                              >
                                Suitable for Children
                              </label>
                              <Form.Check
                                name="children_friendly"
                                type={"checkbox"}
                                id={`children_friendly`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.children_friendly}
                              />
                            </li>
                            <li>
                              <label
                                htmlFor="infant_friendly"
                                className="form-label text-left pd-b-5 hand-cursor"
                              >
                                Suitable for Infants
                              </label>
                              <Form.Check
                                name="infant_friendly"
                                type={"checkbox"}
                                id={`infant_friendly`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.infant_friendly}
                              />
                            </li>
                          </ul>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-xs-t-0 pd-l-0 pd-r-30 pd-r-40 mg-b-0 pd-lg-t-10 pd-xs-t-0 d-flex align-items-baseline">
                          <ul className="formcheck-section addcheckproperty mg-b-0">
                            <li>
                              <label className="form-label text-left pd-b-5 mg-xs-l-0 mg-lg-l-30">
                                ADA Compliant
                              </label>
                              <div className="d-flex mg-l-30 mg-xs-l-0 mg-lg-l-30">
                                <Form.Check
                                  onChange={this.changeHandler}
                                  name="ada_compliant"
                                  value="1"
                                  checked={
                                    this.state.ada_compliant === 1
                                      ? true
                                      : false
                                  }
                                  type={"radio"}
                                  id="1"
                                  label={"Yes"}
                                  className={"rdiobox mg-r-20"}
                                />
                                <Form.Check
                                  onChange={this.changeHandler}
                                  name="ada_compliant"
                                  value="2"
                                  checked={
                                    this.state.ada_compliant === 2
                                      ? true
                                      : false
                                  }
                                  type={"radio"}
                                  id="2"
                                  label={"No"}
                                  className={"rdiobox"}
                                />
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-lg-t-5 mg-xs-t-0 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-lg-b-15 mg-xs-b-0 d-flex align-items-baseline">
                          <ul className="formcheck-section addcheckproperty mg-b-0">
                            <li>
                              <label
                                htmlFor="allow_direct_booking"
                                className="form-label text-left pd-b-5 hand-cursor"
                              >
                                Allow Direct Booking
                              </label>
                              <Form.Check
                                name="allow_direct_booking"
                                type={"checkbox"}
                                id={`allow_direct_booking`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.allow_direct_booking}
                              />
                            </li>
                            <li>
                              <label
                                htmlFor="appear_on_oh"
                                className="form-label text-left pd-b-5 hand-cursor"
                              >
                                Appear on Orion Haus
                              </label>
                              <Form.Check
                                name="appear_on_oh"
                                type={"checkbox"}
                                id={`appear_on_oh`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.appear_on_oh}
                              />
                            </li>
                          </ul>
                        </div>
                      </div>
                      {/* <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15 min-rent-last">
                          <label className="form-label text-left pd-b-5">
                            NoiseAware ORG ID
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="noiseaware_org_id"
                            value={this.state.noiseaware_org_id}
                            maxLength="10"
                            onKeyPress={global.onKeyPressEvent.numberValidation}
                          />
                          {this.state.errors.noiseaware_org_id ? (
                            <div className="text-danger">
                              {this.state.errors.noiseaware_org_id}
                            </div>
                          ) : (
                            this.validator.message(
                              "noiseaware_org_id",
                              this.state.noiseaware_org_id,
                              "max:10",
                              { className: "text-danger" }
                            )
                          )}
                        </div> 
                      </div>*/}
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                        <h3 className="mg-l-5 mg-t-0 background-head">
                          Notes
                        </h3>
                      </div>
                      <div className="row align-items-center pd-lg-b-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-15">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Property Notes{" "}
                            <span className="optional">(Optional)</span>
                          </label>

                          <Form.Control
                            type="text"
                            as="textarea"
                            rows={4}
                            onChange={this.changeHandler}
                            name="property_notes"
                            value={this.state.property_notes || ""}
                          />
                          {this.state.errors.property_notes ? (
                            <div className="text-danger">
                              {this.state.errors.property_notes}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                        <h3 className="mg-l-5 mg-t-0 background-head">
                          Location
                        </h3>
                      </div>
                      <div className="row align-items-center pd-lg-b-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-15">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Street 1
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="street_1"
                            value={this.state.street_1}
                          />
                          {this.state.errors.street_1 ? (
                            <div className="text-danger">
                              {this.state.errors.street_1}
                            </div>
                          ) : (
                            this.validator.message(
                              "street_1",
                              this.state.street_1,
                              "required|max:100",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left  pd-b-5">
                            Street 2{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            type="text"
                            onChange={this.changeHandler}
                            name="street_2"
                            value={this.state.street_2}
                          />
                          {this.state.errors.street_2 ? (
                            <div className="text-danger">
                              {this.state.errors.street_2}
                            </div>
                          ) : (
                            this.validator.message(
                              "street_2",
                              this.state.street_2,
                              "max:100",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            City
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedCityList}
                            options={this.state.newCityList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeCityList(value)
                            }
                            defaultValue={this.state.selectedCityList}
                            autoComplete="off"
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="city_id"
                  value={this.state.city_id}
                >
                  <option key="" value="">
                    Select City
                  </option>
                  {this.state.cityList.map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.city_name}
                      </option>
                    ))}
                </select> */}
                          {this.state.errors.city_id ? (
                            <div className="text-danger">
                              {this.state.errors.city_id}
                            </div>
                          ) : (
                            this.validator.message(
                              "city",
                              this.state.city_id,
                              "required",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            State
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Form.Control
                            type="text"
                            className="custom-disable"
                            value={this.state.state_name}
                            // placeholder="State"
                            disabled={true}
                          />
                          {/* {this.state.errors.state_id ? (
                            <div className="text-danger">
                              {this.state.errors.state_id}
                            </div>
                          ) : (
                            this.validator.message(
                              "state",
                              this.state.state_id,
                              "required",
                              { className: "text-danger" }
                            )
                          )} */}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Zip Code
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="zip_code"
                            value={this.state.zip_code}
                            maxLength="5"
                            onKeyPress={global.onKeyPressEvent.numberValidation}
                          />
                          {this.state.errors.zip_code ? (
                            <div className="text-danger">
                              {this.state.errors.zip_code}
                            </div>
                          ) : (
                            this.validator.message(
                              "zip_code",
                              this.state.zip_code,
                              "required|max:5",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            County
                            <span className="optional">(Optional)</span>
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedCountyList}
                            options={this.state.newCountyList.filter(
                              (option) => {
                                const val1 = this.state.county_id
                                  ? this.state.county_id
                                  : "";
                                if (val1 === option.slug) return true;
                              }
                            )}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeCountyList(value)
                            }
                            defaultValue={this.state.selectedCountyList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {/* <select
                  className=""
                  onChange={this.changeHandler}
                  name="county_id"
                  value={this.state.county_id}
                >
                  <option key="" value="">
                    Select County
                  </option>
                  {this.state.countyList
                    .filter((option) => {
                      const val1 = this.state.county_id
                        ? this.state.county_id
                        : "";
                      if (val1 === option.slug) return true;
                    })
                    .map((option) => (
                      <option key={option.slug} value={option.slug}>
                        {option.county_name}
                      </option>
                    ))}
                </select> */}
                          {this.state.errors.county_id ? (
                            <div className="text-danger">
                              {this.state.errors.county_id}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Country
                          </label>
                          <Form.Control
                            type="text"
                            className="custom-disable"
                            value={this.state.country_name}
                            // placeholder="Country"
                            disabled={true}
                          />
                          {/* {this.state.errors.country_id ? (
                            <div className="text-danger">
                              {this.state.errors.country_id}
                            </div>
                          ) : (
                            this.validator.message(
                              "country",
                              this.state.country_id,
                              "required",
                              { className: "text-danger" }
                            )
                          )} */}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <ul className="formcheck-section addcheckproperty mg-b-0">
                            <li>
                              <label
                                htmlFor="international_property"
                                className="form-label text-left mb-1 hand-cursor"
                              >
                                International
                              </label>
                              <Form.Check
                                name="international_property"
                                type={"checkbox"}
                                id={`international_property`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.international_property}
                              />
                            </li>
                            {/* <li>
                              <label className="form-label text-left mb-1">
                                Observes Daylight Savings
                              </label>
                              <Form.Check
                                name="observer_day_ligth_saving"
                                type={"checkbox"}
                                id={`observer_day_ligth_saving`}
                                label={""}
                                className={"ckbox"}
                                onChange={this.changeHandler}
                                checked={this.state.observer_day_ligth_saving}
                              />
                            </li> */}
                          </ul>
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Timezone
                            {/* <span className="optional">(Optional)</span> */}
                          </label>
                          <Select
                            styles={customStyles}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedTimeZoneList}
                            options={this.state.newTimeZoneList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeTimeZoneList(value)
                            }
                            defaultValue={this.state.selectedTimeZoneList}
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {this.state.errors.timezone_id ? (
                            <div className="text-danger">
                              {this.state.errors.timezone_id}
                            </div>
                          ) : (
                            this.validator.message(
                              "timezone",
                              this.state.timezone_id,
                              "required",
                              { className: "text-danger" }
                            )
                          )}

                          {/* <select
                        className=""
                        onChange={this.changeHandler}
                        name="timezone_id"
                        value={this.state.timezone_id}

                        >
                        <option key="" value="">
                            Select Property Timezone
                        </option>
                        {this.state.timeZoneList
                            // .sort((a, b) => (a.label > b.label ? 1 : -1))
                            .map((option) => (
                            <option
                                key={option.slug}
                                value={option.slug}
                            >
                                {option.timezone_name}
                            </option>
                            ))}
                        </select> */}
                          {this.state.errors.timezone_id ? (
                            <div className="text-danger">
                              {this.state.errors.timezone_id}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Google Map Link{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="google_map_link"
                            value={this.state.google_map_link}
                          />
                          {this.state.errors.google_map_link ? (
                            <div className="text-danger">
                              {this.state.errors.google_map_link}
                            </div>
                          ) : (
                            this.validator.message(
                              "google_map_link",
                              this.state.google_map_link,
                              "max:500",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Latitude{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="latitude"
                            value={this.state.latitude}
                          />
                          {this.state.errors.latitude ? (
                            <div className="text-danger">
                              {this.state.errors.latitude}
                            </div>
                          ) : (
                            this.validator.message(
                              "latitude",
                              this.state.latitude,
                              "max:50",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left mg-b-0 pd-b-5">
                            Longitude{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="longitude"
                            value={this.state.longitude}
                          />
                          {this.state.errors.longitude ? (
                            <div className="text-danger">
                              {this.state.errors.longitude}
                            </div>
                          ) : (
                            this.validator.message(
                              "longitude",
                              this.state.longitude,
                              "max:50",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                        <h3 className="mg-l-5 background-head">
                          Orion Haus Contact
                        </h3>
                      </div>
                      <div className="row align-items-center pd-lg-b-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-15">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Name
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="orionhaus_name"
                            value={this.state.orionhaus_name}
                            maxLength="50"
                          />
                          {this.state.errors.orionhaus_name ? (
                            <div className="text-danger">
                              {this.state.errors.orionhaus_name}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Email
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="orionhaus_email"
                            value={this.state.orionhaus_email}
                            maxLength="50"
                          />
                          {this.state.errors.orionhaus_email ? (
                            <div className="text-danger">
                              {this.state.errors.orionhaus_email}
                            </div>
                          ) : (
                            this.validator.message(
                              "email",
                              this.state.orionhaus_email,
                              "email",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Phone
                            <span className="optional">(Optional)</span>
                          </label>
                          <PhoneInput
                            country={global.country}
                            disableSearchIcon
                            // autoFormat= "true"
                            enableSearch="true"
                            countryCodeEditable="true"
                            value={this.state.orionhaus_phone_number}
                            onChange={this.onChangePhoneProperty}
                          />
                          {this.state.errors.orionhaus_phone_number ? (
                            <div className="text-danger">
                              {this.state.errors.orionhaus_phone_number}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                        <h3 className="mg-l-5 background-head">
                          Property Contact
                        </h3>
                      </div>
                      <div className="row align-items-center pd-lg-b-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-15">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Property Manager{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="property_manager_name"
                            value={this.state.property_manager_name}
                            maxLength="50"
                          />
                          {/* <Select
                            styles={customStyles}
                            isClearable={true}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedPropertyManagerList}
                            options={this.state.newAccountManagerList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangePropertyManagerList(value)
                            }
                            defaultValue={
                              this.state.selectedPropertyManagerList
                            }
                            autoComplete="off"
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          /> */}
                          {/* {this.state.errors.property_manager_slug ? (
                            <div className="text-danger">
                              {this.state.errors.property_manager_slug}
                            </div>
                          ) : (
                            ""
                          )} */}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Email
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="property_email"
                            value={this.state.property_email}
                            maxLength="50"
                          />
                          {this.state.errors.property_email ? (
                            <div className="text-danger">
                              {this.state.errors.property_email}
                            </div>
                          ) : (
                            this.validator.message(
                              "email",
                              this.state.property_email,
                              "email",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Phone
                            <span className="optional">(Optional)</span>
                          </label>
                          <PhoneInput
                            country={global.country}
                            disableSearchIcon
                            // autoFormat= "true"
                            enableSearch="true"
                            countryCodeEditable="true"
                            value={this.state.property_phone_number}
                            onChange={this.onChangePhoneProperty}
                          />
                          {this.state.errors.property_phone_number ? (
                            <div className="text-danger">
                              {this.state.errors.property_phone_number}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Maintenance Email
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="maintenance_email"
                            value={this.state.maintenance_email}
                          />
                          {this.state.errors.maintenance_email ? (
                            <div className="text-danger">
                              {this.state.errors.maintenance_email}
                            </div>
                          ) : (
                            this.validator.message(
                              "maintenance_email",
                              this.state.maintenance_email,
                              "email",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Invoice Email
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="invoice_email"
                            value={this.state.invoice_email}
                          />
                          {this.state.errors.invoice_email ? (
                            <div className="text-danger">
                              {this.state.errors.invoice_email}
                            </div>
                          ) : (
                            this.validator.message(
                              "invoice_email",
                              this.state.invoice_email,
                              "email",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Asset Manager
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            type="text"
                            onChange={this.changeHandler}
                            name="asset_managers"
                            value={this.state.asset_managers}
                            maxLength="50"
                          />
                          {this.state.errors.asset_managers ? (
                            <div className="text-danger">
                              {this.state.errors.asset_managers}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Orion Haus Account Manager{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <Select
                            styles={customStyles}
                            isClearable={true}
                            className="multiselect"
                            menuPlacement="auto"
                            value={this.state.selectedAccountManagerList}
                            options={this.state.newAccountManagerList}
                            getOptionValue={(option) => `${option.label}`}
                            onChange={(value) =>
                              this.handleChangeAccountManagerList(value)
                            }
                            defaultValue={this.state.selectedAccountManagerList}
                            autoComplete="off"
                            theme={(theme) => ({
                              ...theme,
                              borderRadius: 2,
                              colors: {
                                ...theme.colors,
                                primary: "#fff",
                                primary75: "#000",
                                primary50: "#000",
                                primary25: "#000",
                              },
                            })}
                          />
                          {this.state.errors.orion_haus_account_manager_slug ? (
                            <div className="text-danger">
                              {
                                this.state.errors
                                  .orion_haus_account_manager_slug
                              }
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      {/* <div className="row align-items-center pd-lg-b-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-15">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Orion Haus Account Manager{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="orion_haus_account_manager"
                            value={this.state.orion_haus_account_manager || ""}
                            maxLength="100"
                          />
                          {this.state.errors.orion_haus_account_manager ? (
                            <div className="text-danger">
                              {this.state.errors.orion_haus_account_manager}
                            </div>
                          ) : (
                            this.validator.message(
                              "orion_haus_account_manager",
                              this.state.orion_haus_account_manager,
                              "max:100",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Orion Haus Email{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="orion_haus_email"
                            value={this.state.orion_haus_email || ""}
                            maxLength="70"
                          />
                          {this.state.errors.orion_haus_email ? (
                            <div className="text-danger">
                              {this.state.errors.orion_haus_email}
                            </div>
                          ) : (
                            this.validator.message(
                              "orion_haus_email",
                              this.state.orion_haus_email,
                              "email|max:70",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Orion Haus Phone{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <PhoneInput
                            country={global.country}
                            enableSearch="true"
                            countryCodeEditable="true"
                            value={this.state.orion_haus_phone_number}
                            onChange={(orion_haus_phone_number) =>
                              this.setState({ orion_haus_phone_number })
                            }
                          />
                          {this.state.errors.orion_haus_phone_number ? (
                            <div className="text-danger">
                              {this.state.errors.orion_haus_phone_number}
                            </div>
                          ) : (
                            this.validator.message(
                              "orion_haus_phone_number",
                              this.state.orion_haus_phone_number,
                              "max:15",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Property Manager{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="property_manager"
                            value={this.state.property_manager || ""}
                            maxLength="100"
                          />
                          {this.state.errors.property_manager ? (
                            <div className="text-danger">
                              {this.state.errors.property_manager}
                            </div>
                          ) : (
                            this.validator.message(
                              "property_manager",
                              this.state.property_manager,
                              "max:100",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Property Manager Email{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <Form.Control
                            onChange={this.changeHandler}
                            type="text"
                            name="property_manager_email"
                            value={this.state.property_manager_email || ""}
                            maxLength="70"
                          />
                          {this.state.errors.property_manager_email ? (
                            <div className="text-danger">
                              {this.state.errors.property_manager_email}
                            </div>
                          ) : (
                            this.validator.message(
                              "property_manager_email",
                              this.state.property_manager_email,
                              "email|max:70",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-45 pd-xs-l-0 pd-xs-r-0 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Property Manager Phone{" "}
                            <span className="optional">(Optional)</span>
                          </label>
                          <PhoneInput
                            country={global.country}
                            enableSearch="true"
                            countryCodeEditable="true"
                            value={this.state.property_manager_phone_number}
                            onChange={(property_manager_phone_number) =>
                              this.setState({ property_manager_phone_number })
                            }
                          />
                          {this.state.errors.property_manager_phone_number ? (
                            <div className="text-danger">
                              {this.state.errors.property_manager_phone_number}
                            </div>
                          ) : (
                            this.validator.message(
                              "property_manager_phone_number",
                              this.state.property_manager_phone_number,
                              "max:15",
                              { className: "text-danger" }
                            )
                          )}
                        </div>
                      </div> */}
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                        <h3 className="mg-l-5 background-head">Timings</h3>
                      </div>
                      {/* <div className="row align-items-center pd-b-30 pd-l-30 border-bottom-0">
              <div className="row col-10 pd-l-0 border-bottom-0">
                <label className="col-1 pd-l-0 mg-r-5"></label>
                <label className="col-3 pd-l-0 mg-r-5">From</label>
                <label className="col-3 pd-l-0  pd-r-0 mg-r-0">To</label>
                <label className="col-1 pd-l-0 mg-r-0">Closed</label>
              </div>
                {this.state.property_office_data.map((item, index) => (
                  <div className="row col-10 align-items-center pd-t-10 pd-b-10 pl-0 border-bottom-0" key={item.key}>
                        <div className="col-1 pl-0 pd-b-5 mg-r-5">
                          <label className="form-label mg-b-0 text-right d-flex justify-content-end">
                            {item.label}
                          </label>
                        </div>
                        <div className="col-3 mg-t-5 mg-md-t-0 pl-0 react-time-picker">
                          <InputMask
                            name="start_time"
                            onChange={(e) => this.changeHandler(e, index)}
                            value={item.start_time}
                            disabled={item.closed === 1 ? true : false}
                            mask="99:99 aa"
                          />
                        </div>
                        <div className="col-3 mg-t-5 mg-md-t-0 pl-0">
                          <InputMask
                            name="end_time"
                            onChange={(e) => this.changeHandler(e, index)}
                            value={item.end_time}
                            disabled={item.closed === 1 ? true : false}
                            mask="99:99 aa"
                          />
                        </div>
                        <div className="row col-1 mg-t-5 mg-md-t-0 pl-0 pr-0 justify-content-center align-items-center">
                          <div className="ckbox form-check d-flex align-items-center justify-content-center mg-t-0">
                            <input
                              type="checkbox"
                              id={index}
                              name="closed"
                              className="form-check-input"
                              onChange={(e) => this.changeHandler(e, index)}
                              checked={item.closed || ""}
                            />
                            <label htmlFor={index} className="form-check-label">

                            </label>
                          </div>
                        </div>
                  </div>
                ))}
            </div> */}
                      <div className="row align-items-center pd-t-0 pd-lg-b-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-0 pd-lg-r-15">
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left  pd-b-5">
                            Check In Time
                            <span className="optional">(Optional)</span>
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <DatePicker
                            selected={this.state.check_in_time}
                            onChange={(date) =>
                              this.changeHandlerCheckInTime(date)
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Check Out Time
                            <span className="optional">(Optional)</span>
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <DatePicker
                            selected={this.state.check_out_time}
                            onChange={(date) =>
                              this.changeHandlerCheckOutTime(date)
                            }
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                          />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-xs-r-15 pd-lg-l-30 pd-lg-r-30 mg-b-15">
                          <label className="form-label text-left pd-b-5">
                            Housekeeping Window Hour
                            <span className="optional">(Optional)</span>
                            {/* <span className="text-danger">*</span> */}
                          </label>
                          <input
                            type="text"
                            name="housekeeping_window_hour"
                            onKeyPress={global.onKeyPressEvent.numberValidation}
                            onChange={this.changeHandler}
                            value={this.state.housekeeping_window_hour}
                            maxLength="1"
                          />
                          {this.state.errors.housekeeping_window_hour ? (
                            <div className="text-danger">
                              {this.state.errors.housekeeping_window_hour}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="row align-items-center pd-lg-t-30 pd-xs-t-5 pd-lg-b-30 pd-xs-b-20 pd-l-30 pd-r-30 border-bottom-0 pd-xs-l-0 pd-xs-r-0 pd-lg-l-30 pd-lg-r-30">
                        <div className="col-md-4 col-lg-4 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                          <label className="form-label text-left mg-b-0 mg-r-15">
                            Status
                          </label>
                          <b
                            className={
                              this.state.status_id ? "active" : "inactive"
                            }
                          ></b>
                          <div
                            className={
                              this.state.status_id
                                ? "az-toggle on"
                                : "az-toggle"
                            }
                            onClick={() =>
                              this.setState({
                                status_id: !this.state.status_id,
                              })
                            }
                          >
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="ht-50"></div> */}
          </div>
        </Container>
      </main>
    );
  }
}
export default PropertiesAddForm;

////////////////////////////////////////////////////////////
//     							                                      //
//  Program: PetPolicyForm.jsx                            //
//  Application:  PetPolicy                               //
//  Option: For add or update PetPolicy                   //
//  Developer: Ashish Kumar                               //
//  Date: 2022-02-10                                      //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, Form, InputGroup, Col, Modal } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import PropertySidebar from "../common/PropertySidebar";
import Alerts from "../common/Alerts";
import pluswIcon from "./../../assets/images/plus.svg";
import deleteicon from "../../assets/images/delete.svg";
import editIcon from "./../../assets/images/edit-pen-icon.svg";
import CredentailService from "../../services/CredentailService";
import NoData from "../common/NoData";
import Moment from "moment";
// import openeye from "../../assets/images/openeye.png";
// import closeeye from "../../assets/images/closeeye.png";

export class PropertyCredential extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({
      autoForceUpdate: this,
      element: (message, className) => (
        <div className={className} key={message}>
          {message}
        </div>
      ),
      validators: {
        passwordRequired: {
          message: "The :attribute field is required.",
          rule: (val, params, validator) => {
            return this.state.isPasswordModal
              ? validator.helpers.testRegex(val, /.+/)
              : true;
          },
          required: true,
        },
      },
    });

    this.state = {
      filter_value: "",
      viewMode: 0,
      showSpinner: false,
      loading: false,
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      input: {},
      errors: {},
      status_id: true,
      confirm_back: 0,
      total_record: 0,
      property_slug: this.props.match.params.propertySlug,
      year_value: sessionStorage.getItem("year_value")
        ? sessionStorage.getItem("year_value")
        : "",
      isSubmit: false,
      passwordType: "password",
      records: [
        {
          custom_key_name: "",
          custom_key_slug: "",
          web_url: "",
          notes: "",
          slug: "",
          custom_type_name: "",
          custom_value_name: "",
          passwordType: "password",
        },
      ],
      isPasswordModal: false,
      slug: "",
      password: "",
      currentRecordIndex: null,
      custom_type_name: "************",
    };

    this.saveOrUpdateCredential = this.saveOrUpdateCredential.bind(this);
    this.changeView = this.changeView.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddFields = this.handleAddFields.bind(this);
    this.handleRemoveFields = this.handleRemoveFields.bind(this);
    this.togglePassword = this.togglePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    this.getCredentialData();
  }

  hidePassword(index) {
    const records = [...this.state.records];
    records[index].passwordType = "password";
    records[index].custom_type_name = "************";
    this.setState({ records });
  }

  togglePassword1 = () => {
    this.setState({ passwordType: "text" });
  };

  togglePasswordHide1 = () => {
    this.setState({
      passwordType: "password",
    });
  };

  handlePasswordInputFocus = () => {
    this.passwordInput.removeAttribute("readonly");
  };

  async togglePassword2(index) {
    if (index >= 0 && index < this.state.records.length) {
      const records = [...this.state.records];
      records[index].passwordType = "text";
      // records[index].custom_type_name = "************";
      this.setState({ records });
    }
  }

  async togglePassword(index) {
    const { isPasswordModal, records } = this.state;

    if (isPasswordModal) {
      this.setState({
        isPasswordModal: false,
        custom_type_name: "************",
        passwordType: "password",
        errors: {},
      });
    } else {
      this.setState({
        slug: records[index].slug,
        isPasswordModal: true,
        custom_type_name: "************",
        password: "",
        errors: {},
        currentRecordIndex: index,
      });
    }
    this.validator = new SimpleReactValidator({ autoForceUpdate: this });
  }

  async handleSubmit(e) {
    e.preventDefault();
    this.setState({ isSubmit: true });
    const { slug, password, records, currentRecordIndex } = this.state;

    if (this.validator.allValid()) {
      try {
        const res = await CredentailService.fetchPass({ slug, password });

        if (global.successStatus.includes(res.status)) {
          const { custom_type_name } = res.data || {};

          if (res.message === "Unauthorized. Incorrect password.") {
            this.setState({
              errors: { password: res.message },
              isSubmit: false,
            });
          } else {
            const updatedRecords = records.map((record, index) =>
              index === currentRecordIndex
                ? { ...record, custom_type_name, passwordType: "text" }
                : record
            );
            this.setState({
              records: updatedRecords,
              custom_type_name: custom_type_name || "************",
              isSubmit: false,
              errors: {},
              isPasswordModal: false,
            });
          }
        } else {
          const errors = res.data.result.errorDetail.reduce((acc, item) => {
            acc[item.errorField] = item.errorMessage[0];
            return acc;
          }, {});
          this.setState({ errors, isSubmit: false });
        }
      } catch (err) {
        this.setState({
          errors: err.response?.data?.error || "An error occurred",
          isSubmit: false,
          custom_type_name: "************",
        });
      }
    } else {
      this.validator.showMessages();
    }
    this.setState({ isSubmit: false });
  }

  changeHandler = (event) => {
    let input = this.state.input;
    let value = event.target.value;
    input[event.target.name] = value;
    this.setState({ [event.target.name]: value, input, errors: {} });
  };

  togglePasswordHide = (index) => {
    if (index >= 0 && index < this.state.records.length) {
      const records = [...this.state.records];
      records[index].passwordType = "password";
      records[index].custom_type_name = "************";
      this.setState({ records });
    }
  };

  togglePasswordHide2 = (index) => {
    if (index >= 0 && index < this.state.records.length) {
      const records = [...this.state.records];
      records[index].passwordType = "password";
      // records[index].custom_type_name = "************";
      this.setState({ records });
    }
  };

  async getCredentialData(queryString = "") {
    let list = [];
    let totalRecords = 0;

    let res = await CredentailService.getCredential(this.state.property_slug);
    if (global.successStatus.includes(res.status)) {
      list = res.data || [];
      totalRecords = list.length;
    }

    const records = list.map((item) => ({
      custom_key_name: item.custom_key_name || "",
      custom_key_slug: item.slug || "",
      web_url: item.web_url || "",
      notes: item.notes || "",
      slug: item.slug || "",
      custom_type_name: item.custom_type_name || "",
      custom_value_name: item.custom_value_name || "",
      last_updated_at: item.updated_at || "",
      passwordType: "password",
    }));

    this.setState({
      records: records.length ? records : [this.getEmptyRecord()],
      total_record: totalRecords,
    });
  }

  getEmptyRecord() {
    return {
      custom_key_name: "",
      custom_key_slug: "",
      web_url: "",
      notes: "",
      slug: "",
      custom_type_name: "",
      custom_value_name: "",
      passwordType: "password",
    };
  }

  async saveOrUpdateCredential(e) {
    e.preventDefault();
    if (this.validator.allValid() && this.customValidate()) {
      const inputData = {
        cost_data: this.state.records,
        ref_slug: this.state.property_slug,
        ref_table_id: 3,
      };
      const res = await CredentailService.createCredential(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message || "Success",
        });
        setTimeout(() => this.changeView(), global.redirect_time);
      }
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
  }

  async deleteCredential(slug) {
    this.setState({ showSpinner: true });
    if (slug) {
      let res = await CredentailService.deleteCredential(slug);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message || "Success",
          selectedPetTypeList: [],
        });
        this.getCredentialData();
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.message || "Error!",
          alertModalMessage: res.data.message,
        });
      }
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  handleChange(index, event) {
    if (index >= 0 && index < this.state.records.length) {
      const records = [...this.state.records];
      records[index][event.target.name] = event.target.value;
      this.setState({ records });
    }
  }

  handleAddFields = () => {
    const values = [...this.state.records];
    values.push(this.getEmptyRecord());
    this.setState({ records: values });
  };

  handleRemoveFields = (i) => {
    const values = [...this.state.records];
    values.splice(i, 1);
    this.setState({ records: values });
  };

  customValidate() {
    const errors = {};
    let isValid = true;

    this.state.records.forEach((record, index) => {
      if (!record.custom_key_name) {
        isValid = false;
        errors[`custom_key_name_${index}`] =
          "The custom_key_name cannot be blank";
      }
      if (!record.custom_type_name) {
        isValid = false;
        errors[`custom_type_name_${index}`] =
          "The custom type name cannot be blank.";
      }
    });

    this.setState({ errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    return "Credentials";
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.getCredentialData();
      this.setState({
        viewMode: 0,
        records: [],
        custom_type_name: "************",
      });
    } else {
      this.getCredentialData();
      this.setState({
        viewMode: 1,
        records: [],
        custom_type_name: "************",
      });
    }
  }
  render() {
    const { isPasswordModal, custom_type_name, isSubmit } = this.state;
    return (
      <main>
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              <div className="col-md-2 left">
                <div className="child-menu-section">
                  <PropertySidebar property_slug={this.state.property_slug} />
                </div>
              </div>
              <div className="col-md-10 right">
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex justify-content-end">
                      {this.state.viewMode === 0 ? (
                        <>
                          {global.userPermissions.checkPermission(
                            "property-credentials-update"
                          ) ? (
                            <button
                              className="btn-success-outline-small"
                              onClick={this.changeView}
                            >
                              <img src={editIcon} alt="edit" /> Edit
                            </button>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        <>
                          <button
                            type="button"
                            className="btn-success-outline-small"
                            onClick={this.changeView}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn-success ml-3"
                            type="button"
                            onClick={this.saveOrUpdateCredential}
                            disabled={this.state.isSubmit ? true : false}
                          >
                            {this.state.isSubmit ? global.loader : "Save"}
                          </button>{" "}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="scrolling-carousel pd-l-45  pd-r-30">
                  <div className="dashboardRightcard background-none pt-0 pl-30 pr-0 propfees ">
                    {this.state.viewMode === 0 ? (
                      <div className="col-md-12 pl-0 pr-0">
                        {this.state.total_record !== 0 ||
                        this.state.filter_value !== "" ? (
                          <div className="mg-t-18 brandList p-0">
                            <div className="pd-lg-l-0 pd-xs-l-15">
                              <div
                                className="as-react-table"
                                id="as-react-datatable-container"
                              >
                                <div
                                  className="row table-body asrt-table-body m-0 pd-lg-t-20"
                                  id="as-react-datatable-table-body"
                                >
                                  <div className="col-md-12 petFeeSection">
                                    <table
                                      className="table table-hoverno-wrap mg-b-30 pd-b-2 "
                                      id="as-react-datatable"
                                    >
                                      <thead>
                                        <tr>
                                          <th className=" text-" width="">
                                            Account
                                          </th>
                                          <th className=" text-" width="">
                                            URL
                                          </th>
                                          <th className=" text-" width="">
                                            Username
                                          </th>
                                          <th
                                            className=" text- text-left"
                                            width=""
                                          >
                                            Password
                                          </th>
                                          <th
                                            className=" text- text-left"
                                            width=""
                                          >
                                            Notes
                                          </th>
                                          <th
                                            className=" text- text-left"
                                            width=""
                                          >
                                            Last Updated On
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.total_record > 0 ? (
                                          this.state.records.map(
                                            (option, i) => (
                                              <tr key={i}>
                                                <td className="text-left">
                                                  {option.custom_key_name || ""}
                                                </td>
                                                <td className="text-left">
                                                  {option.web_url || ""}
                                                </td>
                                                <td className="text-left">
                                                  {option.custom_value_name ||
                                                    ""}
                                                </td>
                                                <td className="text- text-left password_popup">
                                                  <span
                                                    className={
                                                      option.custom_type_name ===
                                                      "************"
                                                        ? "hand-cursor"
                                                        : "hand-cursor pass-show"
                                                    }
                                                  >
                                                    {option.custom_type_name ||
                                                      ""}
                                                  </span>
                                                  {option.custom_type_name !==
                                                  "************" ? (
                                                    <i
                                                      onClick={() => {
                                                        this.hidePassword(i);
                                                      }}
                                                      className="fa fa-eye-slash hand-cursor"
                                                    ></i>
                                                  ) : (
                                                    <i
                                                      onClick={() => {
                                                        this.togglePassword(i);
                                                      }}
                                                      className="fa fa-eye hand-cursor"
                                                    ></i>
                                                  )}
                                                </td>
                                                <td className="text- text-left">
                                                  {option.notes || ""}
                                                </td>
                                                <td className="text- text-left date_tbl_formate">
                                                  {option.last_updated_at
                                                    ? Moment(
                                                        option.last_updated_at
                                                      ).format(
                                                        "MM/DD/YY[\r\n]hh:mm A"
                                                      )
                                                    : ""}
                                                </td>
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan="9" align="center">
                                              There are no credentials added in
                                              the system. You may add them by
                                              clicking Edit button.
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <NoData
                            msg={global.commonMessages.checkMessage(
                              "CREDENTIALS"
                            )}
                            action=" Edit "
                          />
                        )}
                      </div>
                    ) : (
                      <div className="col-md-12 pd-lg-l-0 pd-lg-r-0 pd-xs-l-15 pd-xs-r-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                        <div className="pd-lg-l-0 pd-xs-l-15 pd-r-0 pet-f pd-lg-t-20">
                          <div className="pd-l-0 pd-r-0 pet-f mg-b-30 pet ">
                            <div className="col-md-12 p-0 petFeeSection">
                              <table className="petForm cred_form">
                                <thead>
                                  <tr>
                                    <th className="w-2">
                                      <label className="mb-1">Account</label>
                                    </th>
                                    <th className="w-2">
                                      <label className="mb-1">URL</label>
                                    </th>
                                    <th className="w-2">
                                      <label className="mb-1">Username</label>
                                    </th>
                                    <th className="w-2">
                                      <label className="mb-1">Password </label>
                                    </th>
                                    <th className="w-2">
                                      <label className="mb-1">Notes </label>
                                    </th>
                                    <th className="w-2">
                                      <label className="mb-1">
                                        Last Updated On
                                      </label>
                                    </th>
                                    <th className="w-3"></th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.records.map((data, i) => (
                                    <tr key={i}>
                                      <td className="w-2">
                                        <Col
                                          xs={12}
                                          className="pd-l-0 pd-r-2 colmn1"
                                        >
                                          <InputGroup className="mt-0 m-b-1">
                                            <div className="input-group-prepend w-100">
                                              <Form.Control
                                                name="custom_key_name"
                                                type="text"
                                                autoComplete="off"
                                                value={
                                                  data.custom_key_name || ""
                                                }
                                                onChange={(event) =>
                                                  this.handleChange(i, event)
                                                }
                                              />
                                            </div>
                                          </InputGroup>
                                        </Col>
                                      </td>
                                      <td className="w-2">
                                        <Col
                                          xs={12}
                                          className="pd-l-0 pd-r-2 colmn1"
                                        >
                                          <InputGroup className="mt-0 m-b-1">
                                            <div className="input-group-prepend w-100">
                                              <Form.Control
                                                name="web_url"
                                                type="text"
                                                autoComplete="off"
                                                value={data.web_url || ""}
                                                onChange={(event) =>
                                                  this.handleChange(i, event)
                                                }
                                              />
                                            </div>
                                          </InputGroup>
                                        </Col>
                                      </td>
                                      <td className="w-2">
                                        <Col
                                          xs={12}
                                          className="pd-l-2 pd-r-2 colmn2"
                                        >
                                          <InputGroup className="mt-0">
                                            <div className="input-group-prepend w-100">
                                              <Form.Control
                                                name="custom_value_name"
                                                autoComplete="off"
                                                value={
                                                  data.custom_value_name || ""
                                                }
                                                onChange={(event) =>
                                                  this.handleChange(i, event)
                                                }
                                              />
                                            </div>
                                          </InputGroup>
                                        </Col>
                                      </td>
                                      <td className="w-2">
                                        <Col
                                          xs={12}
                                          className="pd-l-2 pd-r-2 colmn3"
                                        >
                                          <InputGroup className="mt-0">
                                            <div className="input-group-prepend w-100">
                                              <div className="passwod_input">
                                                <Form.Control
                                                  type={data.passwordType}
                                                  name="custom_type_name"
                                                  autoComplete="off"
                                                  value={
                                                    data.custom_type_name || ""
                                                  }
                                                  onChange={(event) =>
                                                    this.handleChange(i, event)
                                                  }
                                                />
                                                {data.custom_type_name ? (
                                                  <div className="input-group-append">
                                                    {data.passwordType ===
                                                    "password" ? (
                                                      <span
                                                        className="eye-icon"
                                                        onClick={() =>
                                                          data.custom_key_slug
                                                            ? this.togglePassword(
                                                                i
                                                              )
                                                            : this.togglePassword2(
                                                                i
                                                              )
                                                        }
                                                      >
                                                       <i className="fa fa-eye hand-cursor text-black"></i>
                                                      </span>
                                                    ) : (
                                                      <span
                                                        className="eye-icon"
                                                        onClick={() =>
                                                          data.custom_key_slug
                                                            ? this.togglePasswordHide(
                                                                i
                                                              )
                                                            : this.togglePasswordHide2(
                                                                i
                                                              )
                                                        }
                                                      >
                                                        <i className="fa fa-eye-slash hand-cursor text-black"></i>
                                                      </span>
                                                    )}
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </div>
                                          </InputGroup>
                                        </Col>
                                      </td>
                                      <td className="w-2">
                                        <Col
                                          xs={12}
                                          className="pd-l-0 pd-r-2 colmn1"
                                        >
                                          <InputGroup className="mt-0 m-b-1">
                                            <div className="input-group-prepend w-100">
                                              <Form.Control
                                                name="notes"
                                                type="text"
                                                autoComplete="off"
                                                value={data.notes || ""}
                                                onChange={(event) =>
                                                  this.handleChange(i, event)
                                                }
                                              />
                                            </div>
                                          </InputGroup>
                                        </Col>
                                      </td>
                                      <td className="w-2">
                                        <Col
                                          xs={12}
                                          className="pd-l-2 pd-r-2 colmn3"
                                        >
                                          <InputGroup className="mt-0">
                                            <div className="input-group-prepend  w-100">
                                              <Form.Control
                                                name="last_updated_at"
                                                autoComplete="off"
                                                readOnly
                                                value={
                                                  data.last_updated_at
                                                    ? Moment(
                                                        data.last_updated_at
                                                      ).format(
                                                        "MM/DD/YY hh:mm A"
                                                      )
                                                    : ""
                                                }
                                                onChange={(event) =>
                                                  this.handleChange(i, event)
                                                }
                                              />
                                            </div>
                                          </InputGroup>
                                        </Col>
                                      </td>
                                      <td>
                                        <Col
                                          xs={12}
                                          className="pd-l-2 pd-r-0 d-flex align-items-center add-delete"
                                        >
                                          {this.state.records.length === 1 &&
                                          i === 0 ? (
                                            <>
                                              {data.slug ? (
                                                <div className="d-flex  justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                  {global.userPermissions.checkPermission(
                                                    "property-credentials-delete"
                                                  ) ? (
                                                    <button
                                                      onClick={() => {
                                                        this.handleRemoveFields(
                                                          i
                                                        );

                                                        this.deleteCredential(
                                                          data.slug
                                                        );
                                                      }}
                                                      className={
                                                        "btn-danger-outline-x-small"
                                                      }
                                                    >
                                                      <img
                                                        src={deleteicon}
                                                        alt="Delete"
                                                      />
                                                    </button>
                                                  ) : (
                                                    ""
                                                  )}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <div className="d-flex justify-content-end align-items-center h-100 pd-l-2 pd-r-0">
                                                {global.userPermissions.checkPermission(
                                                  "property-credentials-add"
                                                ) ? (
                                                  <button
                                                    onClick={
                                                      this.handleAddFields
                                                    }
                                                    className="btn-success-outline-x-small plus_tbn"
                                                  >
                                                    <img
                                                      src={pluswIcon}
                                                      alt="Add"
                                                    />
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </>
                                          ) : i <
                                            this.state.records.length - 1 ? (
                                            <>
                                              <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                {global.userPermissions.checkPermission(
                                                  "property-credentials-delete"
                                                ) ? (
                                                  <button
                                                    onClick={() => {
                                                      this.handleRemoveFields(
                                                        i
                                                      );

                                                      this.deleteCredential(
                                                        data.slug
                                                      );
                                                    }}
                                                    className={
                                                      "btn-danger-outline-x-small"
                                                    }
                                                  >
                                                    <img
                                                      src={deleteicon}
                                                      alt="Delete"
                                                    />
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              {this.state.records.length !==
                                              0 ? (
                                                <>
                                                  <div className="d-flex  justify-content-end align-items-center h-100 pd-l-5 pd-r-5">
                                                    {global.userPermissions.checkPermission(
                                                      "property-credentials-delete"
                                                    ) ? (
                                                      <button
                                                        onClick={() => {
                                                          this.handleRemoveFields(
                                                            i
                                                          );

                                                          this.deleteCredential(
                                                            data.slug
                                                          );
                                                        }}
                                                        className={
                                                          "btn-danger-outline-x-small"
                                                        }
                                                      >
                                                        <img
                                                          src={deleteicon}
                                                          alt="Delete"
                                                        />
                                                      </button>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              <div className="d-flex justify-content-end align-items-end h-100- pd-l-5 pd-r-5">
                                                {global.userPermissions.checkPermission(
                                                  "property-credentials-add"
                                                ) ? (
                                                  <button
                                                    onClick={
                                                      this.handleAddFields
                                                    }
                                                    className="btn-success-outline-x-small plus_tbn"
                                                  >
                                                    <img
                                                      src={pluswIcon}
                                                      alt="Add"
                                                    />
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            </>
                                          )}
                                        </Col>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          show={isPasswordModal}
          centered
          className="modal-xs agreementdocModal"
          onShow={() => {
            this.passwordInput && this.passwordInput.focus();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              this.handleSubmit(e);
            }
          }}
        >
          <Modal.Body className="width_402 p-4 border_radius_14 pass-model">
            <div>
              <span
                className="welcomeClose"
                onClick={() => this.togglePassword()}
              >
                {global.closee}
              </span>
              <h3 className="text-center">Password</h3>
              <div>
                {!custom_type_name === "" ||
                custom_type_name === "************" ? (
                  <>
                    <div className="remove-read-only">
                      <label>Password</label>
                      <div className="passwod_input remove-read-only">
                        <input
                          type={this.state.passwordType}
                          id="passwordInput"
                          name="password"
                          value={this.state.password}
                          onChange={(event) =>
                            this.setState({
                              password: event.target.value,
                              errors: {},
                            })
                          }
                          ref={(input) => (this.passwordInput = input)}
                          onFocus={this.handlePasswordInputFocus}
                          required
                        />
                        {this.state.password ? (
                          <div className="input-group-append">
                            {this.state.passwordType === "password" ? (
                              <span
                                className="eye-icon"
                                onClick={this.togglePassword1}
                              >
                               <i className="fa fa-eye hand-cursor text-black"></i>
                              </span>
                            ) : (
                              <span
                                className="eye-icon"
                                onClick={this.togglePasswordHide1}
                              >
                                <i className="fa fa-eye-slash hand-cursor text-black"></i>
                              </span>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {this.state.errors.password ? (
                        <div className="text-danger">
                          {this.state.errors.password}
                        </div>
                      ) : (
                        this.validator.message(
                          "password",
                          this.state.password,
                          "password|required",
                          { className: "text-danger" }
                        )
                      )}
                    </div>
                    <button
                      className={
                        isSubmit
                          ? "m-0 btn btn btn-success btn-block btn-pass password-button-submit"
                          : "m-0 btn btn btn-success btn-block btn-pass"
                      }
                      onClick={this.handleSubmit}
                      disabled={isSubmit}
                    >
                      Verify Password
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default PropertyCredential;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: ListingService.js                              //
//  Application: Listing                                    //
//  Option: Used for manage Listing                       //
//  Developer: Ashish Kumar						                     //
//  Date: 2022-02-03                                         //
//                                                        //
////////////////////////////////////////////////////////////

// import { diffDates } from "@fullcalendar/core/util/misc";
import ApiService from "./ApiService";

class ListingService {
  getListingList(queryString) {
    let endPoint = "listing" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getAllListingList(queryString) {
    let endPoint = "listing" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getListingUnavailableDateList(queryString) {
    let endPoint =
      "get-listing-unavailable-date" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getListingSpecialPriceList(queryString) {
    let endPoint =
      "get-listing-special-price" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getListingPriceLabList(queryString) {
    let endPoint =
      "get-listing-price-lab" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getListingLOSDiscountList(queryString) {
    let endPoint =
      "get-listing-los-discount" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getListing(slug) {
    let endPoint = "listing/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getListingUnavailableDate(slug, queryString) {
    let endPoint =
      "listing-unavailable-date-by-slug/" +
      slug +
      (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // getListingSpecialPrice(slug) {
  //   let endPoint = "listing-special-price-by-slug/" + slug;
  //   let addditionalHeaderData = {};
  //   let postData = {};

  //   let inputData = {
  //     endPoint: endPoint,
  //     addditionalHeaderData: addditionalHeaderData,
  //     postData: postData,
  //   };
  //   let response = ApiService.getCall(inputData);
  //   return response;
  // }

  getListingSpecialPrice(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "listing-special-price-by-slug";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getListingLOSDiscount(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "listing-los-discount-by-slug";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  checkListingAvailabilty(slug) {
    let endPoint = "get-block-list-date/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  createListing(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "listing";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  createListingUnavailableDate(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "listing-unavailable-date";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  createListingSpecialPrice(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "listing-special-price";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateListing(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-listing/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateListingUnavailableDate(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-listing-unavailable-date/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateListingSpecialPrice(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-listing-special-price";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateListingSpecialPriceFromCalendar(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-listing-special-price-from-calendar";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  updateListingUnavailableDateFromCalendar(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-listing-unavailable-date-from-calendar";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  deleteListing(slug) {
    let endPoint = "listing/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  }

  /*   deleteListingUnavailableDate(slug) {
    let endPoint = "listing-unavailable-date-delete/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.deleteCall(inputData);
    return response;
  } */

  deleteListingUnavailableDate(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "listing-unavailable-date-delete";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  // deleteListingSpecialPrice(slug, from, to) {
  //   let endPoint = "listing-special-price-delete/" + slug;
  //   let addditionalHeaderData = {};
  //   let postData = {};

  //   let inputData = {
  //     endPoint: endPoint,
  //     addditionalHeaderData: addditionalHeaderData,
  //     postData: postData,
  //   };
  //   let response = ApiService.deleteCall(inputData);
  //   return response;
  // }

  deleteListingSpecialPrice(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "listing-special-price-delete";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  uploadHostImg(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "upload-host-photo";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  exportListingData(queryString) {
    let endPoint = "export-listing" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  exportDataFilewise(data) {
    let endPoint = "listing-export";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  //for avilability
  getListingAval(queryString) {
    let endPoint =
      "listing-availability" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  addListingAval(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "listing-availability";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  checkListingAvailabiltyForSameSlug(slug, slug2) {
    let endPoint = "get-update-block-list-date/" + slug + "/" + slug2;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getResidentDataByUnit(slug) {
    let endPoint = "fetch-unit-lease-data/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getUnitSKU(slug) {
    let endPoint = "get-unit-prop-sku/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getHostData(slug) {
    let endPoint = "get-host-info/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getListingUpdateUnavailableData(slug, slug2) {
    let endPoint = "get-update-block-listing-date/" + slug + "/" + slug2;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getListingUpdateSpecialPrice(slug, from, to, type) {
    let endPoint =
      "get-update-block-listing-specail-price-date/" +
      slug +
      "/" +
      from +
      "/" +
      to +
      "/" +
      type;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getListingLOSDiscountBlockedDate(slug, from, to, type) {
    let endPoint =
      "get-update-block-listing-los-discount-date/" +
      slug +
      "/" +
      from +
      "/" +
      to +
      "/" +
      type;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  // getListingUpdateSpecialPrice(slug, slug2) {
  //   let endPoint =
  //     "get-update-block-listing-specail-price-date/" + slug + "/" + slug2;
  //   let addditionalHeaderData = {};
  //   let postData = {};

  //   let inputData = {
  //     endPoint: endPoint,
  //     addditionalHeaderData: addditionalHeaderData,
  //     postData: postData,
  //   };
  //   let response = ApiService.getCall(inputData);
  //   return response;
  // }

  publishProperty(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "publish-building";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  publishListing(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "publish-property";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  publishAllListing(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "publish-all-property";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  publishPricing(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "push-listing-prices";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  publishAvailability(data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "push-listing-availability";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  copyListing(slug, queryString) {
    let endPoint =
      "copy-listing/" + slug + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  copyListingImg(slug) {
    let endPoint = "copy-listing-img/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  importData(data) {
    let endPoint = "listing-import";
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.uploadFileCall(inputData);
    return response;
  }

  getListingOccupancyTaxData(queryString) {
    let endPoint =
      "get-listing-occupancy-taxes" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  listUnlistListing(queryString) {
    let endPoint =
      "list-unlist-listing" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  // add listing by unit api open to popup
  addListingByUnit(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "add-listing-by-unit";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }

  /* START: Get Latch Access schedules for backend --------------------------- */
  getLatchAccess(slug) {
    let endPoint = `get-latch-access-by-refrec-id?resident_stay_slug=${slug}`;
    let addditionalHeaderData = {};
    let postData = {};
    let inputData = { endPoint, addditionalHeaderData, postData };
    let response = ApiService.getCall(inputData);
    return response;
  }
  /* END: Get Latch Access schedules for backend --------------------------- */

  getSeasonalPricepdateNextCheckInDate(slug, listingSlug, date, priceType) {
    let endPoint =
      "get-block-seasonal-price-update-checkout-date/" +
      slug +
      "/" +
      listingSlug +
      "/" +
      date +
      "/" +
      priceType;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  getSeasonalPriceNextCheckInDate(listingSlug, date, priceType) {
    let endPoint =
      "get-block-seasonal-price-checkout-date/" +
      listingSlug +
      "/" +
      date +
      "/" +
      priceType;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getResidentDataBySlug(slug) {
    let endPoint = "home-sharing-inquiry-view/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  deleteHomeSharingInquiry(slug) {
    let endPoint = "delete-homesharing-inquiry/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  updateHomeSharingInquiry(slug, data) {
    delete data.errors;
    delete data.input;

    let endPoint = "update-homesharing-inquiry/" + slug;
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  exportInquiriesDataFilewise(data) {
    let endPoint = "inquiries-listing-export";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getPropertyUnitData(slug) {
    let endPoint = "get-property-unit/" + slug;
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  getUserData(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "get-userby-unitslug/" + data;
    let addditionalHeaderData = {};
    let postData = data;
    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }
  createHomeSharingInquiry(data) {
    delete data.errors;
    delete data.input;

    let endPoint = "add-homesharing-inquiry";
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
  getHomeSharingInquiryList(queryString) {
    let endPoint =
      "home-sharing-listing" + (queryString ? "?" + queryString : "");
    let addditionalHeaderData = {};
    let postData = {};

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.getCall(inputData);
    return response;
  }

  updateArrivalDescription(slug, data) {
    // Delete data which we don't want to send
    delete data.errors;
    delete data.input;

    let endPoint = "update-arrival-listing/" + slug;
    let addditionalHeaderData = {};
    let postData = data;

    let inputData = {
      endPoint: endPoint,
      addditionalHeaderData: addditionalHeaderData,
      postData: postData,
    };
    let response = ApiService.postCall(inputData);
    return response;
  }
}

export default new ListingService();

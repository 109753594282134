////////////////////////////////////////////////////////////
//     							                                      //
//  Program: ListingForm.jsx                                    //
//  Application: ListingForm                                    //
//  Option: List all ListingForm                                //
//  Developer: Ashish Kumar					                      //
//  Date: 2022-04-06                                      //
//                                                        //
////////////////////////////////////////////////////////////
import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import RoomService from "../../services/RoomService";
import ListingService from "../../services/ListingService";
import { Container, Form, Tab, Nav, InputGroup, Modal } from "react-bootstrap";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import RoomCategoryService from "../../services/RoomCategoryService";
import RoomTypeService from "../../services/RoomTypeService";
import UnitsService from "../../services/UnitsService";
import MasterDataService from "../../services/MasterDataService";
import AmenityService from "../../services/AmenityService";
import RentalService from "../../services/RentalService";
import Alerts from "../common/Alerts";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import ListingImages from "./ListingImages";
import ListingFees from "./ListingFees";
import ListingAmenities from "./ListingAmenities";
// import ListingSpecialPrice from "./ListingSpecialPrice";
import ListingUnavailableDates from "./ListingUnavailableDates";
import ListingArrivalInstruction from "./ListingArrivalInstruction";
import ListingRentalActivity from "./ListingRentalActivity";
import hostImg from "./../../assets/images/profile-nogender.png";
import Preview from "./Preview";
import PhoneInput from "react-phone-input-2";
import PetPolicyService from "../../services/PetPolicyService";
import ListingPetFee from "./ListingPetFee";
import LisitingSidebar from "../common/LisitingSidebar";
import AllPropertyLisitingSidebar from "../common/AllPropertyLisitingSidebar";
import Onboarding from "./Onboarding";
import PropertyService from "../../services/PropertyService";
import KeycafeService from "../../services/KeycafeService";

export class ListingForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator({ autoForceUpdate: this });

    this.state = {
      viewMode: 0,
      is_inactive: false,
      tableQueryString: "",
      customQueryString: "",
      showFilter: global.showFilter,
      input: {},
      errors: {},
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      filter_value: "",
      loading: false,
      host_dial_code: "",
      showSpinner: false,
      total_record: 0,
      confirm_back: 0,
      listing_slug: this.props.match.params.slug,
      property_slug:
        this.props.match.params.propertySlug !== undefined
          ? this.props.match.params.propertySlug
          : this.props.match.params.outsidePropertySlug,
      slug: "",
      imageUrl: hostImg,
      status_id: false,
      pathname: window.location.pathname,
      records: [],
      roomCategoryList: [],
      newRoomCategoryList: [],
      selectedRoomCategoryList: [],
      roomTypeList: [],
      newRoomTypeList: [],
      selectedRoomTypeList: [],
      bedTypeList: [],
      newBedTypeList: [],
      selectedBedTypeList: [],
      balconyList: [],
      newBalconyList: [],
      selectedBalconyList: [],
      toiletList: [],
      newToiletList: [],
      selectedToiletList: [],
      bathroomList: [],
      newBathroomList: [],
      selectedBathroomList: [],
      amenitiesList: [],
      propertyAmenitiesList: [],
      slugList: [],
      slugUncheckList: [],
      rentingTypeList: [],
      newRentingTypeList: [],
      selectedRentingTypeList: [],
      unitList: [],
      newUnitList: [],
      selectedUnitList: [],
      kitchenList: [],
      newKitchenList: [],
      selectedKitchenList: [],
      livingRoomList: [],
      newLivingRoomList: [],
      selectedLivingRoomList: [],
      checkListingType: 0,
      listing_name: "",
      unit_slug: "",
      unit_slug_for_wifi: "",
      renting_type: "",
      room_category: "",
      room_type: "",
      bed_type: "",
      listing_description: "",
      the_space: "",
      guest_manual: "",
      //airbnb_notes: "",
      bedroom_lock: "",
      balcony: "",
      toilet: "",
      kitchen: "",
      living_room: "",
      bathroom: "",
      room_size: "",
      suit: "",
      from_date: "",
      to_date: "",
      host_name: "",
      host_email: "",
      host_phone_number: "",
      host_bio: "",
      activeClass: "info",
      tabWizard: 0,
      dateFrom: [],
      disabled_dates: [],
      isRefresh: false,
      isRefresh1: false,
      isRefresh2: false,
      isRefreshlistingImages: false,
      isRefreshlistingAmenities: false,
      isRefreshlistingPrice: false,
      isRefreshlistingPetFee: false,
      isRefreshspecialPrice: false,
      isRefreshunavailableDates: false,
      tower_name: "",
      // custom_link: global.air_Bnb_create_url ? global.air_Bnb_create_url : "",
      custom_link: process.env.REACT_AIRBNB_LISITING_ENDPOINT
        ? process.env.REACT_AIRBNB_LISITING_ENDPOINT
        : "https://www.airbnb.com/h/",
      countryList: [],
      newCountryList: [],
      selectedCountryList: [],
      countyList: [],
      newCountyList: [],
      selectedCountyList: [],
      stateList: [],
      newStateList: [],
      selectedStateList: [],
      cityList: [],
      newCityList: [],
      selectedCityList: [],
      resident_slug: "",
      property_sku: "",
      unit_sku: "",
      prop_unit_sku: "",
      prohib_pet: "",
      wi_fi_name: "",
      wi_fi_password: "",
      parking_slot: "",
      //for rental
      // headline: "",
      why_purchased: "",
      owner_story: "",
      wi_fi: "",
      is_exempt: false,
      is_vat_registered: false,
      host_intraction: "",
      license_number: "",
      airbnb_account_id: "",
      issue_date: "",
      expiration_date: "",
      exemption_reason: "",
      listing_pet_allowed: false,
      publishListingModal: false,
      publishMessage: "",
      listingNameError: false,
      lock_type: 0,
      LOQRON_enable: 0,
      LOQRON_listing: 0,
      lockboxIds: [],
    };
    this.changeView = this.changeView.bind(this);

    this.closeModal = this.closeModal.bind(this);
    this.changeHandler = this.changeHandler.bind(this);

    this.changeView = this.changeView.bind(this);

    this.roomImage = React.createRef();
    this.initialState = this.state;
    this.defaultData = {};
    this.closeModal = this.closeModal.bind(this);
    this.saveOrUpdateListing = this.saveOrUpdateListing.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.getActiveClass = this.getActiveClass.bind(this);
    this.getListingAvailableDate = this.getListingAvailableDate.bind(this);
    this.getResidentDataByUnit = this.getResidentDataByUnit.bind(this);
    this.getTabData = this.getTabData.bind(this);
    this.getUnitDataByUnitSlug = this.getUnitDataByUnitSlug.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.openPublishListingModal = this.openPublishListingModal.bind(this);
    this.closePublishListingModal = this.closePublishListingModal.bind(this);
    this.publishListing = this.publishListing.bind(this);
  }

  getActiveClass(e) {
    this.setState({ activeClass: e });
    if (e === "listingImages") {
      this.setState({ isRefreshlistingImages: true });
    } else if (e === "listingAmenities") {
      this.setState({ isRefreshlistingAmenities: true });
    } else if (e === "listingPrice") {
      this.setState({ isRefreshlistingPrice: true });
    } else if (e === "listingPetFee") {
      this.setState({ isRefreshlistingPetFee: true });
    } else if (e === "unavailableDates") {
      this.setState({ isRefreshunavailableDates: true });
    } else if (e === "arrivalInstruction") {
      this.setState({ isRefreshArrivalInstruction: true });
    } else if (e === "listingPreview") {
      this.setState({ isRefresh: true });
    } else if (e === "listingActivity") {
      this.setState({ isRefresh1: true });
    } else if (e === "Onboarding") {
      this.setState({ isRefresh2: false });
      setTimeout(() => {
        this.setState({ isRefresh2: true });
      }, 10);
    }
  }

  componentDidMount() {
    this.setState({ confirm_back: 0, checkListingType: 0 });

    if (this.state.listing_slug !== undefined) {
      this.getAmenitiesData();
      this.getPropertyAmenitiesData();
      this.getInfoData();
      //api call in only update form
    }
    this.getRentingTypeData();
    this.getRoomCategoryData();
    this.getRoomTypeData();
    this.getUnitData();
    this.getBalconyData();
    this.getToiletData();
    this.getBathroomData();
    this.getBedTypeData();
    this.getKitchenData();
    this.getLivingRoomData();
    this.getPetPolicyData();
    this.getHostData();
    this.getLockType();
    this.getKeyCafeFlag();
    //api call in add/update both form
  }

  async getKeyCafeFlag() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await KeycafeService.getKeycafeSetting(
      "property_slug=" + this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      resData.LOQRON_enable = res.data ? res.data.LOQRON_enable : 0;
      resData.LOQRON_listing = res.data ? res.data.LOQRON_listing : 0;
      resData.lockboxIds = res.data ? res.data.lockbox_ids : [];
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  async getLockType() {
    this.setState({ showSpinner: true, loading: true });
    if (this.state.property_slug !== undefined) {
      var resData = {};
      let res = await PropertyService.getProperty(this.state.property_slug);
      if (global.successStatus.includes(res.status)) {
        resData.lock_type =
          res.data && res.data.lock_type ? res.data.lock_type : 0;
      }

      this.setState(resData);
    }
    this.setState({ showSpinner: false, loading: false });
  }

  async getPetPolicyData() {
    //this.setState({ showSpinner: true, loading: true });
    var multiList = [];
    var multiListTotal = 0;
    var prohib_pet = "";

    let res = await PetPolicyService.getPetPolicy(
      this.state.property_slug + "/3"
    );
    if (global.successStatus.includes(res.status)) {
      multiList = res.data.petPolicyRestrictions
        ? res.data.petPolicyRestrictions
        : [];
      multiListTotal = multiList ? multiList.length : 0;
    }
    this.setState({
      multiList: multiList,
      multiListTotal: multiListTotal,
    });
    setTimeout(() => {
      if (this.state.multiListTotal > 0) {
        prohib_pet = this.state.multiList
          .map((item) => item.pet_type_name)
          .join(", ");
        // prohib_pet = this.state.multiList.map(
        //   (option, i) =>
        //     (prohib_pet =
        //       option.pet_type_name +
        //       (i < this.state.multiListTotal - 1 ? ", " : "") +
        //       "")
        // );
      }
      this.setState({ prohib_pet: prohib_pet });
    }, 100);

    //this.setState({ showSpinner: false, loading: false });
  }

  getTabData(slug) {
    this.setState({ listingSlug: slug });
  }

  async getInfoData(slug) {
    var resData = {};

    let res = await ListingService.getListing(
      slug ? slug : this.state.listing_slug
    );

    if (global.successStatus.includes(res.status)) {
      // resData = res.data ? res.data : {};
      resData.listing_name = res.data.listing_name ? res.data.listing_name : "";
      resData.listing_id = res.data.listing_unit_id
        ? res.data.listing_unit_id
        : "";
      resData.listing_description = res.data.listing_description
        ? res.data.listing_description
        : "";
      resData.the_space = res.data.the_space ? res.data.the_space : "";
      resData.guest_manual = res.data.guest_manual ? res.data.guest_manual : "";
      // resData.airbnb_notes = res.data.airbnb_notes
      //   ? res.data.airbnb_notes
      //   : "";
      resData.custom_link = res.data.custom_link ? res.data.custom_link : "";
      resData.wi_fi_name = res.data.wi_fi_name ? res.data.wi_fi_name : "";
      resData.wi_fi_password = res.data.wi_fi_password
        ? res.data.wi_fi_password
        : "";
      resData.parking_slot = res.data.parking_slot ? res.data.parking_slot : "";
      resData.host_bio = res.data.ruListingData
        ? res.data.ruListingData.owner_story
        : "";
      // resData.host_photo = res.data.host_photo ? res.data.host_photo : "";
      // resData.imageUrl = res.data.host_photo_url ? res.data.host_photo_url : "";
      resData.unit_slug = res.data.unit ? res.data.unit.slug : "";
      resData.unit_slug_for_wifi = res.data.unit ? res.data.unit.slug : "";
      resData.floor_plan_slug =
        res.data && res.data.unit && res.data.unit.floor_plan
          ? res.data.unit.floor_plan.slug
          : "";
      //for rental
      resData.is_exempt = res.data.ruListingData
        ? res.data.ruListingData.is_exempt === 1
          ? true
          : false
        : false;
      resData.is_vat_registered = res.data.ruListingData
        ? res.data.ruListingData.is_vat_registered === 1
          ? true
          : false
        : false;
      // resData.headline = res.data.ruListingData
      //   ? res.data.ruListingData.headline
      //   : "";
      resData.why_purchased = res.data.ruListingData
        ? res.data.ruListingData.why_purchased
        : "";
      resData.owner_story = res.data.ruListingData
        ? res.data.ruListingData.owner_story
        : "";
      // resData.wi_fi = res.data.ruListingData ? res.data.ruListingData.wi_fi : ""
      resData.host_intraction = res.data.ruListingData
        ? res.data.ruListingData.host_intraction
        : "";
      resData.parking_slot = res.data.parking_slot ? res.data.parking_slot : "";

      resData.license_number = res.data.ruListingLicenseData
        ? res.data.ruListingLicenseData.licensenumber
        : "";
      resData.airbnb_account_id = res.data.airbnb_account_id
        ? res.data.airbnb_account_id
        : "";
      resData.issue_date =
        res.data.ruListingLicenseData &&
        res.data.ruListingLicenseData.issue_date
          ? Moment(
              res.data.ruListingLicenseData.issue_date,
              "YYYY-MM-DD"
            ).toDate("dd-MM-YYYY")
          : null;
      resData.expiration_date =
        res.data.ruListingLicenseData &&
        res.data.ruListingLicenseData.expiration_date
          ? Moment(
              res.data.ruListingLicenseData.expiration_date,
              "YYYY-MM-DD"
            ).toDate("dd-MM-YYYY")
          : null;
      resData.exemption_reason = res.data.ruListingLicenseData
        ? res.data.ruListingLicenseData.exemption_reason
        : "";

      resData.property_pet_allowed = res.data.property
        ? res.data.property.pet_allowed
        : false;
      resData.listing_pet_allowed =
        res.data.listing_pet_allowed === 1 ? true : false;
      var selectedUnitList = [];
      selectedUnitList = res.data.unit
        ? [
            {
              value: res.data.unit.slug,
              label:
                res.data.unit && res.data.unit.unit_name
                  ? res.data.unit.unit_name
                  : res.data.unit.unit_number,
            },
          ]
        : "";
      // if (res.data.unit) {
      //   this.getListingAvailableDateSameSlug(
      //     resData.unit_slug,
      //     this.state.listing_slug
      //   );
      // }
      resData.room_size = res.data.room_size
        ? res.data.room_size.replace(/\.00$/, "")
        : "";
      resData.renting_type = res.data.listing_type ? res.data.listing_type : "";

      resData.bedroom_lock = res.data.bedroom_lock === 1 ? true : false;

      resData.digital_lock = res.data.digital_lock ? res.data.digital_lock : "";
      resData.suit = res.data.suit === 1 ? true : false;
      resData.from_date = res.data.from_date
        ? Moment(res.data.from_date, "MM/DD/YYYY").toDate("MM/DD/YYYY")
        : null;
      resData.to_date = res.data.to_date
        ? Moment(res.data.to_date, "MM/DD/YYYY").toDate("MM/DD/YYYY")
        : null;
      resData.last_to_date = res.data.last_to_date
        ? Moment(res.data.last_to_date, "MM/DD/YYYY").toDate("MM/DD/YYYY")
        : null;
      resData.sort_order = res.data.sort_order ? res.data.sort_order : "";
      resData.status_id = res.data.status_id === 1 ? true : false;
      resData.bedroom =
        res.data &&
        res.data.unit &&
        res.data.unit.floor_plan &&
        res.data.unit.floor_plan.bedroom
          ? res.data.unit.floor_plan.bedroom
          : "";
      resData.bath =
        res.data &&
        res.data.unit &&
        res.data.unit.floor_plan &&
        res.data.unit.floor_plan.bath
          ? res.data.unit.floor_plan.bath
          : "";

      resData.maximum_number_of_guests =
        res.data.unit && res.data.unit.maximum_number_of_guests
          ? res.data.unit.maximum_number_of_guests
          : "";
      var selectedKitchenList = [];
      var selectedLivingRoomList = [];
      var selectedRentingTypeList = [];
      var selectedRoomCategoryList = [];
      var selectedBedTypeList = [];
      var selectedBalconyList = [];
      var selectedToiletList = [];
      var selectedBathroomList = [];
      selectedRoomCategoryList = res.data.room_category
        ? [
            {
              value: res.data.room_category.slug,
              label: res.data.room_category.room_category_name,
            },
          ]
        : "";

      var selectedRoomTypeList = [];
      selectedRoomTypeList = res.data.room_type
        ? [
            {
              value: res.data.room_type.slug,
              label: res.data.room_type.room_type_name,
            },
          ]
        : "";
      res.data.masterData.map((item) => {
        if (res.data.kitchen === item.slug) {
          selectedKitchenList = res.data.masterData
            ? [
                {
                  value: item.slug,
                  label: item.name,
                },
              ]
            : "";
        }
        if (res.data.bed_type === item.slug) {
          selectedBedTypeList = res.data.masterData
            ? [
                {
                  value: item.slug,
                  label: item.name,
                },
              ]
            : "";
        }
        if (res.data.balcony === item.slug) {
          selectedBalconyList = res.data.masterData
            ? [
                {
                  value: item.slug,
                  label: item.name,
                },
              ]
            : "";
        }
        if (res.data.toilet === item.slug) {
          selectedToiletList = res.data.masterData
            ? [
                {
                  value: item.slug,
                  label: item.name,
                },
              ]
            : "";
        }
        if (res.data.bathroom === item.slug) {
          selectedBathroomList = res.data.masterData
            ? [
                {
                  value: item.slug,
                  label: item.name,
                },
              ]
            : "";
        }
        if (res.data.living_room === item.slug) {
          selectedLivingRoomList = res.data.masterData
            ? [
                {
                  value: item.slug,
                  label: item.name,
                },
              ]
            : "";
        }
        if (res.data.listing_type === item.slug) {
          selectedRentingTypeList = res.data.masterData
            ? [
                {
                  value: item.slug,
                  label: item.name,
                },
              ]
            : "";
          if (selectedRentingTypeList[0].label !== "Entire Unit")
            this.setState({ checkListingType: 1 });
        }
      });
    }
    this.setState(resData);
    this.setState({
      selectedKitchenList: selectedKitchenList,
      selectedLivingRoomList: selectedLivingRoomList,
      selectedUnitList: selectedUnitList,
      selectedRentingTypeList: selectedRentingTypeList,
      selectedRoomCategoryList: selectedRoomCategoryList,
      selectedRoomTypeList: selectedRoomTypeList,
      selectedBedTypeList: selectedBedTypeList,
      selectedBalconyList: selectedBalconyList,
      selectedToiletList: selectedToiletList,
      selectedBathroomList: selectedBathroomList,
    });
    this.defaultData = resData;
    if (resData.unit_slug) {
      this.getResidentDataByUnit(resData.unit_slug);
    }
  }

  async getHostData() {
    var resData = {};

    let res = await ListingService.getHostData(this.state.property_slug);
    if (global.successStatus.includes(res.status)) {
      resData.host_name = res.data ? res.data.property_name : "";
      resData.host_email = res.data ? res.data.property_email : "";
      resData.host_phone_number = res.data
        ? res.data.property_phone_number
        : "";
      resData.host_photo = res.data ? res.data.property_logo : "";
      resData.imageUrl = res.data ? res.data.property_logo_url : "";
    }

    this.setState(resData);
  }

  opendeleteRoomsModal(slug) {
    this.setState({ deleteRoomsModal: true, slug: slug });
  }

  closedeleteRoomsModal() {
    this.setState({ deleteRoomsModal: false, slug: "" });
  }

  /* to get renting type data */
  async getRentingTypeData(queryString = "") {
    var rentingTypeList = [];
    var rentingTypeListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=renting_type",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      rentingTypeList = res.data ? res.data : [];
      rentingTypeListTotal = rentingTypeList ? rentingTypeList.length : 0;
    }
    const newRentingTypeList =
      rentingTypeListTotal > 0
        ? rentingTypeList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      rentingTypeList: rentingTypeList,
      rentingTypeListTotal: rentingTypeListTotal,
      newRentingTypeList: newRentingTypeList,
    });
  }

  /* to get Room Category data list */
  async getRoomCategoryData(queryString = "") {
    var roomCategoryList = [];
    var roomCategoryListTotal = 0;

    let res = await RoomCategoryService.getRoomCategory(
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      roomCategoryList = res.data.data ? res.data.data : [];
      roomCategoryListTotal = roomCategoryList ? roomCategoryList.length : 0;
    }
    const newRoomCategoryList =
      roomCategoryListTotal > 0
        ? roomCategoryList.map(({ slug, room_category_name }) => ({
            value: slug,
            label: room_category_name,
          }))
        : [];
    this.setState({
      roomCategoryList: roomCategoryList,
      roomCategoryListTotal: roomCategoryListTotal,
      newRoomCategoryList: newRoomCategoryList,
    });
  }

  /* to get room type type data */
  async getRoomTypeData(queryString = "") {
    var roomTypeList = [];
    var roomTypeListTotal = 0;

    let res = await RoomTypeService.getRoomType(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      roomTypeList = res.data ? res.data : [];
      roomTypeListTotal = roomTypeList ? roomTypeList.length : 0;
    }
    const newRoomTypeList =
      roomTypeListTotal > 0
        ? roomTypeList.map(({ slug, room_type_name }) => ({
            value: slug,
            label: room_type_name,
          }))
        : [];
    this.setState({
      roomTypeList: roomTypeList,
      roomTypeListTotal: roomTypeListTotal,
      newRoomTypeList: newRoomTypeList,
    });
  }

  onImageChange = async (event) => {
    if (event.target.files[0] !== undefined) {
      this.setState({ showSpinner: true });
      const fileData = new FormData();
      fileData.append(
        "host_photo",
        event.target.files[0],
        event.target.files[0].name
      );
      fileData.append("property_slug", this.state.property_slug);
      fileData.append(
        "resizeOptions",
        "width:240,height:240,maintainAspectRatio:true,resizeDestinationFolder:thumb||width:480,height:480,maintainAspectRatio:false,resizeDestinationFolder:medium||width:720,height:720,maintainAspectRatio:false,resizeDestinationFolder:large-720||width:1200,height:1200,maintainAspectRatio:false,resizeDestinationFolder:large-1200"
      );
      fileData.append("mediaType", "1");
      let res = await ListingService.uploadHostImg(fileData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          host_photo: res.data.fileName,
          imageUrl: res.data.filePath,
        });
        this.setState({ showSpinner: false });
      } else if (res.status === 413) {
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: "Error!",
          alertModalMessage: "Content Too Large",
        });
      } else {
        let alertMessage = "";
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            // alertMessage += item.errorMessage[0]+'\n';
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showSpinner: false,
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage:
            alertMessage !== "" ? alertMessage : res.data.message,
        });
      }
      // setTimeout(
      //   () => this.setState({ showAlertModal: false }),
      //   global.alert_close_time
      // );
    }
  };

  /* to check lisitng available or not  */
  // async getListingAvailableDate(slug) {
  //   this.setState({ showSpinner: true, loading: true });
  //   var dateCheckFrom = "04-01-2022";
  //   var dateCheckTo = "";

  //   let res = await ListingService.checkListingAvailabilty(slug);
  //   if (global.successStatus.includes(res.status)) {
  //     dateCheckFrom = Moment(dateCheckFrom).toDate("dd/MM/YYYY");
  //     dateCheckTo = new Date();
  //   }

  //   this.setState({
  //     dateCheckFrom: dateCheckFrom,
  //     dateCheckTo: dateCheckTo,
  //   });

  //
  // }

  /* to check lisitng available or not for same slug  */

  onChangePhone(value, country, e, formattedValue) {
    this.setState({ phone_number: value });
    this.setState({ dial_code: country.dialCode });
  }

  // onChangePhoneHost(value, country, e, formattedValue){
  //   this.setState({host_phone_number:value});
  //   this.setState({host_dial_code:country.dialCode});

  // }

  async getListingAvailableDateSameSlug(slug, slug2) {
    var checkDate = {};
    const dates = [];
    let res = await ListingService.checkListingAvailabiltyForSameSlug(
      slug,
      slug2
    );
    if (global.successStatus.includes(res.status)) {
      checkDate = res.data ? res.data : {};
    }
    checkDate.map((item, index) => {
      let staretDate = new Date(item.from_date + " 00:00:00");
      let endDate = new Date(item.to_date + " 00:00:00");
      const date = new Date(staretDate.getTime());

      while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
    });
    this.setState({ disabled_dates: dates });

    // checkDate.map((item, index) => {
    //   checkDate[index].from_date = Moment(item.from_date).toDate("dd/MM/YYYY");
    //   checkDate[index].to_date = Moment(item.to_date).toDate("dd/MM/YYYY");
    //   var startDate = Moment(item.from_date).subtract(1, "days");
    //   var timeEnd = Moment(item.to_date);
    //   var diff = timeEnd.diff(startDate);
    //   var diff_days = Moment.duration(diff)._data.days;
    //   var i = 0;
    //   for (i = 0; i < diff_days; i++) {
    //     disabled_dates.push({
    //       dt: startDate.add(1, "days").toDate("dd/MM/YYYY"),
    //     });
    //   }
    // });
    // this.setState({ dateFrom: checkDate });
    // this.setState({ disabled_dates: disabled_dates });
  }

  /* to check lisitng available or not  */
  async getListingAvailableDate(slug) {
    var checkDate = {};
    const dates = [];
    let res = await ListingService.checkListingAvailabilty(slug);
    if (global.successStatus.includes(res.status)) {
      checkDate = res.data ? res.data : {};
    }

    checkDate.map((item, index) => {
      let staretDate = new Date(item.from_date + " 00:00:00");
      let endDate = new Date(item.to_date + " 00:00:00");
      const date = new Date(staretDate.getTime());

      while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
    });
    this.setState({ disabled_dates: dates });
  }

  async getResidentDataByUnit(slug) {
    var resData = {};
    let res = await ListingService.getResidentDataByUnit(slug);
    if (global.successStatus.includes(res.status)) {
      resData.lease_slug = res.data && res.data.slug ? res.data.slug : "";
      resData.first_name =
        res.data.resident_user && res.data.resident_user.first_name
          ? res.data.resident_user.first_name
          : "";
      resData.last_name =
        res.data.resident_user && res.data.resident_user.last_name
          ? res.data.resident_user.last_name
          : "";
      resData.email =
        res.data.resident_user && res.data.resident_user.email
          ? res.data.resident_user.email
          : "";
      resData.phone_number =
        res.data.resident_user && res.data.resident_user.phone_number
          ? res.data.resident_user.phone_number
          : "";
      resData.resident_slug =
        res.data.resident_user && res.data.resident_user.slug
          ? res.data.resident_user.slug
          : "";
      resData.bath =
        res.data.floor_plan && res.data.floor_plan.bath
          ? res.data.floor_plan.bath
          : "";
      resData.bedroom =
        res.data.floor_plan && res.data.floor_plan.bedroom
          ? res.data.floor_plan.bedroom
          : "";
      resData.maximum_number_of_guests = res.data.maximum_number_of_guests
        ? res.data.maximum_number_of_guests
        : "";
    }
    this.setState(resData);
  }

  async getUnitDataByUnitSlug(slug) {
    var prop_unit_sku = "";
    var wi_fi_details = "";
    this.setState({ custom_link: "" });
    let res = await ListingService.getUnitSKU(slug);
    if (global.successStatus.includes(res.status)) {
      prop_unit_sku =
        res.data && res.data.property_sku && res.data.unit_number
          ? res.data.property_sku + "-" + res.data.unit_number
          : "";
      wi_fi_details =
        res.data &&
        res.data.brand_sku &&
        res.data.property_sku &&
        res.data.unit_number
          ? res.data.brand_sku +
            "-" +
            res.data.property_sku +
            "-" +
            res.data.unit_number
          : "";
    }
    this.setState({ custom_link: prop_unit_sku });
    this.setState({ wi_fi_name: wi_fi_details, wi_fi_password: wi_fi_details });
  }

  /* to get unit data list */
  async getUnitData(queryString = "") {
    var unitList = [];
    var unitListTotal = 0;

    let res = await UnitsService.getUnitsList(
      "is_dropdown=1&property_slug=" + this.state.property_slug,
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      unitList = res.data ? res.data : [];
      unitListTotal = unitList ? unitList.length : 0;
    }
    const newUnitList =
      unitListTotal > 0
        ? unitList.map(({ slug, unit_number, unit_name }) => ({
            value: slug,
            label: unit_name ? unit_name : unit_number,
          }))
        : [];
    this.setState({
      unitList: unitList,
      unitListTotal: unitListTotal,
      newUnitList: newUnitList,
    });
  }

  /* to get bed type data list */
  async getBedTypeData(queryString = "") {
    var bedTypeList = [];
    var bedTypeListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=bed_type",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      bedTypeList = res.data ? res.data : [];
      bedTypeListTotal = bedTypeList ? bedTypeList.length : 0;
    }
    const newBedTypeList =
      bedTypeListTotal > 0
        ? bedTypeList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      bedTypeList: bedTypeList,
      bedTypeListTotal: bedTypeListTotal,
      newBedTypeList: newBedTypeList,
    });
  }

  /* to get balcony data list */
  async getBalconyData(queryString = "") {
    var balconyList = [];
    var balconyListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=balcony",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      balconyList = res.data ? res.data : [];
      balconyListTotal = balconyList ? balconyList.length : 0;
    }
    const newBalconyList =
      balconyListTotal > 0
        ? balconyList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      balconyList: balconyList,
      balconyListTotal: balconyListTotal,
      newBalconyList: newBalconyList,
    });
  }

  /* to get toilet data */
  async getToiletData(queryString = "") {
    var toiletList = [];
    var toiletListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=toilet",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      toiletList = res.data ? res.data : [];
      toiletListTotal = toiletList ? toiletList.length : 0;
    }
    const newToiletList =
      toiletListTotal > 0
        ? toiletList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      toiletList: toiletList,
      toiletListTotal: toiletListTotal,
      newToiletList: newToiletList,
    });
  }

  /* to get bathroom data */
  async getBathroomData(queryString = "") {
    var bathroomList = [];
    var bathroomListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=bathroom",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      bathroomList = res.data ? res.data : [];
      bathroomListTotal = bathroomList ? bathroomList.length : 0;
    }
    const newBathroomList =
      bathroomListTotal > 0
        ? bathroomList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      bathroomList: bathroomList,
      bathroomListTotal: bathroomListTotal,
      newBathroomList: newBathroomList,
    });
  }

  /* to amenities data */
  async getAmenitiesData(queryString = "") {
    var amenitiesList = [];

    let res = await AmenityService.getAmenity(
      "level=4",
      queryString ? "&" + queryString : ""
    );
    if (global.successStatus.includes(res.status)) {
      amenitiesList = res.data ? res.data.data : [];
    }
    this.setState({ amenitiesList: amenitiesList });
  }

  /* to get property amenities data */
  async getPropertyAmenitiesData() {
    var propertyAmenitiesList = [];

    let res = await AmenityService.getPropertyAmenities(
      this.state.listing_slug + "/4"
    );
    if (global.successStatus.includes(res.status)) {
      propertyAmenitiesList = res.data ? res.data : [];
    }
    this.setState({ propertyAmenitiesList: propertyAmenitiesList });
  }

  /* to get kitchen data */
  async getKitchenData(queryString = "") {
    var kitchenList = [];
    var kitchenListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=kitchen",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      kitchenList = res.data ? res.data : [];
      kitchenListTotal = kitchenList ? kitchenList.length : 0;
    }
    const newKitchenList =
      kitchenListTotal > 0
        ? kitchenList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      kitchenList: kitchenList,
      kitchenListTotal: kitchenListTotal,
      newKitchenList: newKitchenList,
    });
  }

  /* to get living room data */
  async getLivingRoomData(queryString = "") {
    var livingRoomList = [];
    var livingRoomListTotal = 0;

    let res = await MasterDataService.getMasterData(
      "is_dropdown=1&title=living_room",
      queryString ? "&" + queryString : ""
    );

    if (global.successStatus.includes(res.status)) {
      livingRoomList = res.data ? res.data : [];
      livingRoomListTotal = livingRoomList ? livingRoomList.length : 0;
    }
    const newLivingRoomList =
      livingRoomListTotal > 0
        ? livingRoomList.map(({ slug, name }) => ({
            value: slug,
            label: name,
          }))
        : [];
    this.setState({
      livingRoomList: livingRoomList,
      livingRoomListTotal: livingRoomListTotal,
      newLivingRoomList: newLivingRoomList,
    });
  }

  confirmImportModal(slug) {
    this.getAmenitiesDetails(slug);
    this.setState({ importModal: true });
  }

  closeConfirmImportModal() {
    this.setState({ importModal: false });
  }

  saveOrUpdateListing = async (e) => {
    e.preventDefault();
    if (
      this.validator.allValid() &&
      this.customValidate() &&
      this.state.listingNameError === false
    ) {
      this.setState({ showSpinner: true, isSubmit: true });
      let inputData = {
        //first tab data
        tab: 1,
        listing_name: this.state.listing_name ? this.state.listing_name : "",
        unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
        renting_type: this.state.renting_type ? this.state.renting_type : "",
        room_category_slug: this.state.room_category
          ? this.state.room_category
          : "",
        room_type_slug: this.state.room_type ? this.state.room_type : "",
        bed_type: this.state.bed_type ? this.state.bed_type : "",
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        company_slug: this.state.company_slug ? this.state.company_slug : "",
        listing_description: this.state.listing_description
          ? this.state.listing_description
          : "",
        the_space: this.state.the_space ? this.state.the_space : "",
        guest_manual: this.state.guest_manual ? this.state.guest_manual : "",
        // airbnb_notes: this.state.airbnb_notes
        //   ? this.state.airbnb_notes
        //   : "",
        custom_link: this.state.custom_link ? this.state.custom_link : "",
        wi_fi_name: this.state.wi_fi_name ? this.state.wi_fi_name : "",
        wi_fi_password: this.state.wi_fi_password
          ? this.state.wi_fi_password
          : "",
        parking_slot: this.state.parking_slot ? this.state.parking_slot : "",
        bedroom_lock: this.state.bedroom_lock === true ? 1 : 0,
        balcony: this.state.balcony ? this.state.balcony : "",
        toilet: this.state.toilet ? this.state.toilet : "",
        kitchen: this.state.kitchen ? this.state.kitchen : "",
        living_room: this.state.living_room ? this.state.living_room : "",
        bathroom: this.state.bathroom ? this.state.bathroom : "",
        room_size: this.state.room_size ? this.state.room_size : "",
        suit: this.state.suit === true ? 1 : 0,
        from_date: this.state.from_date
          ? Moment(this.state.from_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        to_date: this.state.to_date
          ? Moment(this.state.to_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        last_to_date: this.state.to_date
          ? Moment(this.state.to_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : null,
        //for host
        host_name: this.state.host_name ? this.state.host_name : "",
        host_email: this.state.host_email ? this.state.host_email : "",
        host_phone_number: this.state.host_phone_number
          ? this.state.host_phone_number
          : "",
        dial_code: this.state.dial_code ? this.state.dial_code : "",
        // host_dial_code : this.state.host_dial_code ? this.state.host_dial_code : "",
        host_bio: this.state.host_bio ? this.state.host_bio : "",
        host_photo: this.state.host_photo ? this.state.host_photo : "",
        sort_order: this.state.sort_order ? this.state.sort_order : "",
        status_id: this.state.status_id === true ? 1 : 2,
        listing_pet_allowed: this.state.listing_pet_allowed === true ? 1 : 2,
        //for resident
        first_name: this.state.first_name ? this.state.first_name : "",
        last_name: this.state.last_name ? this.state.last_name : "",
        email: this.state.email ? this.state.email : "",
        phone_number: this.state.phone_number ? this.state.phone_number : "",
        resident_slug: this.state.resident_slug ? this.state.resident_slug : "",
        lease_slug: this.state.lease_slug ? this.state.lease_slug : "",
        airbnb_account_id: this.state.airbnb_account_id
          ? this.state.airbnb_account_id
          : "",
      };
      let inputData1 = {
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        listing_slug: this.state.listing_slug ? this.state.listing_slug : "",
        // headline: this.state.headline ? this.state.headline : "",
        why_purchased: this.state.why_purchased ? this.state.why_purchased : "",
        owner_story: this.state.owner_story ? this.state.owner_story : "",
        wi_fi: this.state.wi_fi ? this.state.wi_fi : "",
        host_intraction: this.state.host_intraction
          ? this.state.host_intraction
          : "",
        is_exempt: this.state.is_exempt === true ? 1 : 0,
        is_vat_registered: this.state.is_vat_registered === true ? 1 : 0,
      };

      let inputData2 = {
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        listing_slug: this.state.listing_slug ? this.state.listing_slug : "",
        licensenumber: this.state.license_number
          ? this.state.license_number
          : "",
        issue_date: this.state.issue_date
          ? Moment(this.state.issue_date, "YYYY-MM-DD").format("YYYY-MM-DD")
          : "",
        expiration_date: this.state.expiration_date
          ? Moment(this.state.expiration_date, "YYYY-MM-DD").format(
              "YYYY-MM-DD"
            )
          : "",
        exemption_reason: this.state.exemption_reason
          ? this.state.exemption_reason
          : "",
      };

      if (this.state.listing_slug !== undefined) {
        let res = await ListingService.updateListing(
          this.state.listing_slug,
          inputData
        );
        if (global.successStatus.includes(res.status)) {
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "success",
          //   alertModalTitle: "Success",
          //   alertModalMessage: res.message ? res.message : "Success",
          // });
          // setTimeout(
          //   () => document.getElementById("lisitngImages").click(),
          //   global.redirect_time
          // );
          // setTimeout(
          //   () =>
          //     this.props.history.push(
          //       "/properties/view/" +
          //         this.state.property_slug +
          //         "/listing/" +
          //         this.state.listing_slug +
          //         "/info"
          //     ),
          //   global.redirect_time
          // );
          this.openPublishListingModal(res.message);
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }

        let res1 = await RentalService.createListingData(inputData1);
        if (global.successStatus.includes(res1.status)) {
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "success",
          //   alertModalTitle: "Success",
          //   alertModalMessage: res1.message ? res1.message : "Success",
          // });
        } else {
          if (res1.data.result.errorDetail) {
            let errors = {};
            res1.data.errorCode = "Validation Failed";
            res1.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res1.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res1.data.errorCode
              ? res1.data.errorCode
              : "Error!",
            alertModalMessage: res1.data.message ? res1.data.message : "Error!",
          });
        }

        let res3 = await RentalService.createListingLicenseData(inputData2);
        if (global.successStatus.includes(res3.status)) {
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "success",
          //   alertModalTitle: "Success",
          //   alertModalMessage: res3.message ? res3.message : "Success",
          // });
        } else {
          if (res3.data.result.errorDetail) {
            let errors = {};
            res3.data.errorCode = "Validation Failed";
            res3.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res3.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res3.data.errorCode
              ? res3.data.errorCode
              : "Error!",
            alertModalMessage: res3.data.message ? res3.data.message : "Error!",
          });
        }
      } else {
        let res = await ListingService.createListing(inputData);

        if (global.successStatus.includes(res.status)) {
          // this.setState({
          //   showAlertModal: true,
          //   alertModalType: "success",
          //   alertModalTitle: "Success",
          //   alertModalMessage: res.message ? res.message : "Success",
          // });
          var last_insert_slug = res.data.last_insert_slug;
          this.setState({ tabWizard: 1, listing_slug: last_insert_slug });

          let inputData4 = {
            property_slug: this.state.property_slug
              ? this.state.property_slug
              : "",
            listing_slug: last_insert_slug
              ? last_insert_slug
              : this.state.listing_slug
              ? this.state.listing_slug
              : "",
            licensenumber: this.state.license_number
              ? this.state.license_number
              : "",
            issue_date: this.state.issue_date
              ? Moment(this.state.issue_date, "YYYY-MM-DD").format("YYYY-MM-DD")
              : "",
            expiration_date: this.state.expiration_date
              ? Moment(this.state.expiration_date, "YYYY-MM-DD").format(
                  "YYYY-MM-DD"
                )
              : "",
            exemption_reason: this.state.exemption_reason
              ? this.state.exemption_reason
              : "",
          };
          await RentalService.createListingLicenseData(inputData4);
          // setTimeout(
          //   () => document.getElementById("lisitngImages").click(),
          //   global.redirect_time
          // );
          // setTimeout(
          //   () =>
          //     this.props.history.push(
          //       "/properties/view/" +
          //         this.state.property_slug +
          //         "/listing/" +
          //         this.state.listing_slug +
          //         "/info"
          //     ),
          //   global.redirect_time
          // );
          this.openPublishListingModal(res.message);
        } else {
          if (res.data.result.errorDetail !== null) {
            let errors = {};
            res.data.errorCode = "Validation Failed";
            res.data.result.errorDetail.forEach((item) => {
              errors[item.errorField] = item.errorMessage[0];
              res.data.errorMessage = "";
            });
            this.setState({ errors: errors });
          }
          this.setState({
            showAlertModal: true,
            alertModalType: "error",
            alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
            alertModalMessage: res.data.message ? res.data.message : "Error!",
          });
        }
      }

      this.setState({ showSpinner: false, isSubmit: false });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.validator.showMessages();
      const input = document.getElementsByClassName("text-danger");
      if (input.length > 0) {
        input[0].scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start",
        });
      }
    }
  };

  changeHandler = (event) => {
    const { name, type, value } = event.target;
    let errors = {};
    let input = { ...this.state.input }; // Copying input object to avoid mutations
    let listingNameError = false;
    let confirmBackValue = 1;

    // Handling different input types
    let processedValue = type === "checkbox" ? event.target.checked : value;
    processedValue = type === "radio" ? parseInt(value) : processedValue;

    // Updating input object with new value
    input[name] = processedValue;

    if (name === "listing_name") {
      const regex =
        /^(?!.*[A-Z\s]*[!@#$%^&*()_+={[}\]|\\:;"'<,>.?/~`]{2,}[A-Z\s]*).*$/;
      const emojisRegex =
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
      const isValid = regex.test(value);
      const isValidEmoji = emojisRegex.test(value);

      if (!isValid || isValidEmoji) {
        listingNameError = true;
        errors["listing_name"] = isValidEmoji
          ? "Please enter a valid listing name."
          : "Please enter a valid listing name.";
      }
    }

    // Updating state with new values
    this.setState({
      [name]: processedValue,
      input: input,
      listingNameError: listingNameError,
      errors: errors,
      confirm_back: confirmBackValue,
    });

    // Validate after state update
    this.validator.showMessageFor(name);
    setTimeout(() => {
      this.customValidate();
    }, 200);
  };

  // changeHandler = (event) => {
  //   let errors = {};
  //   let input = this.state.input;
  //   let value =
  //     event.target.type === "checkbox"
  //       ? event.target.checked
  //       : event.target.value;
  //   value = event.target.type === "radio" ? parseInt(value) : value;

  //   input[event.target.name] = value;
  //   if (event.target.name === "listing_name") {
  //     const regex =
  //       /^(?!.*[A-Z\s]*[!@#$%^&*()_+={[}\]|\\:;"'<,>.?/~`]{2,}[A-Z\s]*).*$/;
  //     const emojisRegexx =
  //       /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g;
  //     const isValid = regex.test(event.target.value);
  //     const isValidEmoji = emojisRegexx.test(event.target.value);
  //     console.log("isValid", isValid, "isValidEmoji", isValidEmoji);
  //     if (isValid === false) {
  //       this.setState({ listingNameError: true });
  //       setTimeout(() => {
  //         if (
  //           input.listing_name &&
  //           input.listing_name === input.listing_name.toUpperCase()
  //         ) {
  //           if (isValidEmoji === true) {
  //             this.setState({ listingNameError: true });
  //             errors["listing_name"] = "Emojis are not allowed.";
  //           } else {
  //             this.setState({ listingNameError: true });
  //             errors["listing_name"] = "All uppercases are not allowed!";
  //           }
  //         } else {
  //           if (isValidEmoji === true) {
  //             this.setState({ listingNameError: true });
  //             errors["listing_name"] = "Emojis are not allowed.";
  //           } else {
  //             this.setState({ listingNameError: true });
  //             errors["listing_name"] = "Please enter a valid listing name.";
  //           }
  //         }
  //         this.setState({ errors: errors });
  //       }, 100);
  //     } else {
  //       if (isValidEmoji === true) {
  //         this.setState({ listingNameError: true });
  //         errors["listing_name"] = "Emojis are not allowed.";
  //       } else {
  //         this.setState({ listingNameError: false });
  //       }
  //     }
  //   }
  //   this.setState({ [event.target.name]: value, input: input });

  //   // Validate
  //   this.validator.showMessageFor(event.target.name);
  //   setTimeout(() => {
  //     this.customValidate();
  //   }, 200);

  //   this.setState({ confirm_back: 1 });
  // };

  handleChangeRentingTypeList(value) {
    let renting_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        renting_type.push(item.value);
      });
    }
    const renting_type_value = value !== null ? value.value : [];
    if (value !== null && value.label !== "Entire Unit") {
      this.setState({ checkListingType: 1 });
    } else {
      this.setState({ checkListingType: 0 });
    }
    this.setState({
      selectedRentingTypeList: value,
      renting_type: renting_type_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeUnitList(value) {
    let unit_slug = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        unit_slug.push(item.value);
      });
    }
    const unit_slug_value = value !== null ? value.value : [];
    if (value !== null) {
      this.getUnitDataByUnitSlug(unit_slug_value);
      if (this.state.listing_slug !== undefined) {
        // this.getListingAvailableDateSameSlug(
        //   unit_slug_value,
        //   this.state.listing_slug
        // );
        this.getResidentDataByUnit(unit_slug_value);
      } else {
        // this.getListingAvailableDate(unit_slug_value);
        this.getResidentDataByUnit(unit_slug_value);
      }
    }
    this.setState({
      selectedUnitList: value,
      unit_slug: unit_slug_value,
      unit_slug_for_wifi: value !== null ? value.value : "",
      from_date: "",
      to_date: "",
      wi_fi_password: "",
      wi_fi_name: "",
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeRoomCategoryList(value) {
    let room_category = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        room_category.push(item.value);
      });
    }
    const room_category_value = value !== null ? value.value : [];
    this.setState({
      selectedRoomCategoryList: value,
      room_category: room_category_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeRoomTypeList(value) {
    let room_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        room_type.push(item.value);
      });
    }
    const room_type_value = value !== null ? value.value : [];
    this.setState({
      selectedRoomTypeList: value,
      room_type: room_type_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeKitchenList(value) {
    let kitchen = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        kitchen.push(item.value);
      });
    }
    const kitchen_value = value !== null ? value.value : [];
    this.setState({
      selectedKitchenList: value,
      kitchen: kitchen_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeLivingRoomList(value) {
    let living_room = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        living_room.push(item.value);
      });
    }
    const living_room_value = value !== null ? value.value : [];
    this.setState({
      selectedLivingRoomList: value,
      living_room: living_room_value,
    });
    this.setState({ confirm_back: 1 });
  }

  handleChangeBedTypeList(value) {
    let bed_type = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        bed_type.push(item.value);
      });
    }
    const bed_type_value = value !== null ? value.value : [];
    this.setState({
      selectedBedTypeList: value,
      bed_type: bed_type_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeBalconyList(value) {
    let balcony = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        balcony.push(item.value);
      });
    }
    const balcony_value = value !== null ? value.value : [];
    this.setState({
      selectedBalconyList: value,
      balcony: balcony_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeToiletList(value) {
    let toilet = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        toilet.push(item.value);
      });
    }
    const toilet_value = value !== null ? value.value : [];
    this.setState({
      selectedToiletList: value,
      toilet: toilet_value,
    });

    this.setState({ confirm_back: 1 });
  }

  handleChangeBathroomList(value) {
    let bathroom = [];
    if (value != null && value.length > 0) {
      value.forEach((item, i) => {
        bathroom.push(item.value);
      });
    }
    const bathroom_value = value !== null ? value.value : [];
    this.setState({
      selectedBathroomList: value,
      bathroom: bathroom_value,
    });

    this.setState({ confirm_back: 1 });
  }

  changeStatus() {
    this.setState({ status_id: !this.state.status_id });
    this.validator.hideMessages();
    if (!this.state.status_id) {
      this.validator.showMessages();
    } else {
      this.validator.showMessageFor("room_name");
    }
  }

  customValidate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (
      input.the_space !== undefined &&
      input.the_space !== "" &&
      input.the_space !== null &&
      input.the_space.length > 10000
    ) {
      isValid = false;
      errors["the_space"] = "The space character limit has been exceed.";
    }
    if (
      input.listing_description !== undefined &&
      input.listing_description !== "" &&
      input.listing_description !== null &&
      input.listing_description.length > 500
    ) {
      isValid = false;
      errors["listing_description"] =
        "The listing description character limit has been exceed.";
    }
    if (
      input.guest_manual !== undefined &&
      input.guest_manual !== "" &&
      input.guest_manual !== null &&
      input.guest_manual.length > 10000
    ) {
      isValid = false;
      errors["guest_manual"] =
        "The guest manual character limit has been exceed.";
    }
    // if (
    //   input.headline !== undefined &&
    //   input.headline !== "" &&
    //   input.headline !== null &&
    //   input.headline.length > 1000
    // ) {
    //   isValid = false;
    //   errors["headline"] = "The headline character limit has been exceed.";
    // }
    if (
      input.host_bio !== undefined &&
      input.host_bio !== "" &&
      input.host_bio !== null &&
      input.host_bio.length > 100000
    ) {
      isValid = false;
      errors["host_bio"] = "The host bio character limit has been exceed.";
    }
    if (input.listing_name && this.state.listingNameError === true) {
      if (
        input.listing_name &&
        input.listing_name === input.listing_name.toUpperCase()
      ) {
        isValid = false;
        errors["listing_name"] = "All uppercases are not allowed!";
      } else {
        isValid = false;
        errors["listing_name"] = "Please enter a valid listing name.";
      }
    } else {
      if (
        input.listing_name &&
        input.listing_name === input.listing_name.toUpperCase()
      ) {
        isValid = false;
        errors["listing_name"] = "All uppercases are not allowed!";
      }
    }
    // if (
    //   input.listing_name &&
    //   input.listing_name === input.listing_name.toUpperCase()
    // ) {
    //   errors["listing_name"] = "All uppercases are not allowed!";
    // }
    // if (
    //   input.airbnb_notes !== undefined &&
    //   input.airbnb_notes !== "" &&
    //   input.airbnb_notes !== null &&
    //   input.airbnb_notes.length > 100000
    // ) {
    //   isValid = false;
    //   errors["airbnb_notes"] =
    //     "The internal notes character limit has been exceed.";
    // }
    this.setState({ errors: errors });
    return isValid;
  }

  closeModal() {
    this.setState({ showAlertModal: false });
  }

  getTitle() {
    if (this.state.listing_slug !== undefined)
      return (
        "Listings" +
        (this.state.listing_name ? " : " + this.state.listing_name : "")
      );
    else return "Listings";
  }

  async getData(queryString = "") {
    var list = [];
    var totalRecords = 0;
    let res = await RoomService.getRoomList(
      "property_slug=" +
        this.state.property_slug +
        "&unit_slug=" +
        this.state.unit_slug +
        (queryString ? "&" + queryString : "")
    );
    if (global.successStatus.includes(res.status)) {
      list = res.data.data ? res.data.data : [];
      totalRecords = list.length;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      total_record: totalRecords,
      records: list,
    });
  }

  changeView() {
    if (this.state.viewMode === 1) {
      this.setState({ viewMode: 0 });
      this.setState({ confirm_back: 0 });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.setState({
        room_category: "",
        room_type: "",
        bed_type: "",
        listing_description: "",
        the_space: "",
        guest_manual: "",
        //airbnb_notes: "",
        room_size: "",
        bedroom_lock: "",

        balcony: "",
        toilet: "",
        bathroom: "",
        suit: "",

        sort_order: "",
        errors: "",
        selectedBathroomList: [],
        selectedToiletList: [],
        selectedBalconyList: [],
        selectedBedTypeList: [],
        selectedRoomTypeList: [],
        selectedRoomCategoryList: [],
      });
      this.validator.hideMessages();
    } else {
      this.setState({ viewMode: 1 });
      this.setState({ confirm_back: 0 });
      this.validator = new SimpleReactValidator({ autoForceUpdate: this });
      this.setState({
        room_name: "",
        room_category: "",
        room_type: "",
        bed_type: "",
        listing_description: "",
        the_space: "",
        guest_manual: "",
        //airbnb_notes: "",
        room_size: "",
        bedroom_lock: "",

        balcony: "",
        toilet: "",
        bathroom: "",
        suit: "",

        sort_order: "",
        errors: "",
        selectedBathroomList: [],
        selectedToiletList: [],
        selectedBalconyList: [],
        selectedBedTypeList: [],
        selectedRoomTypeList: [],
        selectedRoomCategoryList: [],
      });
      this.validator.hideMessages();
    }
  }

  openPublishListingModal(message) {
    this.setState({ publishListingModal: true, publishMessage: message });
  }

  closePublishListingModal() {
    this.setState({ publishListingModal: false, publishMessage: "" });
  }

  async publishListing() {
    this.setState({ publishData: true });
    let inputData = {
      data: {
        listing_slug: this.state.listing_slug ? this.state.listing_slug : "",
        property_slug: this.state.property_slug ? this.state.property_slug : "",
        unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
        floor_plan_slug: this.state.floor_plan_slug
          ? this.state.floor_plan_slug
          : "",
      },
    };
    let res = await ListingService.publishListing(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    } else {
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
        alertModalMessage: res.data.message ? res.data.message : "Error!",
      });
      setTimeout(
        () => this.setState({ showAlertModal: false }),
        global.alert_close_time
      );
    }
    this.closePublishListingModal();
    this.setState({ publishData: false });
  }

  render() {
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        // color: state.isSelected ? 'black' : ((state.isSelected && state.isFocused) ? "white" : ( state.isFocused && 'white')),
        color:
          state.isSelected && state.isFocused
            ? "white"
            : state.isSelected
            ? "black"
            : state.isFocused && "white",
        backgroundColor:
          state.isSelected && state.isFocused
            ? "black"
            : state.isFocused && "black",
      }),
    };

    var stats = this.state.lockboxIds
    ? this.state.lockboxIds.includes(this.state.listing_id)
    : false;
    return (
      <>
        <main>
          <Container className="innter-container">
            <div className="d-flex flex-column">
              <div className="row row-xs mg-t-0 pd-t-0">
                {this.props.match.params.propertySlug !== undefined ? (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <LisitingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-md-2 left">
                    <div className="child-menu-section">
                      <AllPropertyLisitingSidebar
                        property_slug={this.state.property_slug}
                      />
                    </div>
                  </div>
                )}
                <div
                  className={
                    this.props.match.params.propertySlug !== undefined
                      ? "col-md-10 right"
                      : "col-md-12"
                  }
                >
                  <div className="col-md-12 mg-t-20 mg-b-10"></div>
                  <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-30 pd-r-0 pd-xs-l-15 pd-lg-l-30">
                    <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 swipersectionBtns info_box_model">
                      <h3>{this.getTitle()}</h3>
                    </div>
                  </div>
                  <div className="scrolling-carousel pd-l-30">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="info"
                      onSelect={(e) => this.getActiveClass(e)}
                      eventKey={this.state.activeClass}
                    >
                      <ScrollingCarousel
                        rightArrow={true}
                        leftArrow={true}
                        className="swipesection scroll_nav_mob"
                      >
                        <Nav.Item>
                          <Nav.Link
                            eventKey="info"
                            id="infoForm"
                            className={
                              this.state.activeClass === "info" ? "active" : ""
                            }
                          >
                            Info
                          </Nav.Link>
                        </Nav.Item>

                        <Nav.Item>
                          <Nav.Link
                            eventKey="listingImages"
                            id="lisitngImages"
                            className={
                              this.state.activeClass === "listingImages"
                                ? "active"
                                : ""
                            }
                            disabled={
                              this.state.listing_slug !== undefined
                                ? false
                                : true
                            }
                          >
                            Images
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="listingAmenities"
                            id="listingAmenities"
                            className={
                              this.state.activeClass === "listingAmenities"
                                ? "active"
                                : ""
                            }
                            disabled={
                              this.state.listing_slug !== undefined
                                ? false
                                : true
                            }
                          >
                            Amenities
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="listingPrice"
                            id="listingPrice"
                            className={
                              this.state.activeClass === "listingPrice"
                                ? "active"
                                : ""
                            }
                            disabled={
                              this.state.listing_slug !== undefined
                                ? false
                                : true
                            }
                          >
                            Price
                          </Nav.Link>
                        </Nav.Item>
                        {this.state.property_pet_allowed === 1 ? (
                          <Nav.Item>
                            <Nav.Link
                              eventKey="listingPetFee"
                              id="listingPetFee"
                              className={
                                this.state.activeClass === "listingPetFee"
                                  ? "active"
                                  : ""
                              }
                              disabled={
                                this.state.listing_slug !== undefined
                                  ? false
                                  : true
                              }
                            >
                              Pet Fees
                            </Nav.Link>
                          </Nav.Item>
                        ) : (
                          ""
                        )}
                        {/* <Nav.Item>
                        <Nav.Link
                          eventKey="specialPrice"
                          id="specialPrice"
                          className={
                            this.state.activeClass === "specialPrice"
                              ? "active"
                              : ""
                          }
                          disabled={
                            this.state.listing_slug !== undefined ? false : true
                          }
                        >
                          Custom Pricing
                        </Nav.Link>
                      </Nav.Item> */}
                        <Nav.Item>
                          <Nav.Link
                            eventKey="unavailableDates"
                            id="unavailableDates"
                            className={
                              this.state.activeClass === "unavailableDates"
                                ? "active"
                                : ""
                            }
                            disabled={
                              this.state.listing_slug !== undefined
                                ? false
                                : true
                            }
                          >
                            Resident Stay
                          </Nav.Link>
                        </Nav.Item>
                        {this.state.lock_type === 1 &&
                          this.state.listing_slug !== undefined &&
                          this.state.LOQRON_enable === 1 &&
                          stats === true && (
                            <Nav.Item>
                              <Nav.Link
                                eventKey="arrivalInstruction"
                                id="arrivalInstruction"
                                className={
                                  this.state.activeClass ===
                                  "arrivalInstruction"
                                    ? "active"
                                    : ""
                                }
                                onClick={() =>
                                  this.getTabData(this.state.listing_slug)
                                }
                                disabled={
                                  this.state.listing_slug !== undefined
                                    ? false
                                    : true
                                }
                              >
                                Arrival Instructions
                              </Nav.Link>
                            </Nav.Item>
                          )}
                        <Nav.Item>
                          <Nav.Link
                            eventKey="listingPreview"
                            id="listingPreview"
                            className={
                              this.state.activeClass === "listingPreview"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.getTabData(this.state.listing_slug)
                            }
                            disabled={
                              this.state.listing_slug !== undefined
                                ? false
                                : true
                            }
                          >
                            Preview
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="listingActivity"
                            id="listingActivity"
                            className={
                              this.state.activeClass === "listingActivity"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.getTabData(this.state.listing_slug)
                            }
                            disabled={
                              this.state.listing_slug !== undefined
                                ? false
                                : true
                            }
                          >
                            Sync
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="Onboarding"
                            id="Onboarding"
                            className={
                              this.state.activeClass === "Onboarding"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.getTabData(this.state.listing_slug)
                            }
                            disabled={
                              this.state.listing_slug !== undefined
                                ? false
                                : true
                            }
                          >
                            Activity Stream
                          </Nav.Link>
                        </Nav.Item>
                      </ScrollingCarousel>
                      <Tab.Content>
                        <Tab.Pane eventKey="info">
                          <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30 swipersectionBtns">
                            {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
                            <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns">
                              <button
                                type="button"
                                className="btn-success-outline-small"
                                onClick={() => {
                                  if (
                                    this.props.match.params.propertySlug !==
                                    undefined
                                  ) {
                                    if (this.state.listing_slug !== undefined)
                                      if (this.state.confirm_back === 1)
                                        if (
                                          window.confirm(
                                            "Do you want to exit without saving?"
                                          )
                                        )
                                          this.props.history.push(
                                            "/properties/view/" +
                                              this.state.property_slug +
                                              "/listing/" +
                                              this.state.listing_slug +
                                              "/info"
                                          );
                                        else return false;
                                      else
                                        this.props.history.push(
                                          "/properties/view/" +
                                            this.state.property_slug +
                                            "/listing/" +
                                            this.state.listing_slug +
                                            "/info"
                                        );
                                    else if (this.state.confirm_back === 1)
                                      if (
                                        window.confirm(
                                          "Do you want to exit without saving?"
                                        )
                                      )
                                        this.props.history.push(
                                          "/properties/view/" +
                                            this.state.property_slug +
                                            "/listing"
                                        );
                                      else return false;
                                    else
                                      this.props.history.push(
                                        "/properties/view/" +
                                          this.state.property_slug +
                                          "/listing"
                                      );
                                  } else {
                                    if (this.state.listing_slug !== undefined)
                                      if (this.state.confirm_back === 1)
                                        if (
                                          window.confirm(
                                            "Do you want to exit without saving?"
                                          )
                                        )
                                          this.props.history.push(
                                            "/listing/view/" +
                                              this.state.property_slug +
                                              "/" +
                                              this.state.listing_slug +
                                              "/info"
                                          );
                                        else return false;
                                      else
                                        this.props.history.push(
                                          "/listing/view/" +
                                            this.state.property_slug +
                                            "/" +
                                            this.state.listing_slug +
                                            "/info"
                                        );
                                    else if (this.state.confirm_back === 1)
                                      if (
                                        window.confirm(
                                          "Do you want to exit without saving?"
                                        )
                                      )
                                        this.props.history.push("/listings");
                                      else return false;
                                    else this.props.history.push("/listings");
                                  }
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn-success ml-3 mr-3"
                                onClick={this.saveOrUpdateListing}
                                disabled={this.state.isSubmit ? true : false}
                              >
                                {this.state.listing_slug !== undefined
                                  ? "Save"
                                  : "Save"}
                              </button>
                            </div>
                            <div className="col-md-12 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-0 mg-t-15 ">
                              <Alerts
                                show={this.state.showAlertModal}
                                type={this.state.alertModalType}
                                title={this.state.alertModalTitle}
                                message={this.state.alertModalMessage}
                              />
                            </div>

                            <form id="listing_form">
                              <div className="m-0">
                                {/* listing form */}
                                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                  <h3 className="mg-l-0 background-head">
                                    Info
                                  </h3>
                                </div>
                                <div className="row align-items-basline pd-b-0 border-bottom-0 pd-lg-l-0 pd-xs-l-15 pd-r-15">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Listing Name{" "}
                                      <span className="optional">
                                        ( {this.state.listing_name.length}/
                                        {50 - this.state.listing_name.length} )
                                      </span>
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="listing_name"
                                      value={this.state.listing_name}
                                      maxLength="50"
                                    />

                                    {this.state.errors.listing_name ? (
                                      <div className="text-danger">
                                        {this.state.errors.listing_name}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "Listing_name",
                                        this.state.listing_name,
                                        "required|max:50",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Unit{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <Select
                                      styles={customStyles}
                                      isClearable={true}
                                      className="multiselect"
                                      menuPlacement="auto"
                                      value={this.state.selectedUnitList}
                                      options={this.state.newUnitList}
                                      onChange={(value) =>
                                        this.handleChangeUnitList(value)
                                      }
                                      defaultValue={this.state.selectedUnitList}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />
                                    {/* {this.state.errors.unit_slug ? (
                                      <div className="text-danger">
                                        {this.state.errors.unit_slug}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "unit",
                                        this.state.unit_slug,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    )} */}

                                    {this.state.errors.unit_slug ? (
                                      <div className="text-danger">
                                        {this.state.errors.unit_slug}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Listing Type{" "}
                                    </label>

                                    <Select
                                      styles={customStyles}
                                      isClearable={true}
                                      className="multiselect"
                                      menuPlacement="auto"
                                      value={this.state.selectedRentingTypeList}
                                      options={this.state.newRentingTypeList}
                                      onChange={(value) =>
                                        this.handleChangeRentingTypeList(value)
                                      }
                                      defaultValue={
                                        this.state.selectedRentingTypeList
                                      }
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: "#fff",
                                          primary75: "#000",
                                          primary50: "#000",
                                          primary25: "#000",
                                        },
                                      })}
                                    />

                                    {this.state.errors.renting_type ? (
                                      <div className="text-danger">
                                        {this.state.errors.renting_type}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "listing_type",
                                        this.state.renting_type,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Listing From{" "}
                                      {this.state.unit_slug_for_wifi ? (
                                        ""
                                      ) : (
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      )}
                                    </label>
                                    <DatePicker
                                      className="form-control max_width_100"
                                      disabledKeyboardNavigation
                                      strictParsing
                                      scrollableYearDropdown
                                      autoComplete="off"
                                      showMonthDropdown
                                      showYearDropdown
                                      yearDropdownItemNumber={
                                        global.yearDropdownItemNumber
                                      }
                                      // excludeDates={[{start: Date.parse(this.state.dateCheckFrom), end: this.state.dateCheckTo}]}
                                      excludeDates={this.state.disabled_dates}
                                      //maxDate={this.state.to_date}
                                      name="from_date"
                                      dateFormat="MM/dd/yy"
                                      // minDate={
                                      //   this.state.from_date
                                      //     ? this.state.from_date
                                      //     : new Date()
                                      // }
                                      // maxDate={this.state.to_date}
                                      selected={this.state.from_date}
                                      onChange={(data) =>
                                        this.changeHandler({
                                          target: {
                                            type: "date",
                                            name: "from_date",
                                            value: data,
                                          },
                                        })
                                      }
                                    />
                                    {this.state.errors.from_date ? (
                                      <div className="text-danger">
                                        {this.state.errors.from_date}
                                      </div>
                                    ) : this.state.unit_slug_for_wifi ? (
                                      this.validator.message(
                                        "from_date",
                                        this.state.from_date,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Listing To{" "}
                                      {this.state.unit_slug_for_wifi ? (
                                        ""
                                      ) : (
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      )}
                                    </label>
                                    <DatePicker
                                      className="form-control max_width_100"
                                      disabledKeyboardNavigation
                                      strictParsing
                                      scrollableYearDropdown
                                      autoComplete="off"
                                      showMonthDropdown
                                      showYearDropdown
                                      yearDropdownItemNumber={
                                        global.yearDropdownItemNumber
                                      }
                                      excludeDates={this.state.disabled_dates.map(
                                        (item, index) => {
                                          return item.dt;
                                        }
                                      )}
                                      minDate={
                                        this.state.from_date
                                          ? this.state.from_date
                                          : new Date()
                                      }
                                      name="to_date"
                                      dateFormat="MM/dd/yy"
                                      selected={this.state.to_date}
                                      onChange={(data) =>
                                        this.changeHandler({
                                          target: {
                                            type: "date",
                                            name: "to_date",
                                            value: data,
                                          },
                                        })
                                      }
                                    />
                                    {this.state.errors.to_date ? (
                                      <div className="text-danger">
                                        {this.state.errors.to_date}
                                      </div>
                                    ) : this.state.unit_slug_for_wifi ? (
                                      this.validator.message(
                                        "to_date",
                                        this.state.to_date,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Airbnb Link{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="custom_link"
                                      value={this.state.custom_link}
                                      maxLength="200"
                                      // disabled={true}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Bedrooms{" "}
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      // name="bedroom"
                                      value={this.state.bedroom}
                                      maxLength="50"
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Bathrooms{" "}
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      // name="bath"
                                      value={this.state.bath}
                                      maxLength="50"
                                      disabled={true}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Guest Count{" "}
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      // name="maximum_number_of_guests"
                                      value={
                                        this.state.maximum_number_of_guests
                                      }
                                      maxLength="50"
                                      disabled={true}
                                    />
                                  </div>
                                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    Prohibited Pets{" "}
                                  </label>

                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    // name="listing_name"
                                    value={this.state.prohib_pet}
                                    maxLength="50"
                                    disabled={true}
                                  />
                                </div> */}

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Parking Space{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="parking_slot"
                                      value={this.state.parking_slot}
                                      // maxLength="50"
                                      // disabled={true}
                                    />
                                    {this.state.errors.parking_slot ? (
                                      <div className="text-danger">
                                        {this.state.errors.parking_slot}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Wi-Fi Name{" "}
                                      {this.state.unit_slug_for_wifi ? (
                                        ""
                                      ) : (
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      )}
                                    </label>
                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="wi_fi_name"
                                      value={this.state.wi_fi_name}
                                      maxLength="50"
                                    />

                                    {this.state.errors.wi_fi_name ? (
                                      <div className="text-danger">
                                        {this.state.errors.wi_fi_name}
                                      </div>
                                    ) : this.state.unit_slug_for_wifi ? (
                                      this.validator.message(
                                        "wi-fi_name",
                                        this.state.wi_fi_name,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Wi-Fi Password{" "}
                                      {this.state.unit_slug_for_wifi ? (
                                        ""
                                      ) : (
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      )}
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="wi_fi_password"
                                      value={this.state.wi_fi_password}
                                      // maxLength="50"
                                      // disabled={true}
                                    />
                                    {this.state.errors.wi_fi_password ? (
                                      <div className="text-danger">
                                        {this.state.errors.wi_fi_password}
                                      </div>
                                    ) : this.state.unit_slug_for_wifi ? (
                                      this.validator.message(
                                        "wi-fi_password",
                                        this.state.wi_fi_password,
                                        "required",
                                        { className: "text-danger" }
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  {this.state.property_pet_allowed === 1 ? (
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                      <label className="form-label text-left pd-b-5">
                                        Do you want to allow pets?{" "}
                                      </label>
                                      {this.state.property_pet_allowed === 1 ? (
                                        <Form.Check
                                          name="listing_pet_allowed"
                                          type={"checkbox"}
                                          id={`listing_pet_allowed`}
                                          label={""}
                                          className={"ckbox"}
                                          onChange={this.changeHandler}
                                          checked={
                                            this.state.listing_pet_allowed
                                          }
                                        />
                                      ) : (
                                        "Since the property is not pet friendly, you can not select pets in the listing."
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      License Number
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="license_number"
                                      value={this.state.license_number}
                                      // maxLength="50"
                                    />
                                    {/* {this.state.errors.license_number ? (
                                      <div className="text-danger">
                                        {this.state.errors.license_number}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "license_number",
                                        this.state.license_number,
                                        "max:50",
                                        { className: "text-danger" }
                                      )
                                    )} */}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Issue Date{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>
                                    <DatePicker
                                      className="form-control max_width_100"
                                      disabledKeyboardNavigation
                                      strictParsing
                                      scrollableYearDropdown
                                      autoComplete="off"
                                      showMonthDropdown
                                      showYearDropdown
                                      yearDropdownItemNumber={
                                        global.yearDropdownItemNumber
                                      }
                                      name="issue_date"
                                      dateFormat="MM/dd/yy"
                                      selected={this.state.issue_date}
                                      onChange={(data) =>
                                        this.changeHandler({
                                          target: {
                                            type: "date",
                                            name: "issue_date",
                                            value: data,
                                          },
                                        })
                                      }
                                    />
                                    {this.state.errors.issue_date ? (
                                      <div className="text-danger">
                                        {this.state.errors.issue_date}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Expiration Date{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>
                                    <DatePicker
                                      className="form-control max_width_100"
                                      disabledKeyboardNavigation
                                      strictParsing
                                      scrollableYearDropdown
                                      autoComplete="off"
                                      showMonthDropdown
                                      showYearDropdown
                                      yearDropdownItemNumber={
                                        global.yearDropdownItemNumber
                                      }
                                      name="expiration_date"
                                      dateFormat="MM/dd/yy"
                                      selected={this.state.expiration_date}
                                      onChange={(data) =>
                                        this.changeHandler({
                                          target: {
                                            type: "date",
                                            name: "expiration_date",
                                            value: data,
                                          },
                                        })
                                      }
                                    />
                                    {this.state.errors.expiration_date ? (
                                      <div className="text-danger">
                                        {this.state.errors.expiration_date}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Airbnb Account ID
                                      {/* <span className="optional">
                                        (Optional)
                                      </span> */}
                                    </label>
                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="airbnb_account_id"
                                      value={this.state.airbnb_account_id}
                                      onKeyPress={
                                        global.onKeyPressEvent.numberValidation
                                      }
                                      disabled={true}
                                    />
                                    {this.state.errors.airbnb_account_id ? (
                                      <div className="text-danger">
                                        {this.state.errors.airbnb_account_id}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <div className="col-md-1 pl-0">
                                      <label className="form-label text-left pd-b-5">
                                        Status
                                      </label>
                                    </div>

                                    <div className="col-8 col-md-7 col-lg-7 mg-md-t-0 pl-0 d-flex align-items-center justify-content-start ">
                                      <b
                                        className={
                                          this.state.status_id
                                            ? "active"
                                            : "inactive"
                                        }
                                      ></b>
                                      <div
                                        className={
                                          this.state.status_id
                                            ? "az-toggle on"
                                            : "az-toggle"
                                        }
                                        onClick={() => this.changeStatus()}
                                      >
                                        <span></span>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    Airbnb Link{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    type="text"
                                    onChange={this.changeHandler}
                                    name="custom_link"
                                    value={this.state.custom_link}
                                    maxLength="200"
                                  />
                                  {this.state.errors.custom_link ? (
                                    <div className="text-danger">
                                      {this.state.errors.custom_link}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div> */}
                                  {/* <div className="col-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <InputGroup className="mt-3">
                                      <label className="form-label text-left pd-b-5 w-100">
                                        Headline{" "}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="headline"
                                        value={this.state.headline}
                                        className="form-control max_width_100"
                                        id="headline"
                                        as="textarea"
                                        rows={5}
                                      />
                                    </InputGroup>
                                    {this.state.errors.headline ? (
                                      <div className="text-danger">
                                        {this.state.errors.headline}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "headline",
                                        this.state.headline,
                                        "required|max:1000",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div> */}

                                  <div className="col-12 col-lg-12 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <InputGroup className="mt-3">
                                      <label className="form-label text-left pd-b-5 w-100">
                                        Listing Description{" "}
                                        <span className="optional">
                                          ({" "}
                                          {
                                            this.state.listing_description
                                              .length
                                          }
                                          /
                                          {500 -
                                            this.state.listing_description
                                              .length}{" "}
                                          )
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="listing_description"
                                        value={this.state.listing_description}
                                        className="form-control max_width_100"
                                        id="listing_description"
                                        as="textarea"
                                        rows={5}
                                        maxLength="500"
                                      />
                                    </InputGroup>
                                    {this.state.errors.listing_description ? (
                                      <div className="text-danger">
                                        {this.state.errors.listing_description}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "listing_description",
                                        this.state.listing_description,
                                        "required|max:500",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  <div className="col-12 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <InputGroup className="mt-3">
                                      <label className="form-label text-left pd-b-5 w-100">
                                        The Space{" "}
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="the_space"
                                        value={this.state.the_space}
                                        className="form-control max_width_100"
                                        id="the_space"
                                        as="textarea"
                                        rows={10}
                                      />
                                    </InputGroup>
                                    {this.state.errors.the_space ? (
                                      <div className="text-danger">
                                        {this.state.errors.the_space}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "space",
                                        this.state.the_space,
                                        "required|max:10000",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  <div className="col-12 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <InputGroup className="mt-3">
                                      <label className="form-label text-left pd-b-5 w-100">
                                        Guest Manual{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        name="guest_manual"
                                        value={this.state.guest_manual}
                                        className="form-control max_width_100"
                                        id="guest_manual"
                                        as="textarea"
                                        rows={10}
                                        // placeholder="Username"
                                      />
                                    </InputGroup>
                                    {this.state.errors.guest_manual ? (
                                      <div className="text-danger">
                                        {this.state.errors.guest_manual}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "guest_manual",
                                        this.state.guest_manual,
                                        "max:10000",
                                        { className: "text-danger" }
                                      )
                                    )}
                                  </div>
                                  {/* <div className="col-12 col-lg-6 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    Internal Notes{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                      removePlugins: [
                                        "EasyImage",
                                        "ImageUpload",
                                        "MediaEmbed",
                                        "Table",
                                      ],
                                    }}
                                    name="airbnb_notes"
                                    minHeight="50px"
                                    data={this.state.airbnb_notes}
                                    onReady={(editor) => {
                                      editor.editing.view.change((writer) => {
                                        writer.setStyle(
                                          "height",
                                          "200px",
                                          editor.editing.view.document.getRoot()
                                        );
                                      });
                                    }}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      this.changeHandler({
                                        target: {
                                          type: "ckeditor",
                                          name: "airbnb_notes",
                                          value: data,
                                        },
                                      });
                                    }}
                                  />
                                  {this.state.errors.airbnb_notes ? (
                                    <div className="text-danger">
                                      {this.state.errors.airbnb_notes}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "airbnb_notes",
                                      this.state.airbnb_notes,
                                      "max:100000",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div> */}
                                </div>
                                {this.state.checkListingType === 1 ? (
                                  <>
                                    <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                      <h3 className="mg-l-0 mg-t-15 background-head">
                                        Room
                                      </h3>
                                    </div>
                                    <div className="row align-items-basline pd-b-0 border-bottom-0  pd-r-15">
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15  pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                          Room Category{" "}
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>

                                        <Select
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect"
                                          menuPlacement="auto"
                                          value={
                                            this.state.selectedRoomCategoryList
                                          }
                                          options={
                                            this.state.newRoomCategoryList
                                          }
                                          onChange={(value) =>
                                            this.handleChangeRoomCategoryList(
                                              value
                                            )
                                          }
                                          defaultValue={
                                            this.state.selectedRoomCategoryList
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />

                                        {this.state.errors.room_category ? (
                                          <div className="text-danger">
                                            {this.state.errors.room_category}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                          Room Type{" "}
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>

                                        <Select
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect"
                                          menuPlacement="auto"
                                          value={
                                            this.state.selectedRoomTypeList
                                          }
                                          options={this.state.newRoomTypeList}
                                          onChange={(value) =>
                                            this.handleChangeRoomTypeList(value)
                                          }
                                          defaultValue={
                                            this.state.selectedRoomTypeList
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />

                                        {this.state.errors.room_type ? (
                                          <div className="text-danger">
                                            {this.state.errors.room_type}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                          Bed Type{" "}
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>

                                        <Select
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect"
                                          menuPlacement="auto"
                                          value={this.state.selectedBedTypeList}
                                          options={this.state.newBedTypeList}
                                          onChange={(value) =>
                                            this.handleChangeBedTypeList(value)
                                          }
                                          defaultValue={
                                            this.state.selectedBedTypeList
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />

                                        {this.state.errors.bed_type ? (
                                          <div className="text-danger">
                                            {this.state.errors.bed_type}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                          Room Size (Sq. ft){" "}
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>

                                        <Form.Control
                                          type="text"
                                          onChange={this.changeHandler}
                                          name="room_size"
                                          onKeyPress={
                                            global.onKeyPressEvent
                                              .floatValidation
                                          }
                                          value={this.state.room_size}
                                          maxLength="50"
                                        />
                                        {this.state.errors.room_size ? (
                                          <div className="text-danger">
                                            {this.state.errors.room_size}
                                          </div>
                                        ) : (
                                          this.validator.message(
                                            "room_size",
                                            this.state.room_size,
                                            "numeric|min:1,num",
                                            { className: "text-danger" }
                                          )
                                        )}
                                      </div>

                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                          Balcony{" "}
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>

                                        <Select
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect"
                                          menuPlacement="auto"
                                          value={this.state.selectedBalconyList}
                                          options={this.state.newBalconyList}
                                          onChange={(value) =>
                                            this.handleChangeBalconyList(value)
                                          }
                                          defaultValue={
                                            this.state.selectedBalconyList
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />

                                        {this.state.errors.balcony ? (
                                          <div className="text-danger">
                                            {this.state.errors.balcony}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                          Toilet{" "}
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>

                                        <Select
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect"
                                          menuPlacement="auto"
                                          value={this.state.selectedToiletList}
                                          options={this.state.newToiletList}
                                          onChange={(value) =>
                                            this.handleChangeToiletList(value)
                                          }
                                          defaultValue={
                                            this.state.selectedToiletList
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />

                                        {this.state.errors.toilet ? (
                                          <div className="text-danger">
                                            {this.state.errors.toilet}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                          Bathroom{" "}
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>

                                        <Select
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect"
                                          menuPlacement="auto"
                                          value={
                                            this.state.selectedBathroomList
                                          }
                                          options={this.state.newBathroomList}
                                          onChange={(value) =>
                                            this.handleChangeBathroomList(value)
                                          }
                                          defaultValue={
                                            this.state.selectedBathroomList
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />

                                        {this.state.errors.bathroom ? (
                                          <div className="text-danger">
                                            {this.state.errors.bathroom}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                          Kitchen{" "}
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>

                                        <Select
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect"
                                          menuPlacement="auto"
                                          value={this.state.selectedKitchenList}
                                          options={this.state.newKitchenList}
                                          onChange={(value) =>
                                            this.handleChangeKitchenList(value)
                                          }
                                          defaultValue={
                                            this.state.selectedKitchenList
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />

                                        {this.state.errors.kitchen ? (
                                          <div className="text-danger">
                                            {this.state.errors.kitchen}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>

                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                        <label className="form-label text-left pd-b-5">
                                          Living Room{" "}
                                          <span className="optional">
                                            (Optional)
                                          </span>
                                        </label>

                                        <Select
                                          styles={customStyles}
                                          isClearable={true}
                                          className="multiselect"
                                          menuPlacement="auto"
                                          value={
                                            this.state.selectedLivingRoomList
                                          }
                                          options={this.state.newLivingRoomList}
                                          onChange={(value) =>
                                            this.handleChangeLivingRoomList(
                                              value
                                            )
                                          }
                                          defaultValue={
                                            this.state.selectedLivingRoomList
                                          }
                                          theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 2,
                                            colors: {
                                              ...theme.colors,
                                              primary: "#fff",
                                              primary75: "#000",
                                              primary50: "#000",
                                              primary25: "#000",
                                            },
                                          })}
                                        />

                                        {this.state.errors.living_room ? (
                                          <div className="text-danger">
                                            {this.state.errors.living_room}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15 d-flex align-items-baseline  pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                        <div className="">
                                          <ul className="formcheck-section mg-b-0">
                                            <li>
                                              <label className="form-label text-left pd-b-5">
                                                Bedroom Lock
                                              </label>
                                              <Form.Check
                                                name="bedroom_lock"
                                                type={"checkbox"}
                                                id={`bedroom_lock`}
                                                label=""
                                                className={"ckbox"}
                                                onChange={this.changeHandler}
                                                checked={
                                                  this.state.bedroom_lock
                                                }
                                              />
                                            </li>
                                            {/* <li>
                                            <label className="form-label text-left pd-b-5">
                                              Suit
                                            </label>
                                            <Form.Check
                                              name="suit"
                                              type={"checkbox"}
                                              id={`suit`}
                                              label=""
                                              className={"ckbox"}
                                              onChange={this.changeHandler}
                                              checked={this.state.suit}
                                            />
                                          </li> */}
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}

                                <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                  <h3 className="mg-l-0 background-head">
                                    Resident
                                  </h3>
                                </div>
                                <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-15 pd-r-15 pd-lg-l-0 pd-xs-l-15">
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      First Name{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="first_name"
                                      value={this.state.first_name || ""}
                                      maxLength="50"
                                      disabled={
                                        this.state.resident_slug ? true : false
                                      }
                                    />
                                    {
                                      this.state.errors.first_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.first_name}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                      //  (
                                      //   this.validator.message(
                                      //     "first_name",
                                      //     this.state.first_name,
                                      //     "required",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Last Name{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="last_name"
                                      value={this.state.last_name || ""}
                                      maxLength="50"
                                      disabled={
                                        this.state.resident_slug ? true : false
                                      }
                                    />
                                    {
                                      this.state.errors.last_name ? (
                                        <div className="text-danger">
                                          {this.state.errors.last_name}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                      // (
                                      //   this.validator.message(
                                      //     "last_name",
                                      //     this.state.last_name,
                                      //     "required",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Phone{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>
                                    <PhoneInput
                                      country={global.country}
                                      disableSearchIcon
                                      // autoFormat= "true"
                                      enableSearch="true"
                                      countryCodeEditable="true"
                                      value={this.state.phone_number}
                                      onChange={this.onChangePhone}
                                      disabled={
                                        this.state.resident_slug ? true : false
                                      }
                                    />
                                    {
                                      this.state.errors.phone_number ? (
                                        <div className="text-danger">
                                          {this.state.errors.phone_number}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                      // (
                                      //   this.validator.message(
                                      //     "phone_number",
                                      //     this.state.phone_number,
                                      //     "required",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <label className="form-label text-left pd-b-5">
                                      Email{" "}
                                      <span className="optional">
                                        (Optional)
                                      </span>
                                    </label>

                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="email"
                                      value={this.state.email || ""}
                                      maxLength="50"
                                      disabled={
                                        this.state.resident_slug ? true : false
                                      }
                                    />
                                    {
                                      this.state.errors.email ? (
                                        <div className="text-danger">
                                          {this.state.errors.email}
                                        </div>
                                      ) : (
                                        ""
                                      )
                                      //  (
                                      //   this.validator.message(
                                      //     "email",
                                      //     this.state.email,
                                      //     "required|email",
                                      //     { className: "text-danger" }
                                      //   )
                                      // )
                                    }
                                  </div>
                                  {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                        <label className="form-label text-left pd-b-5">
                                          Street 1
                                          {" "}
                                         <span className="optional">(Optional)</span>
                                        </label>
                                        <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="street_1"
                                        value={this.state.street_1}
                                        />
                                        {this.state.errors.street_1 ? (
                                        <div className="text-danger">
                                            {this.state.errors.street_1}
                                        </div>
                                        ) :''}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                      <label className="form-label text-left  pd-b-5">
                                        Street 2 <span className="optional">(Optional)</span>
                                      </label>
                                    <Form.Control
                                      type="text"
                                      onChange={this.changeHandler}
                                      name="street_2"
                                      value={this.state.street_2}
                                    />
                                    {this.state.errors.street_2 ? (
                                      <div className="text-danger">
                                        {this.state.errors.street_2}
                                      </div>
                                    ) : (
                                      this.validator.message(
                                        "street_2",
                                        this.state.street_2,
                                        "max:100",
                                        { className: "text-danger" }
                                      )
                                    )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        City  {" "}
                                      <span className="optional">(Optional)</span>
                                      </label>
                                    <Select
                                      styles={customStyles}
                                      className="multiselect"
                                      menuPlacement="auto"
                                      value={this.state.selectedCityList}
                                      options={this.state.newCityList}
                                      getOptionValue={option => `${option.label}`}
                                      onChange={(value) => this.handleChangeCityList(value)}
                                      defaultValue={this.state.selectedCityList}
                                      autoComplete="off"
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: '#fff',
                                          primary75: '#000',
                                          primary50: '#000',
                                          primary25: '#000',
                                        }
                                      })}
                                    />
                                    {this.state.errors.city_id ? (
                                      <div className="text-danger">{this.state.errors.city_id}</div>
                                    ) :'' }
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        State
                                        {" "}
                                      <span className="optional">(Optional)</span>
                                      </label>
                                    <Form.Control
                                      type="text"
                                      className="custom-disable"
                                      value={this.state.state_name}
                                      // placeholder="State"
                                      disabled={true}
                                    />
                                    {this.state.errors.state_id ? (
                                      <div className="text-danger">
                                        {this.state.errors.state_id}
                                      </div>
                                    ) : ''}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Zip Code {" "}
                                      <span className="optional">(Optional)</span>
                                      </label>
                                      <Form.Control
                                        onChange={this.changeHandler}
                                        type="text"
                                        name="zipcode"
                                        value={this.state.zipcode}
                                        maxLength="5"
                                        onKeyPress={global.onKeyPressEvent.numberValidation}
                                      />
                                      {this.state.errors.zipcode ? (
                                        <div className="text-danger">
                                          {this.state.errors.zipcode}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "zipcode",
                                          this.state.zipcode,
                                          "max:5",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      County
                                      <span className="optional">(Optional)</span>
                                    </label>
                                    <Select
                                      styles={customStyles}
                                      className="multiselect"
                                      menuPlacement="auto"
                                      value={this.state.selectedCountyList}
                                      options={this.state.newCountyList.filter((option) => {
                                        const val1 = this.state.county_id
                                          ? this.state.county_id
                                          : "";
                                        if (val1 === option.slug) return true;
                                      })}
                                      getOptionValue={option => `${option.label}`}
                                      onChange={(value) => this.handleChangeCountyList(value)}
                                      defaultValue={this.state.selectedCountyList}
                                      theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 2,
                                        colors: {
                                          ...theme.colors,
                                          primary: '#fff',
                                          primary75: '#000',
                                          primary50: '#000',
                                          primary25: '#000',
                                        }
                                      })}
                                    />

                                    {this.state.errors.county_id ? (
                                      <div className="text-danger">
                                        {this.state.errors.county_id}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-30 mg-b-15">
                                    <label className="form-label text-left pd-b-5">
                                      Country{" "}
                                      <span className="optional">(Optional)</span>
                                    </label>
                                    <Form.Control
                                      type="text"
                                      className="custom-disable"
                                      value={this.state.country_name}
                                      // placeholder="Country"
                                      disabled={true}
                                    />
                                    {this.state.errors.country_id ? (
                                      <div className="text-danger">
                                        {this.state.errors.country_id}
                                      </div>
                                    ) :''}
                                    </div>
                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-l-30 pd-r-30 pd-r-40 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Photo{" "}
                                        <span className="optional">(Optional)</span>
                                      </label>

                                      <div className="fileUpload d-flex flex-wrap justify-content-between">
                                        <span>
                                          <label className="hand-cursor">
                                            <input
                                              onChange={this.onImageChange}
                                              type="file"
                                              name="resident_photo"
                                            />
                                            {this.state.imageUrl ? (
                                              <img
                                                src={this.state.imageUrl}
                                                alt=""
                                              />
                                            ) : (
                                              <img src={hostImg} alt="" />
                                            )}
                                          </label>
                                        </span>
                                      </div>
                                    </div> */}
                                </div>
                                {/* <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                <h3 className="mg-l-0 background-head">Host</h3>
                              </div>
                              <div className="row align-items-center pd-b-5 border-bottom-0 mg-t-15 pd-r-15 pd-lg-l-0 pd-xs-l-15">
                                <div className="col-12 col-lg-4 mg-t-5 mg-md-t-0  mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    Name{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="host_name"
                                    value={this.state.host_name}
                                    className="form-control max_width_100"
                                    type="text"
                                    // placeholder="Name"
                                    disabled={true}
                                  />
                                  {this.state.errors.host_name ? (
                                    <div className="text-danger">
                                      {this.state.errors.host_name}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-12 col-lg-4 mg-t-5 mg-md-t-0 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30 mg-b-15">
                                  <label className="form-label text-left pd-b-5">
                                    Email{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    onChange={this.changeHandler}
                                    name="host_email"
                                    value={this.state.host_email}
                                    className="form-control max_width_100 custom-disable"
                                    type="text"
                                    // placeholder="Name"
                                    disabled={true}
                                  />
                                  {this.state.errors.host_email ? (
                                    <div className="text-danger">
                                      {this.state.errors.host_email}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "host_email",
                                      this.state.host_email,
                                      "email",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                                <div className="col-12 col-lg-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    Phone{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <PhoneInput
                                    country={global.country}
                                    disableSearchIcon
                                    // autoFormat= "true"
                                    enableSearch="true"
                                    countryCodeEditable="true"
                                    value={this.state.host_phone_number}
                                    onChange={(host_phone_number) =>
                                      this.setState({ host_phone_number })
                                    }
                                    disabled={true}
                                  />
                                  {this.state.errors.host_phone_number ? (
                                    <div className="text-danger">
                                      {this.state.errors.host_phone_number}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="col-12 col-lg-4 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    Photo{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>

                                  <div className="fileUpload d-flex flex-wrap justify-content-between">
                                    <span>
                                      <label className="">
                                        <input
                                          onChange={this.onImageChange}
                                          type="file"
                                          name="host_photo"
                                          disabled={true}
                                        />
                                        {this.state.imageUrl ? (
                                          <img
                                            src={this.state.imageUrl}
                                            alt=""
                                          />
                                        ) : (
                                          <img src={hostImg} alt="" />
                                        )}
                                      </label>
                                    </span>
                                  </div>

                                </div>
                                <div className="col-12 col-lg-8 mg-t-5 mg-md-t-0 mg-b-15 pd-xs-l-0 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                  <label className="form-label text-left pd-b-5">
                                    Bio/Story{" "}
                                    <span className="optional">(Optional)</span>
                                  </label>
                                  <Form.Control
                                    as="textarea"
                                    className="custom-disable"
                                    onChange={this.changeHandler}
                                    name="host_bio"
                                    value={this.state.host_bio}
                                    maxLength="500"
                                    disabled={true}
                                  />
                                  {this.state.errors.host_bio ? (
                                    <div className="text-danger">
                                      {this.state.errors.host_bio}
                                    </div>
                                  ) : (
                                    this.validator.message(
                                      "host_bio",
                                      this.state.host_bio,
                                      "max:100000",
                                      { className: "text-danger" }
                                    )
                                  )}
                                </div>
                              </div> */}

                                {/* {this.state.property_slug &&
                              this.state.listing_slug ? (
                                <>
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-30">
                                    <h3 className="mg-l-0 mg-t-15 background-head">
                                      Rental United (optional)
                                    </h3>
                                  </div>
                                  <div className="row align-items-center pd-b-0 border-bottom-0  pd-xs-l-0 pd-xs-r-0  pd-lg-l-0 pd-lg-r-15">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Why Purchased{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="why_purchased"
                                        value={this.state.why_purchased}
                                        maxLength="1000"
                                        as="textarea"
                                      />
                                      {this.state.errors.why_purchased ? (
                                        <div className="text-danger">
                                          {this.state.errors.why_purchased}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "why_purchased",
                                          this.state.why_purchased,
                                          "max:1000",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Host Interaction{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="host_intraction"
                                        value={this.state.host_intraction}
                                        maxLength="500"
                                        as="textarea"
                                      />
                                      {this.state.errors.host_intraction ? (
                                        <div className="text-danger">
                                          {this.state.errors.host_intraction}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "host_intraction",
                                          this.state.host_intraction,
                                          "max:500",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">

                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        VAT Registered
                                      </label>
                                      <Form.Check
                                        name="is_vat_registered"
                                        type={"checkbox"}
                                        id={`is_vat_registered`}
                                        label=""
                                        className={"ckbox"}
                                        onChange={this.changeHandler}
                                        checked={this.state.is_vat_registered}
                                      />
                                      {this.state.errors.email ? (
                                        <div className="text-danger">
                                          {this.state.errors.email}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "email",
                                          this.state.email,
                                          "email|max:50",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Exempt
                                      </label>
                                      <Form.Check
                                        name="is_exempt"
                                        type={"checkbox"}
                                        id={`is_exempt`}
                                        label=""
                                        className={"ckbox"}
                                        onChange={this.changeHandler}
                                        checked={this.state.is_exempt}
                                      />
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Exemption Reason{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="exemption_reason"
                                        value={this.state.exemption_reason}
                                        maxLength="500"
                                        as="textarea"
                                      />
                                      {this.state.errors.exemption_reason ? (
                                        <div className="text-danger">
                                          {this.state.errors.exemption_reason}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "exemption_reason",
                                          this.state.exemption_reason,
                                          "max:500",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        License Number{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <Form.Control
                                        type="text"
                                        onChange={this.changeHandler}
                                        name="license_number"
                                        value={this.state.license_number}
                                        maxLength="50"
                                      />
                                      {this.state.errors.license_number ? (
                                        <div className="text-danger">
                                          {this.state.errors.license_number}
                                        </div>
                                      ) : (
                                        this.validator.message(
                                          "license_number",
                                          this.state.license_number,
                                          "max:50",
                                          { className: "text-danger" }
                                        )
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Issue Date{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <DatePicker
                                        className="form-control max_width_100"
                                        disabledKeyboardNavigation
                                        strictParsing
                                        scrollableYearDropdown
                                        autoComplete="off"
                                        showMonthDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={
                                          global.yearDropdownItemNumber
                                        }
                                        name="issue_date"
                                        dateFormat="MM/dd/yy"
                                        selected={this.state.issue_date}
                                        onChange={(data) =>
                                          this.changeHandler({
                                            target: {
                                              type: "date",
                                              name: "issue_date",
                                              value: data,
                                            },
                                          })
                                        }
                                      />
                                      {this.state.errors.issue_date ? (
                                        <div className="text-danger">
                                          {this.state.errors.issue_date}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 mg-t-5 mg-md-t-0 pd-lg-l-30 pd-lg-r-30 pd-xs-l-15 pd-xs-r-15 mg-b-15">
                                      <label className="form-label text-left pd-b-5">
                                        Expiration Date{" "}
                                        <span className="optional">
                                          (Optional)
                                        </span>
                                      </label>
                                      <DatePicker
                                        className="form-control max_width_100"
                                        disabledKeyboardNavigation
                                        strictParsing
                                        scrollableYearDropdown
                                        autoComplete="off"
                                        showMonthDropdown
                                        showYearDropdown
                                        yearDropdownItemNumber={
                                          global.yearDropdownItemNumber
                                        }
                                        name="expiration_date"
                                        dateFormat="MM/dd/yy"
                                        selected={this.state.expiration_date}
                                        onChange={(data) =>
                                          this.changeHandler({
                                            target: {
                                              type: "date",
                                              name: "expiration_date",
                                              value: data,
                                            },
                                          })
                                        }
                                      />
                                      {this.state.errors.expiration_date ? (
                                        <div className="text-danger">
                                          {this.state.errors.expiration_date}
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                ""
                              )} */}
                              </div>
                            </form>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="listingImages">
                          {this.state.isRefreshlistingImages && (
                            <ListingImages
                              listing_slug={this.state.listing_slug}
                              property_slug={this.state.property_slug}
                              unit_slug={this.state.unit_slug}
                              floor_plan_slug={this.state.floor_plan_slug}
                              imgFile={this.getImgStatus}
                            />
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="listingAmenities">
                          {this.state.isRefreshlistingAmenities && (
                            <ListingAmenities
                              listing_slug={this.state.listing_slug}
                              property_slug={this.state.property_slug}
                              unit_slug={this.state.unit_slug}
                              floor_plan_slug={this.state.floor_plan_slug}
                            />
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="listingPrice">
                          {this.state.isRefreshlistingPrice && (
                            <ListingFees
                              listing_slug={this.state.listing_slug}
                              property_slug={this.state.property_slug}
                              unit_slug={this.state.unit_slug}
                              floor_plan_slug={this.state.floor_plan_slug}
                              confirmBackStatus={this.getConfirmStatus}
                            />
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="listingPetFee">
                          {this.state.isRefreshlistingPetFee && (
                            <ListingPetFee
                              listing_slug={this.state.listing_slug}
                              property_slug={this.state.property_slug}
                              unit_slug={this.state.unit_slug}
                              floor_plan_slug={this.state.floor_plan_slug}
                              confirmBackStatus={this.getConfirmStatus}
                            />
                          )}
                        </Tab.Pane>
                        {/* <Tab.Pane eventKey="specialPrice">
                        {this.state.isRefreshspecialPrice && (
                          <ListingSpecialPrice
                            listing_slug={this.state.listing_slug}
                            unit_slug={this.state.unit_slug}
                            property_slug={this.state.property_slug}
                            confirmBackStatus={this.getConfirmStatus}
                          />
                        )}
                      </Tab.Pane> */}
                        <Tab.Pane eventKey="unavailableDates">
                          {this.state.isRefreshunavailableDates && (
                            <ListingUnavailableDates
                              listing_slug={this.state.listing_slug}
                              property_slug={this.state.property_slug}
                              unit_slug={this.state.unit_slug}
                              floor_plan_slug={this.state.floor_plan_slug}
                              confirmBackStatus={this.getConfirmStatus}
                            />
                          )}
                        </Tab.Pane>
                        {this.state.lock_type === 1 &&
                          this.state.listing_slug !== undefined &&
                          this.state.LOQRON_enable === 1 &&
                         stats === true && (
                            <Tab.Pane eventKey="arrivalInstruction">
                              {this.state.isRefreshArrivalInstruction && (
                                <ListingArrivalInstruction
                                  listing_slug={this.state.listing_slug}
                                  property_slug={this.state.property_slug}
                                  unit_slug={this.state.unit_slug}
                                  floor_plan_slug={this.state.floor_plan_slug}
                                  confirmBackStatus={this.getConfirmStatus}
                                />
                              )}
                            </Tab.Pane>
                          )}
                        <Tab.Pane eventKey="listingPreview">
                          {this.state.isRefresh && (
                            <Preview
                              listing_slug={this.state.listing_slug}
                              property_slug={this.state.property_slug}
                              unit_slug={this.state.unit_slug}
                              floor_plan_slug={this.state.floor_plan_slug}
                              listingSlug={this.listingSlug}
                            />
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="listingActivity">
                          {this.state.isRefresh1 && (
                            <ListingRentalActivity
                              listing_slug={this.state.listing_slug}
                              property_slug={this.state.property_slug}
                              unit_slug={this.state.unit_slug}
                              floor_plan_slug={this.state.floor_plan_slug}
                              listingSlug={this.listingSlug}
                            />
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="Onboarding">
                          {this.state.isRefresh2 && (
                            <Onboarding
                              listing_slug={this.state.listing_slug}
                              property_slug={this.state.property_slug}
                              unit_slug={this.state.unit_slug}
                              floor_plan_slug={this.state.floor_plan_slug}
                              listingSlug={this.listingSlug}
                            />
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>

        <Modal
          show={this.state.publishListingModal}
          // onHide={() => {
          //   if (
          //     window.confirm("Are you sure to exit the pop up without delete?")
          //   )
          //     this.closedeleteListingModal();
          // }}
          centered
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closePublishListingModal()}
              >
                {global.closee}
              </span>
              {/* <img src={modaldeleteicon} alt="modal-delete-icon" /> */}
              {/* <h3>Publish to OTA?</h3> */}
              <h3></h3>
              <p>
                {this.state.publishMessage
                  ? this.state.publishMessage
                  : "Listing is successfully updated! Your changes are not published to OTA yet, do you want to publish now ? You can still publish from Sync tab later."}
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block button-width"
                  onClick={() => this.closePublishListingModal()}
                >
                  Sync Later
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block button-width"
                  onClick={this.publishListing}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Sync Now"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default ListingForm;

////////////////////////////////////////////////////////////
//     							                          //
//  Program: ReservationView.jsx                         //
//  Application: Reservation View                             //
//  Option: For view Reservation Viewd details                //
//  Developer: Ashish Kumar 				              //
//  Date: 2022-04-19                                     //
//                                                        //
////////////////////////////////////////////////////////////

import React, { Component } from "react";
import { Container, OverlayTrigger, Popover } from "react-bootstrap";
import ReservationService from "../../services/ReservationService";
import PetPolicyService from "../../services/PetPolicyService";
import CalendarSidebar from "../common/CalendarSidebar";
import Alerts from "../common/Alerts";
import { Link } from "react-router-dom";
import deleteicon from "../../assets/images/delete.svg";
import modaldeleteicon from "./../../assets/images/delete.svg";
import IglooService from "../../services/IglooService";
import { Modal, Table } from "react-bootstrap";
import Moment from "moment";
import StarRatings from "react-star-ratings";
import PropertyCostService from "../../services/PropertyCostService";
import RUmessageService from "../../services/RUmessageService";
import NumberFormat from "react-number-format";
import UserService from "../../services/UserService";
import TurnOverBnbService from "../../services/TurnOverBnbService";
import KeycafeService from "../../services/KeycafeService";
import LatchService from "../../services/LatchService";
import renderHTML from "react-render-html";
import infoicon from "../../assets/images/info-b.svg";
import moment from "moment-timezone";
import cart from "../../assets/images/guest_form.svg";
import AllPropertyReservationSidebar from "../common/AllPropertyReservationSidebar";
import pdfIcon from "../../assets/images/pdf.png";
import ReservationActivity from "../reservation/ReservationActivity";
import closeIcon from "./../../assets/images/close-wel.svg";

// import { async } from "q";

export class ReservationView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAlertModal: false,
      alertModalType: "",
      alertModalTitle: "",
      alertModalMessage: "",
      toggleSwitchDark2: true,
      addModal: false,
      activity_box: true,
      showSpinner: false,
      loading: false,
      editModal: false,
      deleteModal: false,
      submitsendAccess: false,
      submitrevokeAccess: false,
      submitsendAccess1: false,
      submitrevokeAccess1: false,
      status_id: 1,
      status_name: "",
      open: true,
      eventKey: "3",
      confirm_back: 0,
      reservation_slug: this.props.match.params.slug,
      property_slug: this.props.match.params.propertySlug
        ? this.props.match.params.propertySlug
        : this.props.match.params.outsidePropertySlug,
      outside_property_slug: this.props.match.params.outsidePropertySlug,
      cleaning_slug: "",
      pathname: window.location.pathname,
      door_type_data: [],
      reservationAccessStatus: "",
      reservationAccessCleanerStatus: "",
      first_name: "",
      last_name: "",
      checkin_date_only: "",
      checkout_date_only: "",
      multiList: [],
      multiListTotal: 0,
      inspectionData: [],
      inspectionDataTotal: 0,
      UserFirstName: JSON.parse(localStorage.getItem("userData")).first_name,
      UserLastName: JSON.parse(localStorage.getItem("userData")).last_name,
      UserEmail: JSON.parse(localStorage.getItem("userData")).email,
      UserSlug: JSON.parse(localStorage.getItem("userData")).slug,
      lock_type: localStorage.getItem("propertyLockType")
        ? parseInt(localStorage.getItem("propertyLockType"))
        : 1,
      showPetFee: 0,
      tbnbProjectData: [],
      tbnbProjectDetail: [],
      tbnbProjectAssign: [],
      isProjectCreated: false,
      taxOccupancyList: [],
      taxOccupancyListTotal: 0,
      taxList: [],
      taxListTotal: 0,
      wi_fi_name: "",
      wi_fi_password: "",
      isSearch: 0,
      guest_manual: "",
      descShow: 0,
      descShowInternalNotes: 0,
      descShowIncludeInOwnerReport: 0,
      instruction: "",
      instructionPopup: false,
      keyCafeFlag: 0,
      arrival_instruction: "",
      arrivalinstructionPopup: false,
      cleaning_url: "",
      isGuestImageModal: false,
      set_channel_name: "",
      bypass_guest_form: "",
      bypass_card_token: "",
      bypass_parking_payment: "",
      bypass_email_verification: "",
      guest_info_keep: "",
      costListTotal: 0,
      toggleValue: false,
      costList: [],
      guest_info_deleted: 0,
      LOQRON_enable: 0,
      LOQRON_listing: 0,
      lockboxIds: [],
    };
    this.opendeleteReservationModal =
      this.opendeleteReservationModal.bind(this);
    this.closedeleteReservationModal =
      this.closedeleteReservationModal.bind(this);
    this.deleteReservation = this.deleteReservation.bind(this);
    this.sendAccess = this.sendAccess.bind(this);
    this.revokeAccess = this.revokeAccess.bind(this);
    this.sendAccess1 = this.sendAccess1.bind(this);
    this.revokeAccess1 = this.revokeAccess1.bind(this);
    this.fullText = this.fullText.bind(this);
    this.fullTextInternalNotes = this.fullTextInternalNotes.bind(this);
    this.fullTextIncludeInOwnerReport =
      this.fullTextIncludeInOwnerReport.bind(this);
    this.createProjectTBNB = this.createProjectTBNB.bind(this);
    this.openInstructionPopup = this.openInstructionPopup.bind(this);
    this.openArrivalInstructionPopup =
      this.openArrivalInstructionPopup.bind(this);
    this.activityStream = this.activityStream.bind(this);
  }

  openActivity() {
    // this.setState({
    //   activity_box: false
    // })
    if (this.state.activity_box == true) {
      this.setState({
        activity_box: false,
      });
    }
    if (this.state.activity_box == false) {
      this.setState({
        activity_box: true,
      });
    }
  }
  closeActivity() {
    this.setState({
      activity_box: true,
    });
  }

  fullTextInternalNotes() {
    if (this.state.descShowInternalNotes === 0) {
      this.setState({ descShowInternalNotes: 1 });
    } else {
      this.setState({ descShowInternalNotes: 0 });
    }
  }
  fullTextIncludeInOwnerReport() {
    if (this.state.descShowIncludeInOwnerReport === 0) {
      this.setState({ descShowIncludeInOwnerReport: 1 });
    } else {
      this.setState({ descShowIncludeInOwnerReport: 0 });
    }
  }

  async getKeyCafeAccessDetails() {
    if (this.state.reservation_slug) {
      var resData = {};
      let res = await ReservationService.getKeyCafeAccess(
        this.state.reservation_slug
      );
      if (global.successStatus.includes(res.status)) {
        resData.access_code = res.data ? res.data.access_code : "";
        resData.booking_code = res.data ? res.data.booking_code : "";
        resData.keycafe_key_id = res.data ? res.data.keycafe_key_id : "";
        resData.serial_number = res.data ? res.data.serial_number : "";
        resData.access_start_date = res.data ? res.data.access_start_date : "";
        resData.access_start_time = res.data ? res.data.access_start_time : "";
        resData.access_end_date = res.data ? res.data.access_end_date : "";
        resData.acess_end_time = res.data ? res.data.acess_end_time : "";
        resData.key_status = res.data ? res.data.key_status : "";
        resData.status_time = res.data ? res.data.status_time : "";
        resData.ref_table_id = res.data ? res.data.ref_table_id : "";
        resData.keycafe_access_id = res.data ? res.data.keycafe_access_id : "";
        resData.bin_no = res.data ? res.data.bin_no : "";
        resData.current_key_status = res.data
          ? res.data.current_key_status
          : "";
        resData.key_status_updated_time = res.data
          ? res.data.key_status_updated_time
          : "";
        resData.serial_number = res.data ? res.data.serial_number : "";
        resData.lock_status = res.data ? res.data.lock_status : "";
        resData.key_name = res.data ? res.data.key_name : "";
      }
      this.setState(resData);
    }
  }

  async getKeyCafeAccessDetails1() {
    if (this.state.cleaning_slug) {
      var resData = {};
      let res = await ReservationService.getKeyCafeAccess(
        this.state.cleaning_slug
      );
      if (global.successStatus.includes(res.status)) {
        resData.access_code = res.data ? res.data.access_code : "";
        resData.booking_code1 = res.data ? res.data.booking_code : "";
        resData.keycafe_key_id1 = res.data ? res.data.keycafe_key_id : "";
        resData.serial_number1 = res.data ? res.data.serial_number : "";
        resData.access_start_date1 = res.data ? res.data.access_start_date : "";
        resData.access_start_time1 = res.data ? res.data.access_start_time : "";
        resData.access_end_date1 = res.data ? res.data.access_end_date : "";
        resData.acess_end_time1 = res.data ? res.data.acess_end_time : "";
        resData.ref_table_id1 = res.data ? res.data.ref_table_id : "";
        resData.status1 = res.data ? res.data.key_status : "";
        resData.status_time1 = res.data ? res.data.status_time : "";
        resData.keycafe_access_id1 = res.data ? res.data.keycafe_access_id : "";
        resData.bin_no1 = res.data.bin_no ? res.data.bin_no : "";
        resData.current_key_status1 = res.data
          ? res.data.current_key_status
          : "";
        resData.key_status_updated_time1 = res.data
          ? res.data.key_status_updated_time
          : "";
        resData.serial_number1 = res.data ? res.data.serial_number : "";
        resData.key_name1 = res.data ? res.data.key_name : "";
      }
      this.setState(resData);
    }
  }
  /* START: Get Latch Access schedules for backend --------------------------- */
  async getLatchAccessDetails() {
    if (this.state.reservation_slug) {
      let resData = [];
      let res = await ReservationService.getLatchAccess(
        this.state.reservation_slug
      );
      if (global.successStatus.includes(res.status)) {
        resData.latchAccess = res.data ? res.data : [];
      }
      this.setState(resData);
    }
  }
  /* END: Get Latch Access schedules for backend --------------------------- */

  async sendAccess() {
    this.setState({ submitsendAccess: true });
    let inputData = {
      property_slug: this.state.property_slug
        ? this.state.property_slug
        : this.props.property_slug,
      unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
      listing_slug: this.state.listing_slug ? this.state.listing_slug : "",
      reservation_slug: this.state.reservation_slug
        ? this.state.reservation_slug
        : "",
      user_slug: this.state.user_slug ? this.state.user_slug : "",

      checkin_date: this.state.checkin_date_only
        ? Moment(this.state.checkin_date_only).format("YYYY-MM-DD")
        : "",
      checkout_date: this.state.checkout_date_only
        ? Moment(this.state.checkout_date_only).format("YYYY-MM-DD")
        : "",
      checkin_time: this.state.checkin_time_only
        ? this.state.checkin_time_only
        : "",
      checkout_time: this.state.checkout_time_only
        ? this.state.checkout_time_only
        : "",

      first_name: this.state.first_name ? this.state.first_name : "",
      last_name: this.state.last_name ? this.state.last_name : "",
      email: this.state.email ? this.state.email : "",
      phone_number: this.state.phone_number ? this.state.phone_number : "",

      number_of_guest: this.state.number_of_guest
        ? this.state.number_of_guest
        : "",
      from_where: 1,
    };

    if (this.state.reservation_slug) {
      let res = await ReservationService.sendAccess(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.getKeyCafeAccessDetails();
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
        const input = document.getElementsByClassName("alert-danger");
        if (input.length > 0) {
          input[0].scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      }
    }
    this.setState({ submitsendAccess: false });
  }

  async revokeAccess() {
    this.setState({ submitrevokeAccess: true });

    if (this.state.reservation_slug) {
      let res = await ReservationService.revokeAccess(
        this.state.keycafe_access_id
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({
          booking_code: "",
          keycafe_key_id: "",
          serial_number: "",
          access_start_date: "",
          access_start_time: "",
          access_end_date: "",
          acess_end_time: "",
          key_status: "",
          status_time: "",
          keycafe_access_id: "",
        });
      } else {
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
    }
    this.setState({ submitrevokeAccess: false });
  }

  async sendAccess1() {
    this.setState({ submitsendAccess1: true });
    let inputData = {
      property_slug: this.state.property_slug
        ? this.state.property_slug
        : this.props.property_slug,
      unit_slug: this.state.unit_slug ? this.state.unit_slug : "",
      listing_slug: this.state.listing_slug ? this.state.listing_slug : "",
      reservation_slug: this.state.reservation_slug
        ? this.state.reservation_slug
        : "",
      cleaning_slug: this.state.cleaning_slug ? this.state.cleaning_slug : "",

      scheduled_on:
        this.state.inspectionData && this.state.inspectionData[0]
          ? this.state.inspectionData[0].scheduled_on
          : "",
      completed_on:
        this.state.inspectionData && this.state.inspectionData[0]
          ? this.state.inspectionData[0].completed_on
          : "",
      from_where: 2,
    };

    if (this.state.cleaning_slug) {
      let res = await ReservationService.sendAccess(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.getKeyCafeAccessDetails1();
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
        const input = document.getElementsByClassName("alert-danger");
        if (input.length > 0) {
          input[0].scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        }
      }
    }
    this.setState({ submitsendAccess1: false });
  }

  async revokeAccess1() {
    this.setState({ submitrevokeAccess1: true });

    if (this.state.reservation_slug) {
      let res = await ReservationService.revokeAccess(
        this.state.keycafe_access_id
      );
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.setState({
          booking_code1: "",
          keycafe_key_id1: "",
          serial_number1: "",
          access_start_date1: "",
          access_start_time1: "",
          access_end_date1: "",
          acess_end_time1: "",
          status1: "",
          status_time1: "",
          keycafe_access_id1: "",
        });
      } else {
        if (res.data.result.errorDetail !== null) {
          let errors = {};
          res.data.errorCode = "Validation Failed";
          res.data.result.errorDetail.forEach((item) => {
            errors[item.errorField] = item.errorMessage[0];
            res.data.errorMessage = "";
          });
          this.setState({ errors: errors });
        }
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
    }
    this.setState({ submitrevokeAccess1: false });
  }

  opendeleteReservationModal(slug) {
    this.setState({ deleteReservationModal: true, slug: slug });
  }

  closedeleteReservationModal() {
    this.setState({ deleteReservationModal: false, slug: "" });
  }

  async SendGenerateEmail() {
    let inputData = {
      email: this.state.email,
      is_website: 1,
    };
    this.setState({ showSpinner: true, isSubmit: true });
    var res = await UserService.SendEmailGuest(inputData);
    if (global.successStatus.includes(res.status)) {
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      this.setState({ isSearch: 0 });
      this.setState({ showSpinner: false, isSubmit: false });
    } else {
      if (res.data.result.errorDetail) {
        let errors = {};
        res.data.errorCode = "Validation Failed";
        res.data.result.errorDetail.forEach((item) => {
          errors[item.errorField] = item.errorMessage[0];
          res.data.errorMessage = "";
        });
        this.setState({ errors: errors });
      }
      this.setState({ isSearch: 0 });
      this.setState({ showSpinner: false, isSubmit: false });
    }
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async getReservationOccupancyTaxData() {
    this.setState({ showSpinner: true, loading: true });
    var taxOccupancyList = [];
    var taxOccupancyListTotal = 0;
    let res = await ReservationService.getReservationOccupancyTax(
      "property_slug=" +
        this.state.property_slug +
        "&reservation_slug=" +
        this.state.reservation_slug
    );
    if (global.successStatus.includes(res.status)) {
      taxOccupancyList = res.data ? res.data : [];
      taxOccupancyListTotal = taxOccupancyList ? taxOccupancyList.length : 0;
    }
    this.setState({
      showSpinner: false,
      loading: false,
      taxOccupancyListTotal: taxOccupancyListTotal,
      taxOccupancyList: taxOccupancyList,
    });
  }

  async deleteReservation(slug) {
    this.setState({ showSpinner: true, isSubmit: true });
    let res = await ReservationService.deleteReservation(slug);
    if (global.successStatus.includes(res.status)) {
      this.closedeleteReservationModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "success",
        alertModalTitle: "Success",
        alertModalMessage: res.message ? res.message : "Success",
      });
      setTimeout(
        () =>
          this.props.history.push(
            this.props.match.params.propertySlug !== undefined
              ? "/properties/view/" + this.state.property_slug + "/reservations"
              : "/reservations"
          ),
        global.redirect_time
      );
    } else {
      let alertMessage = "";
      this.closedeleteReservationModal();
      this.setState({
        showAlertModal: true,
        alertModalType: "error",
        alertModalTitle: res.data.message ? res.data.message : "Error!",
        alertModalMessage:
          alertMessage !== "" ? alertMessage : res.data.message,
      });
    }
    this.setState({ showSpinner: false });
    setTimeout(
      () => this.setState({ showAlertModal: false }),
      global.alert_close_time
    );
  }

  async componentDidMount() {
    const data = document.querySelector(".mobchildMenu");
    if (data != null) {
      document.querySelector(".mobchildMenu").classList.add("d-none");
    }
    this.getReservationOccupancyTaxData();

    if (this.state.reservation_slug !== undefined) {
      var resData = {};
      var inspectionData = [];
      var inspectionDataTotal = 0;
      var petFeesData = [];
      var petFeesDataTotal = 0;
      let res = await ReservationService.getReservation(
        this.state.reservation_slug
      );
      if (global.successStatus.includes(res.status)) {
        inspectionData = res.data ? res.data.inspectionData : [];
        inspectionDataTotal = inspectionData ? inspectionData.length : 0;
        resData.cleaning_slug =
          res.data &&
          res.data.inspectionData &&
          res.data.inspectionData[0] &&
          res.data.inspectionData[0].slug
            ? res.data.inspectionData[0].slug
            : "";

        resData.unit_number = res.data.unit ? res.data.unit.unit_number : "";
        resData.unit_name = res.data.unit ? res.data.unit.unit_name : "";
        resData.unit_slug = res.data.unit ? res.data.unit.slug : "";
        resData.reservationAccessStatus = res.data
          ? res.data.reservationAccessStatus
          : "";
        resData.reservationAccessCleanerStatus = res.data
          ? res.data.reservationAccessCleanerStatus
          : "";
        var resUrl =
          "/properties/view/" +
          this.state.property_slug +
          "/housekeeping-list/" +
          resData.cleaning_slug;
        this.setState({ cleaning_url: resUrl });
        resData.floor_plan_name =
          res.data &&
          res.data.unit &&
          res.data.unit.floor_plan &&
          res.data.unit.floor_plan.property_floor_plan_name
            ? res.data.unit.floor_plan.property_floor_plan_name
            : "";
        resData.floor_plan_slug =
          res.data &&
          res.data.unit &&
          res.data.unit.floor_plan &&
          res.data.unit.floor_plan.slug
            ? res.data.unit.floor_plan.slug
            : "";
        resData.listing_name = res.data.listing_details
          ? res.data.listing_details.listing_name
          : "";
        resData.listing_slug = res.data.listing_details
          ? res.data.listing_details.slug
          : "";
        resData.wi_fi_name = res.data.listing_details
          ? res.data.listing_details.wi_fi_name
          : "";
        resData.wi_fi_password = res.data.listing_details
          ? res.data.listing_details.wi_fi_password
          : "";
        resData.parking_slot = res.data.listing_details
          ? res.data.listing_details.parking_slot
          : "";
        resData.guest_manual = res.data.property
          ? res.data.property.guest_manual
          : "";
        resData.total_tower_count = res.data.total_tower_count
          ? res.data.total_tower_count
          : "";
        resData.tower_name = res.data.unit.tower
          ? res.data.unit.tower.tower_name
          : "";
        resData.aka_name = res.data.unit.tower
          ? res.data.unit.tower.aka_name
          : "";
        resData.total_stay_nights = res.data.total_stay_nights
          ? res.data.total_stay_nights
          : "";
        resData.number_of_guest = res.data.number_of_guest
          ? res.data.number_of_guest
          : "";
        resData.checkin_date = res.data.checkin_date
          ? Moment(res.data.checkin_date).format(global.dateFormat) +
            " | " +
            res.data.checkin_time
          : "";

        resData.checkin_time_only = res.data.checkin_time
          ? res.data.checkin_time
          : "";
        resData.checkout_time_only = res.data.checkout_time
          ? res.data.checkout_time
          : "";
        resData.checkin_date_only = res.data.checkin_date
          ? Moment(res.data.checkin_date).format(global.dateFormat)
          : "";
        resData.checkout_date_only = res.data.checkout_date
          ? Moment(res.data.checkout_date).format(global.dateFormat)
          : "";
        resData.checkout_date = res.data.checkout_date
          ? Moment(res.data.checkout_date).format(global.dateFormat) +
            " | " +
            res.data.checkout_time
          : "";
        resData.booked = res.data.created_at
          ? Moment(res.data.created_at).format(global.dateFormat)
          : "";
        resData.confirmation_code = res.data.confirmation_code
          ? res.data.confirmation_code
          : "";
        resData.set_channel = res.data.channel
          ? res.data.channel.channel_name
          : "";
        resData.set_channel_name = res.data.channel
          ? res.data.channel.channel_name
          : "";
        resData.listing_id = res.data.availability_id
          ? res.data.availability_id
          : 0;

        if (res.data && res.data.phone_number) {
          this.phoneNumberAutoFormat(res.data.phone_number);
        } else if (
          res.data &&
          res.data.guest_user &&
          res.data.guest_user.phone_number
        ) {
          this.phoneNumberAutoFormat(res.data.guest_user.phone_number);
        }

        resData.first_name = res.data.first_name
          ? res.data.first_name
          : res.data.guest_user && res.data.guest_user.first_name
          ? res.data.guest_user.first_name
          : "";

        resData.last_name = res.data.last_name
          ? res.data.last_name
          : res.data.guest_user && res.data.guest_user.last_name
          ? res.data.guest_user.last_name
          : "";

        resData.email = res.data.email
          ? res.data.email
          : res.data.guest_user && res.data.guest_user.email
          ? res.data.guest_user.email
          : "";

        resData.address = res.data.guest_user
          ? res.data.guest_user.address
          : "";

        resData.street_1 = res.data.street_1
          ? res.data.street_1
          : res.data.guest_user && res.data.guest_user.street_1
          ? res.data.guest_user.street_1
          : "";

        resData.street_2 = res.data.street_2
          ? res.data.street_2
          : res.data.guest_user && res.data.guest_user.street_2
          ? res.data.guest_user.street_2
          : "";

        resData.zip_code = res.data.zipcode
          ? res.data.zipcode
          : res.data.guest_user && res.data.guest_user.zip_code
          ? res.data.guest_user.zip_code
          : "";

        resData.city_name =
          res.data.city && res.data.city.city_name
            ? res.data.city.city_name
            : res.data.guest_user && res.data.guest_user.city
            ? res.data.guest_user.city.city_name
            : "";

        resData.country_name =
          res.data.country && res.data.country.country_name
            ? res.data.country.country_name
            : res.data.guest_user && res.data.guest_user.country
            ? res.data.guest_user.country.country_name
            : "";

        resData.county_name =
          res.data.county && res.data.county.county_name
            ? res.data.county.county_name
            : res.data.guest_user && res.data.guest_user.county
            ? res.data.guest_user.county.county_name
            : "";

        resData.state_name =
          res.data.state && res.data.state.state_name
            ? res.data.state.state_name
            : res.data.guest_user && res.data.guest_user.state
            ? res.data.guest_user.state.state_name
            : "";

        resData.passport = res.data.passport
          ? res.data.passport
          : res.data.guest_user && res.data.guest_user.passport
          ? res.data.guest_user.passport
          : "";
        resData.set_password = res.data.guest_user
          ? res.data.guest_user.is_password_set_mail
          : null;

        resData.bypass_guest_form =
          res.data.guest_form && res.data.guest_form.is_form_submited
            ? res.data.guest_form.is_form_submited === 1
              ? true
              : false
            : "";
        resData.is_form_submited =
          res.data.guest_form && res.data.guest_form.is_form_submited
            ? res.data.guest_form.is_form_submited
            : 0;
        resData.guest_info_deleted = res.data.guest_info_deleted
          ? res.data.guest_form.guest_info_deleted
          : 0;
        resData.bypass_card_token =
          res.data.guest_form && res.data.guest_form.card_tokenise
            ? res.data.guest_form.card_tokenise === 1
              ? true
              : false
            : "";
        resData.bypass_parking_payment =
          res.data.guest_form && res.data.guest_form.parking_payment
            ? res.data.guest_form.parking_payment === 1
              ? true
              : false
            : "";
        resData.bypass_email_verification =
          res.data.guest_form && res.data.guest_form.email_verified
            ? res.data.guest_form.email_verified == 1
              ? true
              : false
            : "";

        resData.guest_info_keep =
          res.data && res.data.guest_info_keep
            ? res.data.guest_form.guest_info_keep == 1
              ? true
              : false
            : "";

        // resData.fronImage = res.data.guest_form
        //   ? res.data.guest_form.front_photo_name
        //   : null;

        // resData.backImage = res.data.guest_form
        //   ? res.data.guest_form.back_photo_name
        //   : null;

        // resData.selfiImage = res.data.guest_form
        //   ? res.data.guest_form.selfie_photo_name
        //   : null;

        resData.receiptPdf = res.data.guest_form
          ? res.data.guest_form.receipt_pdf
          : null;

        resData.set_password = res.data.guest_user
          ? res.data.guest_user.is_password_set_mail
          : null;
        resData.activated_at = res.data.guest_form_created
          ? res.data.guest_form_created
          : null;

        resData.last_login =
          res.data.user && res.data.user.last_login
            ? moment(moment.utc(res.data.user.last_login).toDate())
                .local()
                .format(global.dateTimeFormat)
            : null;

        resData.set_password_at = res.data.guest_user
          ? moment(
              moment.utc(res.data.guest_user.is_password_set_mail_at).toDate()
            )
              .local()
              .format(global.dateTimeFormat)
          : null;

        resData.is_id_verified = res.data.guest_user
          ? res.data.guest_user.is_id_verified
          : "";
        resData.resident_earning = res.data.resident_earning
          ? res.data.resident_earning.replace(/\.00$/, "")
          : "";
        resData.airbnb_earning = res.data.airbnb_earning
          ? res.data.airbnb_earning.replace(/\.00$/, "")
          : "";
        resData.host_service_fee = res.data.host_service_fee
          ? res.data.host_service_fee.replace(/\.00$/, "")
          : "";
        resData.total_nightly_rate = res.data.total_nightly_rate
          ? res.data.total_nightly_rate.replace(/\.00$/, "")
          : "";
        resData.guest_paid = res.data.guest_paid
          ? res.data.guest_paid.replace(/\.00$/, "")
          : "";
        resData.payment_processing_fee = res.data.payment_processing_fee
          ? res.data.payment_processing_fee.replace(/\.00$/, "")
          : "";
        resData.host_payout = res.data.host_payout
          ? res.data.host_payout.replace(/\.00$/, "")
          : "";
        resData.oh_earning_resolution = res.data.oh_earning_resolution
          ? res.data.oh_earning_resolution.replace(/\.00$/, "")
          : "";
        resData.property_earning_resolution = res.data
          .property_earning_resolution
          ? res.data.property_earning_resolution.replace(/\.00$/, "")
          : "";
        resData.resident_earning_resolution = res.data
          .resident_earning_resolution
          ? res.data.resident_earning_resolution.replace(/\.00$/, "")
          : "";
        /* pet fee field is used for additional fee */
        resData.pet_fee = res.data.pet_fee
          ? res.data.pet_fee.replace(/\.00$/, "")
          : "";
        resData.pet_policy_fee = res.data.pet_policy_fee
          ? res.data.pet_policy_fee.replace(/\.00$/, "")
          : "";
        resData.security_deposite = res.data.security_deposite
          ? res.data.security_deposite.replace(/\.00$/, "")
          : "";
        resData.total_resolution_fee = res.data.resolution_fee
          ? res.data.resolution_fee.replace(/\.00$/, "")
          : "";
        resData.to_oh_total = res.data.to_oh_total
          ? res.data.to_oh_total.replace(/\.00$/, "")
          : "";
        resData.to_resident_total = res.data.to_resident_total
          ? res.data.to_resident_total.replace(/\.00$/, "")
          : "";
        resData.to_building_total = res.data.to_building_total
          ? res.data.to_building_total.replace(/\.00$/, "")
          : "";
        // resData.convenience_fee = res.data.convenience_fee
        // ? res.data.convenience_fee.replace(/\.00$/, "")
        // : "";
        resData.parking_slot = res.data.listing_details
          ? res.data.listing_details.parking_slot
          : "";

        resData.internal_notes = res.data.internal_notes
          ? res.data.internal_notes
          : "";
        resData.include_owner_report = res.data.include_owner_report
          ? res.data.include_owner_report
          : "";
        resData.adults = res.data.adults ? res.data.adults : "";
        resData.children = res.data.children ? res.data.children : "";
        resData.infants = res.data.infants ? res.data.infants : "";
        resData.pets = res.data.pets ? res.data.pets : "";
        if (
          resData.pets &&
          resData.pets !== undefined &&
          resData.pets !== null &&
          resData.pets !== ""
        ) {
          this.setState({ showPetFee: 1 });
          petFeesData = res.data.petFeesData ? res.data.petFeesData : [];
          petFeesDataTotal = petFeesData ? petFeesData.length : 0;
        }
        resData.property_pet_allowed = res.data.property
          ? res.data.property.pet_allowed
          : "";
        resData.listing_pet_allowed = res.data.listing_details
          ? res.data.listing_details.listing_pet_allowed
          : "";
        resData.rating = res.data.guestReservationRating
          ? res.data.guestReservationRating.rating
          : 0;
        resData.review = res.data.guestReservationRating
          ? res.data.guestReservationRating.review
          : 0;
        // resData.cleanliness_rating = res.data.guestReservationRating
        //   ? res.data.guestReservationRating.cleanliness
        //   : 0;
        // resData.accuracy_rating = res.data.guestReservationRating
        //   ? res.data.guestReservationRating.accuracy
        //   : 0;
        // resData.communication_rating = res.data.guestReservationRating
        //   ? res.data.guestReservationRating.communication
        //   : 0;
        //resData.location_rating = res.data.guestReservationRating
        //   ? res.data.guestReservationRating.location
        //   : 0;
        // resData.check_in_rating = res.data.guestReservationRating
        //   ? res.data.guestReservationRating.check_in
        //   : 0;
        // resData.value_rating = res.data.guestReservationRating
        //   ? res.data.guestReservationRating.value
        //   : 0;
        // resData.comment = res.data.guestReservationRating
        //   ? res.data.guestReservationRating.comment
        //   : "";
        // resData.is_publish = res.data.guestReservationRating
        //   ? res.data.guestReservationRating.is_publish
        //   : "";
        resData.slug = res.data ? res.data.slug : "";
        resData.partner_reservation_id = res.data
          ? res.data.partner_reservation_id
          : "";
        resData.user_slug = res.data.user ? res.data.user.slug : "";

        resData.lock_type =
          res.data.property && res.data.property.lock_type
            ? res.data.property.lock_type
            : "";
        if (
          res.data.property &&
          res.data.property.lock_type &&
          res.data.property.lock_type === 2
        ) {
          this.getLatchInstructionData();
        } else if (
          res.data.property &&
          res.data.property.lock_type &&
          res.data.property.lock_type === 1
        ) {
          this.getKeyInstructionData();
        } else if (
          res.data.property &&
          res.data.property.lock_type &&
          res.data.property.lock_type === 3
        ) {
          this.getIglooInstruction();
        }

        if (res.data.feeArray) {
          res.data.feeArray.forEach((item, i) => {
            if (item.fees_name === "Cleaning Fee") {
              resData.cleaning_fee = item.fees_amount
                ? item.fees_amount.replace(/\.00$/, "")
                : "";
            }
            if (item.fees_name === "Service Fee") {
              resData.service_fee = item.fees_amount
                ? item.fees_amount.replace(/\.00$/, "")
                : "";
            }
          });
        }
        if (res.data.taxArray) {
          if (res.data.taxArray.tax_name === "Occupancy Tax Fee") {
            resData.occupancy_tax_fee =
              res.data.taxArray && res.data.taxArray.tax_amount
                ? res.data.taxArray.tax_amount.replace(/\.00$/, "")
                : "";
          }
        }

        resData.nightly_rate = res.data.nightly_rate
          ? res.data.nightly_rate.replace(/\.00$/, "")
          : "";
        resData.total_oh_earning = res.data.total_oh_earning
          ? res.data.total_oh_earning.replace(/\.00$/, "")
          : "";
        resData.total_property_earning = res.data.total_property_earning
          ? res.data.total_property_earning.replace(/\.00$/, "")
          : "";

        resData.reservationResolutionFeeData =
          res.data && res.data.reservationResolutionFeeData
            ? res.data.reservationResolutionFeeData
            : [];
        resData.door_type_data = res.data.unlock_door.map((item) => ({
          slug: item.slug ? item.slug : "",
          name: item.unlock_door_type ? item.unlock_door_type.name : "",
          door_code: item.unlock_password ? item.unlock_password : "",
        }));

        const currentDate = new Date();

        var dateFrom = Moment(resData.checkin_date).format("MM/DD/YY");
        var dateTo = Moment(resData.checkout_date).format("MM/DD/YY");
        var dateCheck = Moment(currentDate).format("MM/DD/YY");

        var d1 = dateFrom.split("/");
        var d2 = dateTo.split("/");
        var c = dateCheck.split("/");

        if (res.data.status_id === 1 && c >= d1 && c <= d2) {
          resData.status_id = "Currently Hosting";
        } else {
          if (res.data.status_id === 1) {
            resData.status_id = "Confirmed";
          } else if (res.data.status_id === 2) {
            resData.status_id =
              "Cancelled" +
              (res.data.cancelled_date ? " | " + res.data.cancelled_date : "");
          } else if (res.data.status_id === 3) {
            resData.status_id = "Payment Pending";
          } else {
            resData.status_id = "";
          }
        }

        sessionStorage.setItem(
          "property_slug",
          res.data.property ? res.data.property.slug : ""
        );
        sessionStorage.setItem(
          "property_name",
          res.data.property ? res.data.property.property_name : ""
        );
        sessionStorage.setItem(
          "property_sku",
          res.data.property ? res.data.property.aka_name : ""
        );
        sessionStorage.setItem(
          "property_logo_thumb_url",
          res.data.property ? res.data.property.property_logo_thumb_url : ""
        );

        localStorage.setItem(
          "property_slug",
          res.data.property ? res.data.property.slug : ""
        );
        localStorage.setItem(
          "property_name",
          res.data.property ? res.data.property.property_name : ""
        );
        localStorage.setItem(
          "property_sku",
          res.data.property ? res.data.property.aka_name : ""
        );
        localStorage.setItem(
          "property_logo_thumb_url",
          res.data.property ? res.data.property.property_logo_thumb_url : ""
        );

        this.props.propertyName(
          res.data.property && res.data.property.property_name
            ? res.data.property.property_name
            : "Property"
        );
        this.props.propertySlug(
          res.data.property && res.data.property.slug
            ? res.data.property.slug
            : ""
        );
        resData.arrival_instruction = res.data.property
          ? res.data.property.arrival_instruction
          : null;
      }

      this.setState(resData);
      this.setState({
        inspectionData: inspectionData,
        inspectionDataTotal: inspectionDataTotal,
        petFeesData: petFeesData,
        petFeesDataTotal: petFeesDataTotal,
      });
      this.getProjectDetail();
      this.getLatchAccessDetails();
    }
    this.getPetPolicyData();
    this.getListingFees();
    this.getKeyCafeAccessDetails();
    this.getKeyCafeAccessDetails1();
    // this.getKeyInstructionData();
    // this.getIglooInstruction();
    this.getKeyCafeFlag();
    this.getPropertyEarningConfigurationData();
  }

  phoneNumberAutoFormat = async (val) => {
    var lastTenDigits = "";
    const cleanedNumber = val.replace(/[\s-]/g, "");
    if (cleanedNumber.length > 10) {
      // If it's longer, keep only the last 10 characters
      lastTenDigits = cleanedNumber.slice(-10);
    } else {
      // If it's 10 or less characters, set it as is
      lastTenDigits = val;
    }

    var number = lastTenDigits.trim().replace(/[^0-9]/g, "");
    var finalnumber = "";
    if (number.length < 4) {
      finalnumber = number;
    } else if (number.length < 7) {
      finalnumber = number.replace(/(\d{3})(\d{1})/, "$1-$2");
    } else if (number.length < 11) {
      finalnumber = number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
    } else {
      finalnumber = number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
    }
    this.setState({ phone_number: finalnumber });
  };

  async showGuestImages() {
    if (this.state.isGuestImageModal === true) {
      this.setState({ isGuestImageModal: false });
    } else {
      let res = await ReservationService.getPresignedGuestIds(
        "reservation_slug=" + this.state.reservation_slug
      );
      this.setState({ isGuestImageModal: true });
      if (global.successStatus.includes(res.status)) {
        let front_url = "";
        let back_url = "";
        let selfies_url = "";

        if (res.data) {
          front_url = res.data.front_photo_name;
          back_url = res.data.back_photo_name;
          selfies_url = res.data.selfie_photo_name;

          this.setState({
            selfi_image_url: selfies_url,
            front_image_url: front_url,
            back_image_url: back_url,
          });
        }
      }
    }
  }

  async getPropertyEarningConfigurationData() {
    if (this.state.slug !== undefined) {
      var earningConfiguration = [];
      var earningConfigurationTotal = 0;
      var total_nightly_rate_to_resident = 0;
      var cleaning_fee_to_resident = 0;
      var channel_fee_to_resident = 0;
      var total_nightly_rate_to_oh = 0;
      var cleaning_fee_to_oh = 0;
      var channel_fee_to_oh = 0;
      var total_nightly_rate_to_property = 0;
      var cleaning_fee_to_property = 0;
      var channel_fee_to_property = 0;
      var resData = {};
      const values = [];
      var res = "";

      res = await ReservationService.getReservationEarningConfiguration(
        "slug=" +
          this.state.slug +
          "&reference=reservation_details" +
          "&list=" +
          12
      );

      if (global.successStatus.includes(res.status)) {
        resData.earningConfigurationDataLength = res.data ? res.data.length : 0;
        resData.earningConfigurationData = res.data
          ? res.data
          : [
              {
                fee_type: 1,
                fee_type_name: "Total Nightly Rate",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 2,
                fee_type_name: "Cleaning Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
              {
                fee_type: 3,
                fee_type_name: "Channel Fees",
                payout_oh: "",
                payout_resident: "",
                payout_property: "",
                slug: "",
              },
            ];

        if (resData.earningConfigurationDataLength > 0) {
          resData.earningConfigurationData.forEach((item, i) => {
            values.push({
              fee_type: item.fee_type,
              fee_type_name: item.fee_type_name,
              payout_oh: item.payout_oh,
              payout_resident: item.payout_resident,
              payout_property: item.payout_property,
              slug: item.slug,
            });
          });
        } else {
          resData.earningConfigurationData = [
            {
              fee_type: 1,
              fee_type_name: "Total Nightly Rate",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 2,
              fee_type_name: "Cleaning Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
            {
              fee_type: 3,
              fee_type_name: "Channel Fees",
              payout_oh: "",
              payout_resident: "",
              payout_property: "",
              slug: "",
            },
          ];
        }
        earningConfiguration = res.data ? res.data : [];
        earningConfigurationTotal = earningConfiguration
          ? earningConfiguration.length
          : 0;
      }
      if (earningConfigurationTotal > 0) {
        earningConfiguration.map((opt, i) => {
          if (opt.fee_type === 1) {
            total_nightly_rate_to_resident = opt.payout_resident
              ? opt.payout_resident.replace(/\.00$/, "")
              : "";
            total_nightly_rate_to_oh = opt.payout_oh
              ? opt.payout_oh.replace(/\.00$/, "")
              : "";
            total_nightly_rate_to_property = opt.payout_property
              ? opt.payout_property.replace(/\.00$/, "")
              : "";
          }
          if (opt.fee_type === 2) {
            cleaning_fee_to_resident = opt.payout_resident
              ? opt.payout_resident.replace(/\.00$/, "")
              : "";
            cleaning_fee_to_oh = opt.payout_oh
              ? opt.payout_oh.replace(/\.00$/, "")
              : "";
            cleaning_fee_to_property = opt.payout_property
              ? opt.payout_property.replace(/\.00$/, "")
              : "";
          }
          if (opt.fee_type === 3) {
            channel_fee_to_resident = opt.payout_resident
              ? opt.payout_resident.replace(/\.00$/, "")
              : "";
            channel_fee_to_oh = opt.payout_oh
              ? opt.payout_oh.replace(/\.00$/, "")
              : "";
            channel_fee_to_property = opt.payout_property
              ? opt.payout_property.replace(/\.00$/, "")
              : "";
          }
        });
      }
      this.setState({
        total_nightly_rate_to_resident: total_nightly_rate_to_resident,
        total_nightly_rate_to_oh: total_nightly_rate_to_oh,
        total_nightly_rate_to_property: total_nightly_rate_to_property,
        cleaning_fee_to_resident: cleaning_fee_to_resident,
        cleaning_fee_to_oh: cleaning_fee_to_oh,
        cleaning_fee_to_property: cleaning_fee_to_property,
        channel_fee_to_resident: channel_fee_to_resident,
        channel_fee_to_oh: channel_fee_to_oh,
        channel_fee_to_property: channel_fee_to_property,
        earning_configuration: values,
        earningConfigurationData: values,
      });
    }
  }

  async getLatchInstructionData() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await LatchService.getLatchInstruction(
      "property_slug=" + this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      resData.instruction =
        res.data && res.data.latch_description
          ? res.data.latch_description
          : "";
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  async getKeyInstructionData() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await KeycafeService.getKeycafeInstruction(
      "property_slug=" +
        this.state.property_slug +
        "&revervation_slug=" +
        this.state.reservation_slug +
        "&viewMode=1"
    );
    if (global.successStatus.includes(res.status)) {
      resData.instruction =
        res.data && res.data.keycafe_description
          ? res.data.keycafe_description
          : "";
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  async getIglooInstruction() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await IglooService.getIglooInstructionlock(
      "property_slug=" +
        this.state.property_slug +
        "&revervation_slug=" +
        this.state.reservation_slug +
        "&viewMode=1"
    );
    if (global.successStatus.includes(res.status)) {
      resData.instruction =
        res.data && res.data.igloo_description
          ? res.data.igloo_description
          : "";
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  async getKeyCafeFlag() {
    this.setState({ showSpinner: true, loading: true });
    var resData = {};
    let res = await KeycafeService.getKeycafeSetting(
      "property_slug=" + this.state.property_slug
    );
    if (global.successStatus.includes(res.status)) {
      resData.keyCafeFlag = res.data ? res.data.is_use_keycafe : 0;
      resData.LOQRON_enable = res.data ? res.data.LOQRON_enable : 0;
      resData.LOQRON_listing = res.data ? res.data.LOQRON_listing : 0;
      resData.lockboxIds = res.data ? res.data.lockbox_ids : [];
    }
    this.setState(resData);
    this.setState({ showSpinner: false, loading: false });
  }

  searchToggle = () => {
    if (this.state.isSearch === 1) {
      this.setState({ isSearch: 0 });
    } else {
      this.setState({ isSearch: 1 });
    }
  };
  async getListingFees() {
    this.setState({ showSpinner: true, loading: true });
    var costList = [];
    var costListTotal = 0;
    let res = await PropertyCostService.getAllCosts(
      this.state.reservation_slug + "/12"
    );
    if (global.successStatus.includes(res.status)) {
      costList = res.data ? res.data : "";
      costListTotal = costList ? costList.length : 0;
    }
    this.setState({ costList: costList, costListTotal: costListTotal });
    this.setState({ showSpinner: false, loading: false });
  }

  // post thread request
  async postThread() {
    this.setState({ isMessage: true });
    if (this.state.reservation_slug != undefined) {
      let inputData = {
        property_slug: this.state.property_slug,
        role_slug: global.currerntRoleSlug,
        reservation_slug: this.state.reservation_slug,
        is_support: global.is_support,
        thread_type: global.threadType.Guest,
      };
      let res = await RUmessageService.createThread(inputData);
      if (global.successStatus.includes(res.status)) {
        if (res.data.slug) {
          window.open(
            "/properties/view/" +
              this.state.property_slug +
              "/messages/" +
              res.data.slug,
            "_blank"
          );
        }
        this.setState({ isMessage: false });
      }
    }
  }
  async getPetPolicyData() {
    //this.setState({ showSpinner: true, loading: true });
    var multiList = [];
    var multiListTotal = 0;

    let res = await PetPolicyService.getPetPolicy(
      this.state.property_slug + "/3"
    );
    if (global.successStatus.includes(res.status)) {
      multiList = res.data.petPolicyRestrictions
        ? res.data.petPolicyRestrictions
        : [];
      multiListTotal = multiList ? multiList.length : 0;
    }
    this.setState({
      multiList: multiList,
      multiListTotal: multiListTotal,
    });
    //this.setState({ showSpinner: false, loading: false });
  }

  getTitle() {
    return "Reservations";
  }

  getConfirmStatus(confirmBack) {
    this.setState({ confirm_back: confirmBack });
  }
  activityStream() {
    this.setState({ toggleValue: !this.state.toggleValue });
  }
  fullText() {
    if (this.state.descShow === 0) {
      this.setState({ descShow: 1 });
    } else {
      this.setState({ descShow: 0 });
    }
  }

  /* TBNB Start */
  async getProjectDetail() {
    this.setState({ showSpinner: true, loading: true });
    var tbnbProjectData = {};
    var tbnbProjectDetail = [];
    var tbnbProjectAssign = [];
    let res = await TurnOverBnbService.getProjectDetails(
      "reservation_slug=" + this.state.reservation_slug
    );
    if (global.successStatus.includes(res.status)) {
      if (res.data.project_detail) {
        this.setState({ isProjectCreated: true });
      }
      tbnbProjectData = res.data ? res.data : {};
      tbnbProjectDetail = res.data ? res.data.project_detail : "";
      tbnbProjectAssign = res.data ? res.data.assignment_detail : "";
      this.setState({
        tbnbProjectData: tbnbProjectData,
        tbnbProjectDetail: tbnbProjectDetail,
        tbnbProjectAssign: tbnbProjectAssign,
      });
    }
    this.setState({ showSpinner: false, loading: false });
  }

  async createProjectTBNB() {
    let inputData = {
      reservation_slug: this.state.reservation_slug
        ? this.state.reservation_slug
        : "",
    };
    if (this.state.reservation_slug) {
      let res = await TurnOverBnbService.createProject(inputData);
      if (global.successStatus.includes(res.status)) {
        this.setState({
          showAlertModal: true,
          alertModalType: "success",
          alertModalTitle: "Success",
          alertModalMessage: res.message ? res.message : "Success",
        });
        this.getProjectDetail();
      } else {
        this.setState({
          showAlertModal: true,
          alertModalType: "error",
          alertModalTitle: res.data.errorCode ? res.data.errorCode : "Error!",
          alertModalMessage: res.data.message ? res.data.message : "Error!",
        });
      }
    }
  }
  /* TBNB End */

  openArrivalInstructionPopup() {
    this.setState({ arrivalinstructionPopup: true });
  }
  closeArrivalInstructionPopup() {
    this.setState({ arrivalinstructionPopup: false });
  }

  openInstructionPopup() {
    this.setState({ instructionPopup: true });
  }

  closeInstructionPopup() {
    this.setState({ instructionPopup: false });
  }

  render() {
    let A = Moment(this.state.checkin_date);
    let B = Moment(new Date()).format("YYYY-MM-DD");
    let dateDiff = A.diff(B, "days") >= 0;
    let AWS_URL_STG = process.env.REACT_APP_AWS_URL;
    // let fromImage = this.state.fronImage;
    // let backImage = this.state.backImage;
    // let selfieImage = this.state.selfiImage;
    let receiptPdf =
      this.state.receiptPdf !== null ? this.state.receiptPdf : null;
    // const front_image_url = `${AWS_URL_STG}/guest_user_front/${fromImage}`;
    // const back_image_url = `${AWS_URL_STG}/guest_user_back/${backImage}`;
    // const selfi_image_url = `${AWS_URL_STG}/guest_user_selfie/${selfieImage}`;
    const receipt_pdf_url =
      receiptPdf !== null
        ? `${AWS_URL_STG}/guest_form_agrement/${receiptPdf}`
        : null;

        var stats = this.state.lockboxIds
        ? this.state.lockboxIds.includes(this.state.listing_id)
        : false;
    return (
      <main>
        {/* {this.state.showSpinner ? global.spinnerLoader : ""} */}
        <Container className="innter-container">
          <div className="d-flex flex-column">
            <div className="row row-xs mg-t-0 pd-t-0 pd-lg-l-15 pd-xs-l-0">
              {this.props.match.params.propertySlug !== undefined ? (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <CalendarSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>
              ) : (
                <div className="col-md-2 left">
                  <div className="child-menu-section">
                    <aside>
                      <AllPropertyReservationSidebar
                        property_slug={this.state.property_slug}
                      />
                    </aside>
                  </div>
                </div>
              )}
              <div
                className={
                  this.props.match.params.propertySlug !== undefined
                    ? "col-md-10 right"
                    : "col-md-12"
                }
              >
                <div className="col-md-12 mg-t-20 mg-b-10"></div>
                <div className="col-md-12 mg-t-0 mg-b-10 pd-t-0 pd-b-10 pd-l-15 pd-r-0">
                  <div className="dashbaord-pageHeadertitle d-flex justify-content-between align-items-center flex-wrap mg-b-0 reservationheadermobile">
                    <h3>{this.getTitle()}</h3>
                    <div className="d-flex flex-wrap justify-content-end bothBtn align-items-center">
                      {global.userPermissions.checkPermission(
                        "reservations-delete"
                      ) && (
                        <span
                          onClick={() => {
                            this.opendeleteReservationModal(
                              this.state.reservation_slug
                            );
                          }}
                          className="deleteicon mg-r-20 hand-cursor"
                        >
                          <img src={deleteicon} alt="Delete" />
                        </span>
                      )}
                      {(this.state.slug !== undefined &&
                        this.state.email &&
                        global.userPermissions.checkPermission(
                          "reservations-update"
                        ) &&
                        this.state.keyCafeFlag === 1) ||
                      this.state.keyCafeFlag === 3 ? (
                        this.state.set_password ? (
                          <OverlayTrigger
                            placement="top"
                            trigger="hover"
                            overlay={
                              <Popover>
                                <span className="completeBookigHover">
                                  Mail sent at {this.state.set_password_at}
                                </span>
                              </Popover>
                            }
                          >
                            <button
                              type="button"
                              className=" m-0 btn btn btn-success btn-block"
                              onClick={() => this.SendGenerateEmail()}
                            >
                              {this.state.isSubmit
                                ? global.loader
                                : "Send Mail "}
                            </button>
                          </OverlayTrigger>
                        ) : (
                          <button
                            type="button"
                            className=" m-0 btn btn btn-success btn-block"
                            onClick={() => this.SendGenerateEmail()}
                          >
                            {this.state.isSubmit ? global.loader : "Send Mail "}
                          </button>
                        )
                      ) : null}
                      {/* {global.userPermissions.checkPermission(
                        "reservations-activity"
                      ) && (
                        <Link
                          to={
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                this.state.property_slug +
                                "/reservations-activity/" +
                                this.state.reservation_slug
                              : "/reservations-activity/" +
                                this.state.outside_property_slug +
                                "/" +
                                this.state.reservation_slug
                          }
                        >
                          <button
                            type="button"
                            className="btn-success-outline-small ml-3 editbtn"
                          >
                            Activity
                          </button>
                        </Link>
                      )} */}
                      {global.userPermissions.checkPermission(
                        "reservations-update"
                      ) && (
                        <Link
                          to={
                            this.props.match.params.propertySlug !== undefined
                              ? "/properties/view/" +
                                this.state.property_slug +
                                "/reservations/update/" +
                                this.state.reservation_slug
                              : "/reservations/update/" +
                                this.state.outside_property_slug +
                                "/" +
                                this.state.reservation_slug
                          }
                        >
                          <button
                            type="button"
                            className="btn-success-outline-small ml-3 editbtn"
                          >
                            Edit
                          </button>
                        </Link>
                      )}
                      {global.userPermissions.checkPermission(
                        "communication-message-module"
                      ) ? (
                        <button
                          type="button"
                          className="btn-success-outline-small ml-3 msgbtn"
                          onClick={() => this.postThread()}
                        >
                          {this.state.isMessage ? global.loader : "Message"}
                        </button>
                      ) : (
                        false
                      )}

                      <button
                        type="button"
                        className="btn-success-outline-small ml-3 msgbtn"
                        onClick={() => this.openActivity()}
                      >
                        Activity
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row m-0">
                  <div
                    className={
                      this.state.activity_box == true
                        ? "scrolling-carousel pd-l-15 pd-r-15"
                        : "scrolling-carousel reservation-full-section pd-l-15 pd-r-15"
                    }
                  >
                    <div className="dashboardRightcard background-none pt-0 pd-l-30 pd-r-30">
                      <div className="col-md-12 pd-lg-l-30 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15 pd-xs-l-15 pd-xs-r-0">
                        <Alerts
                          show={this.state.showAlertModal}
                          type={this.state.alertModalType}
                          title={this.state.alertModalTitle}
                          message={this.state.alertModalMessage}
                        />
                      </div>
                      <div className="col-12 pd-r-0 pd-l-45 status_reserve_box">
                        <div className="row main-reservation-info reservation_satatus_box">
                          <div className="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                            <div className="status_list">
                              <p className="az-profile-name-text pl-0">Name</p>
                              <p className="media-body">
                                {this.state.first_name
                                  ? this.state.first_name
                                  : ""}{" "}
                                {this.state.last_name
                                  ? this.state.last_name
                                  : ""}
                                <p className="font-weight-normal">
                                  {this.state.number_of_guest
                                    ? this.state.number_of_guest > 1
                                      ? this.state.number_of_guest + " Guests"
                                      : this.state.number_of_guest + " Guest"
                                    : ""}
                                </p>
                              </p>
                            </div>
                          </div>

                          <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                            <div className="status_list">
                              <p className="az-profile-name-text pl-0">
                                Listing Name (Unit) (Floor Plan)
                              </p>
                              <p className="media-body ">
                                {this.state.listing_name
                                  ? this.state.listing_name +
                                    (this.state.unit_name
                                      ? " (" + this.state.unit_name + ")"
                                      : this.state.unit_number) +
                                    (this.state.floor_plan_name
                                      ? " (" + this.state.floor_plan_name + ")"
                                      : "")
                                  : ""}
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                            <div className="status_list">
                              <p className="az-profile-name-text pl-0">
                                Check-In
                              </p>
                              <p className="media-body">
                                {this.state.checkin_date_only
                                  ? this.state.checkin_date_only
                                  : ""}
                                <p>
                                  {" "}
                                  {this.state.checkin_time_only
                                    ? this.state.checkin_time_only
                                    : ""}
                                </p>
                              </p>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                            <div className="status_list">
                              <p className="az-profile-name-text pl-0">
                                Check-Out
                              </p>
                              <p className="media-body ">
                                {this.state.checkout_date_only
                                  ? this.state.checkout_date_only
                                  : ""}
                                <p>
                                  {this.state.checkout_time_only
                                    ? this.state.checkout_time_only
                                    : ""}
                                </p>
                              </p>
                            </div>
                          </div>

                          {global.userPermissions.checkPermission(
                            "guests-paid-view"
                          ) && (
                            <div className="col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2">
                              <div className="status_list">
                                <p className="az-profile-name-text pl-0">
                                  Guest Paid
                                </p>
                                <p className="media-body">
                                  {this.state.guest_paid
                                    ? "$" +
                                      global.onKeyPressEvent.numberWithCommasForDecimal(
                                        this.state.guest_paid.replace(
                                          /\.00$/,
                                          ""
                                        )
                                      )
                                    : ""}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        {this.state.lock_type === 1 ||
                        this.state.lock_type === 3 ? (
                          <div className="row main-reservation-info reservation_satatus_box">
                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <div className="status_list">
                                <p className="az-profile-name-text pl-0">
                                  Guest Email
                                </p>
                                <p className="media-body text-break">
                                  {this.state.email
                                    ? this.state.email
                                    : "Guest email is missing"}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <div className="status_list">
                                <p className="az-profile-name-text pl-0">
                                  Guest Access Code
                                </p>
                                <p className="media-body">
                                  {this.state.booking_code
                                    ? this.state.booking_code
                                    : ""}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <div className="status_list">
                                <p className="az-profile-name-text pl-0">
                                  Cleaner Access Code
                                </p>
                                <p className="media-body">
                                  {this.state.booking_code1
                                    ? this.state.booking_code1
                                    : ""}
                                </p>
                              </div>
                            </div>

                            {this.state.lock_type === 1 && stats === false ? (
                              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="status_list">
                                  <p className="az-profile-name-text pl-0">
                                    Key Status
                                  </p>
                                  <p className="media-body">
                                    {(this.state.ref_table_id == 1 ||
                                      this.state.ref_table_id == 2 ||
                                      this.state.ref_table_id == 3 ||
                                      this.state.ref_table_id == 4 ||
                                      this.state.ref_table_id == 0) &&
                                    this.state.current_key_status === "DROPOFF"
                                      ? "Key in Locker"
                                      : this.state.ref_table_id == 1 &&
                                        this.state.current_key_status !=
                                          undefined &&
                                        this.state.current_key_status != ""
                                      ? "Key with Guest"
                                      : this.state.ref_table_id == 2 &&
                                        this.state.current_key_status !=
                                          undefined &&
                                        this.state.current_key_status != ""
                                      ? "Key with Cleaner"
                                      : this.state.ref_table_id == 3 &&
                                        this.state.current_key_status !=
                                          undefined &&
                                        this.state.current_key_status != ""
                                      ? "Key with Resident"
                                      : this.state.ref_table_id == 4 &&
                                        this.state.current_key_status !=
                                          undefined &&
                                        this.state.current_key_status != ""
                                      ? "Key with Vistor"
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.lock_type === 3 ? (
                              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="status_list">
                                  <p className="az-profile-name-text pl-0">
                                    Lock Status
                                  </p>
                                  <p className="media-body">
                                    {this.state.lock_status
                                      ? this.state.lock_status
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.lock_type === 1 && stats === false ? (
                              <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                                <div className="status_list">
                                  <p className="az-profile-name-text pl-0">
                                    Status Time
                                  </p>
                                  <p className="media-body">
                                    {this.state.key_status_updated_time
                                      ? this.state.key_status_updated_time
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <p
                                className="pl-0 hand-cursor blue-link-cust"
                                onClick={this.openInstructionPopup}
                              >
                                Pick Up/Drop Off Instructions
                              </p>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <p
                                className="pl-0 hand-cursor blue-link-cust"
                                onClick={this.openArrivalInstructionPopup}
                              >
                                Arrival Instructions
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.lock_type === 2 ? (
                          <div className="row main-reservation-info reservation_satatus_box">
                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <div className="status_list">
                                <p className="az-profile-name-text pl-0">
                                  Guest Email
                                </p>
                                <p className="media-body text-break">
                                  {this.state.email
                                    ? this.state.email
                                    : "Guest email is missing"}
                                </p>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <div className="status_list">
                                <p className="az-profile-name-text pl-0">
                                  Guest Access Code
                                </p>
                                <p className="media-body">
                                  {this.state.reservationAccessStatus
                                    ? this.state.reservationAccessStatus
                                    : "Create manually in Latch"}
                                </p>
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3">
                              <div className="status_list">
                                <p className="az-profile-name-text pl-0">
                                  Cleaner Access Code
                                </p>
                                <p className="media-body">
                                  {this.state.reservationAccessCleanerStatus
                                    ? this.state.reservationAccessCleanerStatus
                                    : "Create manually in Latch"}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                            <h3 className="mg-l-0 background-head d-flex">
                              Activity
                              <span className="rightArrow">
                              <a href="#" onClick={this.activityStream}>
                                <i className="fas fa-chevron-right"></i>
                                </a>
                              </span>{" "}
                              <span className="d-flex align-items-center flex-place-self resr_activity_tgl hand-cursor">
                              <div
                                className={
                                  this.state.toggleValue
                                    ? "az-toggle on"
                                    : "az-toggle"
                                }
                                onClick={(e) => this.activityStream(e)}
                              >
                                <span></span>
                              </div>
                              <label className="d-inline-block m-0 pl-1">
                                {this.state.toggleValue ? "On" : "Off"}
                              </label>
                            </span>{" "}
                            </h3>
                        </div> */}
                      {/* {this.state.toggleValue ? (
                          <ReservationActivity
                            reservation_slug={this.state.reservation_slug}
                            property_slug={this.state.property_slug}
                            outside_property_slug={this.state.outside_property_slug}
                          />
                        ) : (
                          <> */}
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                        <h3 className="mg-l-0 background-head">Notes</h3>
                      </div>
                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-sm-l-30 pd-r-0 pd-xs-l-15">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <p className="az-profile-name-text pl-0">
                            Reservation Notes
                          </p>
                          <p className="media-body">
                            {this.state.internal_notes ? (
                              <p className="p_wrap1">
                                {this.state.descShowInternalNotes
                                  ? this.state.internal_notes
                                  : this.state.internal_notes.slice(
                                      0,
                                      global.showMoreLength
                                    )}

                                {this.state.internal_notes.length >
                                  global.showMoreLength && (
                                  <span onClick={this.fullTextInternalNotes}>
                                    {this.state.descShowInternalNotes ? (
                                      <p className="hand-cursor showmore">
                                        Show Less{" "}
                                      </p>
                                    ) : (
                                      <p className="hand-cursor showmore">
                                        ...Show more{" "}
                                      </p>
                                    )}
                                  </span>
                                )}
                              </p>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                          <p className="az-profile-name-text pl-0">
                            Include In Owner Report
                          </p>
                          <p className="media-body">
                            {this.state.include_owner_report ? (
                              <p className="p_wrap1">
                                {this.state.descShowIncludeInOwnerReport
                                  ? this.state.include_owner_report
                                  : this.state.include_owner_report.slice(
                                      0,
                                      global.showMoreLength
                                    )}

                                {this.state.include_owner_report.length >
                                  global.showMoreLength && (
                                  <span
                                    onClick={this.fullTextIncludeInOwnerReport}
                                  >
                                    {this.state.descShowIncludeInOwnerReport ? (
                                      <p className="hand-cursor showmore">
                                        Show Less{" "}
                                      </p>
                                    ) : (
                                      <p className="hand-cursor showmore">
                                        ...Show more{" "}
                                      </p>
                                    )}
                                  </span>
                                )}
                              </p>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                        <h3 className="mg-l-0 background-head">Basic</h3>
                      </div>
                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-sm-l-30 pd-r-0 pd-xs-l-15">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            First Name
                          </p>
                          <p className="media-body">
                            {this.state.first_name ? this.state.first_name : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Last Name</p>
                          <p className="media-body">
                            {this.state.last_name ? this.state.last_name : ""}
                          </p>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Listing Name (Unit)
                          </p>
                          <p className="media-body">
                            {this.state.listing_name ? (
                              <a
                                className="link-url"
                                href={
                                  this.props.match.params
                                    .outsidePropertySlug !== undefined
                                    ? "/properties/view/" +
                                      this.state.property_slug +
                                      "/listing/" +
                                      this.state.listing_slug +
                                      "/info"
                                    : "/listing/view/" +
                                      this.state.property_slug +
                                      "/" +
                                      this.state.listing_slug +
                                      "/info"
                                }
                              >
                                {this.state.listing_name}
                              </a>
                            ) : (
                              ""
                            )}
                            {this.state.unit_name ? " (" : ""}

                            {this.state.unit_name &&
                            this.state.property_slug ? (
                              <a
                                className="link-url"
                                href={
                                  "/properties/view/" +
                                  this.state.property_slug +
                                  "/units/" +
                                  this.state.unit_slug +
                                  "/unit-basic"
                                }
                              >
                                {this.state.unit_name}
                              </a>
                            ) : (
                              ""
                            )}
                            {this.state.unit_name ? ")" : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Floor Plan
                          </p>
                          <p className="media-body ">
                            {this.state.floor_plan_name &&
                            this.state.property_slug ? (
                              <a
                                className="link-url"
                                href={
                                  "/properties/view/" +
                                  this.state.property_slug +
                                  "/floor-plans/view/" +
                                  this.state.floor_plan_slug
                                }
                              >
                                {this.state.floor_plan_name}
                              </a>
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Check-In</p>
                          <p className="media-body">
                            {this.state.checkin_date
                              ? this.state.checkin_date
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Check-Out</p>
                          <p className="media-body">
                            {this.state.checkout_date
                              ? this.state.checkout_date
                              : ""}
                          </p>
                        </div>

                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Confirmation Code
                          </p>
                          <p className="media-body">
                            {this.state.confirmation_code
                              ? this.state.confirmation_code
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Adults</p>
                          <p className="media-body">
                            {this.state.adults ? this.state.adults : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Children</p>
                          <p className="media-body">
                            {this.state.children ? this.state.children : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Infants</p>
                          <p className="media-body">
                            {this.state.infants ? this.state.infants : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Pets</p>
                          <p className="media-body">
                            {this.state.pets ? this.state.pets : ""}
                          </p>
                        </div>
                        {global.userPermissions.checkPermission(
                          "guests-personal-data-view"
                        ) && (
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">Channel</p>
                            <p className="media-body">
                              {this.state.set_channel}
                            </p>
                          </div>
                        )}
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Status</p>
                          <p className="media-body">{this.state.status_id}</p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Number of Guests
                          </p>
                          <p className="media-body">
                            {this.state.number_of_guest
                              ? this.state.number_of_guest
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Booked</p>
                          <p className="media-body">
                            {this.state.booked ? this.state.booked : ""}
                          </p>
                        </div>
                      </div>
                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-r-0 pd-sm-l-30 pd-xs-l-15">
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Wi-Fi Name
                          </p>
                          <p className="media-body">
                            {this.state.wi_fi_name ? this.state.wi_fi_name : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Wi-Fi Password
                          </p>
                          <p className="media-body">
                            {this.state.wi_fi_password
                              ? this.state.wi_fi_password
                              : ""}
                          </p>
                        </div>
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">
                            Parking Space
                          </p>
                          <p className="media-body">
                            {this.state.parking_slot
                              ? this.state.parking_slot
                              : ""}
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                        <h3 className="mg-l-0 background-head">
                          Guest Info.
                          {global.userPermissions.checkPermission(
                            "reservations-guest-photos"
                          ) &&
                            this.state.is_form_submited === 1 &&
                            this.state.guest_info_deleted === 0 && (
                              <span
                                onClick={(e) => this.showGuestImages(e)}
                                className="ml-2"
                              >
                                <img src={cart} alt="contact_image" />
                              </span>
                            )}
                        </h3>
                      </div>

                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-sm-l-30 pd-r-0 pd-xs-l-15">
                        {global.userPermissions.checkPermission(
                          "guests-personal-data-view"
                        ) && (
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">Email</p>
                            <p className="media-body">
                              {this.state.email ? this.state.email : ""}
                            </p>
                          </div>
                        )}
                        {global.userPermissions.checkPermission(
                          "guests-personal-data-view"
                        ) && (
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">Phone</p>
                            <p className="media-body">
                              {this.state.phone_number
                                ? this.state.phone_number
                                : ""}
                            </p>
                          </div>
                        )}
                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                          <p className="az-profile-name-text pl-0">Passport</p>
                          <p className="media-body">
                            {this.state.passport ? this.state.passport : ""}
                          </p>
                        </div>
                      </div>

                      {global.userPermissions.checkPermission(
                        "guests-personal-data-view"
                      ) && (
                        <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-sm-l-30 pd-r-0 pd-xs-l-15">
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">
                              Apt. no./House no.
                            </p>
                            <p className="media-body">
                              {this.state.street_1 ? this.state.street_1 : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">Street </p>
                            <p className="media-body">
                              {this.state.street_2 ? this.state.street_2 : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">City</p>
                            <p className="media-body">
                              {this.state.city_name ? this.state.city_name : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">
                              Zip Code
                            </p>
                            <p className="media-body">
                              {this.state.zip_code ? this.state.zip_code : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">County</p>
                            <p className="media-body">
                              {this.state.county_name
                                ? this.state.county_name
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">State</p>
                            <p className="media-body">
                              {this.state.state_name
                                ? this.state.state_name
                                : ""}
                            </p>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">Country</p>
                            <p className="media-body">
                              {this.state.country_name
                                ? this.state.country_name
                                : ""}
                            </p>
                          </div>{" "}
                          <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                            <p className="az-profile-name-text pl-0">
                              Last Login
                            </p>
                            <p className="media-body">
                              {this.state.last_login
                                ? this.state.last_login
                                : ""}
                            </p>
                          </div>
                        </div>
                      )}

                      {this.state.slug !== undefined ? (
                        <>
                          <div className="col-lg-12 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                            <h3 className="mg-l-0 mg-t-15 background-head">
                              Bypass
                            </h3>
                          </div>
                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-r-0 pd-sm-l-30 pd-xs-l-15">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Bypass Guest Form
                              </p>
                              <p className="media-body">
                                {this.state.bypass_guest_form === true
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Bypass Security Deposit
                              </p>
                              <p className="media-body">
                                {this.state.bypass_card_token === true
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Bypass Parking Payment
                              </p>
                              <p className="media-body">
                                {this.state.bypass_parking_payment === true
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Bypass Email Verification
                              </p>
                              <p className="media-body">
                                {this.state.bypass_email_verification == true
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Keep Guest Info
                              </p>
                              <p className="media-body">
                                {this.state.guest_info_keep == true
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {global.userPermissions.checkPermission(
                        "guests-pricing-data-view"
                      ) && (
                        <>
                          <div className="col-lg-12 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                            <h3 className="mg-l-0 mg-t-15 background-head">
                              Price
                            </h3>
                          </div>
                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-r-0 pd-sm-l-30 pd-xs-l-15">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Nightly Rate
                              </p>
                              <p className="media-body">
                                {this.state.nightly_rate
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.nightly_rate.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                {" "}
                                Total Nights
                              </p>
                              <p className="media-body">
                                {this.state.total_stay_nights
                                  ? this.state.total_stay_nights
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Total Nightly Rate
                              </p>
                              <p className="media-body">
                                {this.state.total_nightly_rate
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.total_nightly_rate.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Cleaning Fee
                              </p>
                              <p className="media-body">
                                {this.state.cleaning_fee
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.cleaning_fee.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                General Fee
                              </p>
                              <p className="media-body">
                                {this.state.pet_fee
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.pet_fee.replace(/\.00$/, "")
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Pet Fee
                              </p>
                              <p className="media-body">
                                {this.state.pet_policy_fee
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.pet_policy_fee.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Resolution Fee
                              </p>
                              <p className="media-body">
                                {this.state.total_resolution_fee ? (
                                  <NumberFormat
                                    value={parseFloat(
                                      this.state.total_resolution_fee
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                      .replace(
                                        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                        ""
                                      )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                            {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  Convenience Fee
                                </p>
                                <p className="media-body">
                                  {this.state.convenience_fee
                                    ? "$" +
                                      global.onKeyPressEvent.numberWithCommasForDecimal(
                                        this.state.convenience_fee.replace(
                                          /\.00$/,
                                          ""
                                        )
                                      )
                                    : ""}
                                </p>
                              </div> */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Occupancy Taxes
                              </p>
                              <p className="media-body">
                                {this.state.occupancy_tax_fee
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.occupancy_tax_fee.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Security Deposit
                              </p>
                              <p className="media-body">
                                {this.state.security_deposite
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.security_deposite.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Guest Service Fee
                              </p>
                              <p className="media-body">
                                {this.state.service_fee
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.service_fee.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Channel Fee
                                {/* <span className="reservation-optional">
                                    (Total Nightly Rate + Cleaning Fee * 3%){" "}
                                    {this.state.host_service_fee ? (
                                      <>
                                        {"= ("}
                                        {this.state.total_nightly_rate
                                          ? "$" + this.state.total_nightly_rate
                                          : ""}{" "}
                                        {this.state.cleaning_fee
                                          ? "+ $" + this.state.cleaning_fee
                                          : ""}{" "}
                                        {") * 3%"}{" "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {" = "}
                                  </span> */}
                              </p>
                              <p className="media-body">
                                {this.state.host_service_fee
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.host_service_fee.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Payment Processing Fee
                              </p>
                              <p className="media-body">
                                {this.state.payment_processing_fee
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.payment_processing_fee.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Guest Paid
                                <span className="reservation-optional">
                                  (Total Nightly Rate + Cleaning Fee + Guest
                                  Service Fee + Occupancy Taxes){" "}
                                  {this.state.guest_paid ? (
                                    <>
                                      {" "}
                                      {this.state.total_nightly_rate
                                        ? "=  $" +
                                          this.state.total_nightly_rate +
                                          " + "
                                        : ""}{" "}
                                      {this.state.cleaning_fee
                                        ? "$" + this.state.cleaning_fee + " + "
                                        : ""}{" "}
                                      {this.state.service_fee
                                        ? "$" + this.state.service_fee + " + "
                                        : ""}{" "}
                                      {this.state.occupancy_tax_fee
                                        ? "$" + this.state.occupancy_tax_fee
                                        : ""}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {" = "}
                                </span>
                              </p>
                              <p className="media-body">
                                {" "}
                                {this.state.guest_paid
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.guest_paid.replace(/\.00$/, "")
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                OTA payout to Orion Haus
                                <span className="reservation-optional">
                                  {this.state.set_channel_name ===
                                  "Direct Booking"
                                    ? "(Guest Paid - Guest Service Fee - Occupancy Taxes - Channel Fee - Payment Processing Fee)"
                                    : "(Guest Paid - Guest Service Fee - Occupancy Taxes - Channel Fee)"}
                                  {this.state.host_payout ? (
                                    <>
                                      {" = "}
                                      {this.state.set_channel_name ===
                                      "Direct Booking" ? (
                                        <>
                                          {this.state.guest_paid
                                            ? "$" + this.state.guest_paid
                                            : ""}{" "}
                                          {this.state.service_fee
                                            ? "- $" + this.state.service_fee
                                            : ""}{" "}
                                          {this.state.occupancy_tax_fee
                                            ? "- $" +
                                              this.state.occupancy_tax_fee
                                            : ""}{" "}
                                          {this.state.host_service_fee
                                            ? "- $" +
                                              this.state.host_service_fee
                                            : ""}
                                          {this.state.payment_processing_fee
                                            ? "- $" +
                                              this.state.payment_processing_fee
                                            : ""}
                                        </>
                                      ) : (
                                        <>
                                          {this.state.guest_paid
                                            ? "$" + this.state.guest_paid
                                            : ""}{" "}
                                          {this.state.service_fee
                                            ? "- $" + this.state.service_fee
                                            : ""}{" "}
                                          {this.state.occupancy_tax_fee
                                            ? "- $" +
                                              this.state.occupancy_tax_fee
                                            : ""}{" "}
                                          {this.state.host_service_fee
                                            ? "- $" +
                                              this.state.host_service_fee
                                            : ""}{" "}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {" = "}
                                </span>
                              </p>
                              <p className="media-body">
                                {" "}
                                {this.state.host_payout
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.host_payout.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </>
                      )}

                      {global.userPermissions.checkPermission(
                        "guests-pricing-data-view"
                      ) && (
                        <>
                          {this.state.costListTotal > 0 ? (
                            <React.Fragment>
                              <div className="col-lg-12 pd-lg-l-30 pd-lg-r-15  pd-xs-r-0 pd-xs-l-15">
                                <h3 className="mg-l-0 mg-t-15 background-head">
                                  General Fee
                                </h3>
                              </div>
                              <div className="row mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-45 pd-xs-l-30 pd-sm-l-45 pd-sm-r-15 pd-lg-r-15 pd-xs-r-0 mg-r-0">
                                <div className="table-responsive">
                                  <Table hover className="mg-b-0">
                                    <thead>
                                      <tr>
                                        <th className="w-20">Fee Type</th>
                                        <th className="w-20">Payable By</th>
                                        <th className="w-20">Payable At</th>

                                        <th className="w-20">Refundable</th>
                                        <th className="w-20">Estimated</th>
                                        <th className="w-20 text-left">
                                          Mandatory
                                        </th>
                                        <th className="w-20">Fee</th>
                                        <th className="w-20">Amount</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.costListTotal > 0 ? (
                                        <>
                                          {" "}
                                          {this.state.costList.map(
                                            (option, i) => {
                                              return (
                                                <tr key={i}>
                                                  <td>
                                                    {option.cost_type
                                                      ? option.cost_type
                                                          .cost_type_name
                                                      : ""}
                                                  </td>
                                                  <td>
                                                    {option.cost_payable_by
                                                      ? option.cost_payable_by
                                                          .cost_payable_by_name
                                                      : ""}
                                                  </td>
                                                  <td>
                                                    {option.cost_payable_at
                                                      ? option.cost_payable_at
                                                          .cost_payable_at_name
                                                      : ""}
                                                  </td>

                                                  <td>
                                                    {option.refundable === 1
                                                      ? "Yes"
                                                      : "No"}
                                                  </td>
                                                  <td>
                                                    {option.estimated === 1
                                                      ? "Yes"
                                                      : "No"}
                                                  </td>
                                                  <td className="text-left">
                                                    {option.mandatory === 1
                                                      ? "Yes"
                                                      : "No"}
                                                  </td>
                                                  <td>
                                                    {option.cost_name
                                                      ? "$" +
                                                        global.onKeyPressEvent.numberWithCommasForDecimal(
                                                          option.cost_name.replace(
                                                            /\.00$/,
                                                            ""
                                                          )
                                                        )
                                                      : ""}
                                                  </td>
                                                  <td>
                                                    {option.cost_name
                                                      ? option.cost_payable_at
                                                        ? option.cost_payable_at
                                                            .cost_payable_at_name ===
                                                          "Daily"
                                                          ? "$" +
                                                            global.onKeyPressEvent.numberWithCommasForDecimal(
                                                              (
                                                                parseFloat(
                                                                  option.cost_name
                                                                ) *
                                                                parseInt(
                                                                  this.state
                                                                    .total_stay_nights
                                                                )
                                                              )
                                                                .toFixed(2)
                                                                .replace(
                                                                  /\.00$/,
                                                                  ""
                                                                )
                                                            )
                                                          : "$" +
                                                            global.onKeyPressEvent.numberWithCommasForDecimal(
                                                              option.cost_name.replace(
                                                                /\.00$/,
                                                                ""
                                                              )
                                                            )
                                                        : ""
                                                      : ""}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )}{" "}
                                          <tr>
                                            <td>Total General Fee</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>

                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                              {this.state.pet_fee
                                                ? "$" +
                                                  global.onKeyPressEvent.numberWithCommasForDecimal(
                                                    parseFloat(
                                                      this.state.pet_fee
                                                    )
                                                      .toFixed(2)
                                                      .replace(/\.00$/, "")
                                                  )
                                                : ""}
                                            </td>
                                          </tr>
                                        </>
                                      ) : (
                                        <tr>
                                          <td colSpan="9" align="center">
                                            There are no general fees added in
                                            the system.
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </>
                      )}

                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                        <h3 className="mg-l-0 mg-t-15 background-head">
                          Pet Fees
                        </h3>
                      </div>
                      <div className="row mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-45 pd-xs-l-30 pd-sm-l-45 pd-sm-r-15  pd-lg-r-15 pd-xs-r-0 mg-r-0">
                        {this.state.property_pet_allowed === 1 &&
                        this.state.listing_pet_allowed === 1 ? (
                          <div className="table-responsive">
                            <Table hover className="mg-b-0">
                              <thead>
                                <tr>
                                  <th className="w-20">Pet Type</th>
                                  <th className="w-20">Max # Allowed</th>
                                  <th className="w-20 text-left">
                                    Max # Weight
                                  </th>
                                  <th className="w-20">Pet Count</th>
                                  <th className="w-20">One-Time Fees</th>

                                  <th className="w-20 text-left">Pet Weight</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.petFeesDataTotal > 0 ? (
                                  this.state.petFeesData.map((option, i) => (
                                    <tr key={i}>
                                      <td>
                                        {option.pet_type
                                          ? option.pet_type.pet_type_name
                                          : ""}
                                      </td>
                                      <td>
                                        {option.allowed_pet_number
                                          ? option.allowed_pet_number
                                          : ""}
                                      </td>
                                      <td>
                                        {option.pet_count
                                          ? option.pet_count
                                          : ""}
                                      </td>
                                      <td className="text-left">
                                        {option.weight_per_type
                                          ? parseFloat(option.weight_per_type)
                                              .toFixed(2)
                                              .replace(/\.00$/, "")
                                              .replace(
                                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                ""
                                              ) + " lbs"
                                          : ""}
                                      </td>
                                      <td>
                                        {option.one_time_fee_per_type ? (
                                          <NumberFormat
                                            value={parseFloat(
                                              option.one_time_fee_per_type
                                            )
                                              .toFixed(2)
                                              .replace(/\.00$/, "")
                                              .replace(
                                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                ""
                                              )}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            prefix={"$"}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </td>

                                      <td className="text-left">
                                        {option.pet_weight
                                          ? parseFloat(option.pet_weight)
                                              .toFixed(2)
                                              .replace(/\.00$/, "")
                                              .replace(
                                                /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                ""
                                              ) + " lbs"
                                          : ""}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="9" align="center">
                                      There are no pet fees added in the system.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        ) : (
                          <p className="pets_no_text text-left font-italic pd-xs-l-15 pd-lg-l-0 pd-lg-b-20">
                            Since the listing is not pet friendly, you cannot
                            select pets in the listing.
                          </p>
                        )}
                      </div>

                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                        <h3 className="mg-l-0 mg-t-15 background-head">
                          Occupancy Tax Distribution
                        </h3>
                      </div>
                      <div className="row mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-45 pd-xs-l-30 pd-sm-l-45 pd-sm-r-15 pd-lg-r-15 pd-xs-r-0 mg-r-0">
                        <div className="table-responsive">
                          <Table hover className="mg-b-0">
                            <thead>
                              <tr>
                                <th className="w-30">Name</th>
                                <th className="w-30">Tax Distribution</th>
                                <th className="w-30">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.taxListTotal > 0 ||
                              this.state.taxOccupancyListTotal > 0 ? (
                                <>
                                  {this.state.taxOccupancyListTotal > 0
                                    ? this.state.taxOccupancyList.map(
                                        (option, i) => {
                                          return (
                                            <tr key={i}>
                                              <td>
                                                {option.name ? option.name : ""}
                                              </td>
                                              <td>
                                                {option.tax_distribution
                                                  ? option.tax_type_id === 1
                                                    ? "$" +
                                                      global.onKeyPressEvent.numberWithCommasForDecimal(
                                                        option.tax_distribution.replace(
                                                          /\.00$/,
                                                          ""
                                                        )
                                                      )
                                                    : option.tax_distribution.replace(
                                                        /\.00$/,
                                                        ""
                                                      ) + "%"
                                                  : ""}
                                              </td>
                                              <td>
                                                {option.amount
                                                  ? "$" +
                                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                                      option.amount.replace(
                                                        /\.00$/,
                                                        ""
                                                      )
                                                    )
                                                  : ""}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    : this.state.taxList.map((option, i) => {
                                        var aa = option.tax_type_name
                                          ? option.tax_type_name
                                          : option.tax_type
                                          ? option.tax_type.tax_type_name
                                          : "";
                                        return (
                                          <tr key={i}>
                                            <td>
                                              {option.property_level_tax_name
                                                ? option.property_level_tax_name
                                                : ""}
                                            </td>
                                            <td>
                                              {option.property_amount
                                                ? aa.includes("$")
                                                  ? "$" +
                                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                                      parseFloat(
                                                        option.property_amount
                                                      )
                                                        .toFixed(2)
                                                        .replace(/\.00$/, "")
                                                        .replace(
                                                          /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                          ""
                                                        )
                                                    )
                                                  : parseFloat(
                                                      option.property_amount
                                                    )
                                                      .toFixed(2)
                                                      .replace(/\.00$/, "")
                                                      .replace(
                                                        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                        ""
                                                      ) + "%"
                                                : ""}
                                            </td>
                                            <td>
                                              {(aa.includes("$")
                                                ? "$" + option.property_amount
                                                : "$" +
                                                  (
                                                    ((parseFloat(
                                                      this.state
                                                        .total_nightly_rate
                                                        ? this.state
                                                            .total_nightly_rate
                                                        : 0
                                                    ) +
                                                      parseFloat(
                                                        this.state.cleaning_fee
                                                          ? this.state
                                                              .cleaning_fee
                                                          : 0
                                                      )) *
                                                      parseFloat(
                                                        option.property_amount
                                                      )) /
                                                    100
                                                  ).toFixed(2)
                                              ).replace(/\.00$/, "")}
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  <tr>
                                    <td>Total Occupancy Taxes</td>
                                    <td></td>
                                    <td>
                                      {this.state.occupancy_tax_fee
                                        ? "$" +
                                          global.onKeyPressEvent.numberWithCommasForDecimal(
                                            parseFloat(
                                              this.state.occupancy_tax_fee
                                            )
                                              .toFixed(2)
                                              .replace(/\.00$/, "")
                                          )
                                        : ""}
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <tr>
                                  <td colSpan="9" align="center">
                                    There are no occupancy taxes added in the
                                    system.
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>

                      {global.userPermissions.checkPermission(
                        "guests-pricing-data-view"
                      ) && (
                        <>
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                            <h3 className="mg-l-0 mg-t-15 background-head">
                              Reservation Earnings
                            </h3>
                          </div>
                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-sm-l-30 pd-r-0 mg-r-0">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Guest Paid
                                <span className="reservation-optional">
                                  (Total Nightly Rate + Cleaning Fee + Guest
                                  Service Fee + Occupancy Taxes){" "}
                                  {this.state.guest_paid ? (
                                    <>
                                      {" "}
                                      {this.state.total_nightly_rate
                                        ? "=  $" +
                                          this.state.total_nightly_rate +
                                          " + "
                                        : ""}{" "}
                                      {this.state.cleaning_fee
                                        ? "$" + this.state.cleaning_fee + " + "
                                        : ""}{" "}
                                      {this.state.service_fee
                                        ? "$" + this.state.service_fee + " + "
                                        : ""}{" "}
                                      {this.state.occupancy_tax_fee
                                        ? "$" + this.state.occupancy_tax_fee
                                        : ""}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {" = "}
                                </span>
                              </p>
                              <p className="media-body">
                                {this.state.guest_paid
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.guest_paid.replace(/\.00$/, "")
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Resident Earnings{" "}
                                <span className="reservation-optional">
                                  {this.state.resident_earning ? (
                                    <>
                                      {" "}
                                      (
                                      {this.state
                                        .total_nightly_rate_to_resident &&
                                      this.state.total_nightly_rate
                                        ? "Total Nightly Rate *" +
                                          this.state
                                            .total_nightly_rate_to_resident +
                                          "%"
                                        : ""}
                                      {this.state.cleaning_fee_to_resident &&
                                      this.state.cleaning_fee
                                        ? "+ Cleaning Fee *" +
                                          this.state.cleaning_fee_to_resident +
                                          "%"
                                        : ""}
                                      {this.state.channel_fee_to_resident &&
                                      this.state.host_service_fee
                                        ? " - Channel Fee *" +
                                          this.state.channel_fee_to_resident +
                                          "%"
                                        : ""}
                                      )
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.resident_earning ? (
                                    <>
                                      {" = "}
                                      {this.state
                                        .total_nightly_rate_to_resident &&
                                      this.state.total_nightly_rate
                                        ? "($" +
                                          this.state.total_nightly_rate +
                                          "* " +
                                          this.state
                                            .total_nightly_rate_to_resident +
                                          "%)"
                                        : ""}
                                      {this.state.cleaning_fee_to_resident &&
                                      this.state.cleaning_fee
                                        ? " + ($" +
                                          this.state.cleaning_fee +
                                          "* " +
                                          this.state.cleaning_fee_to_resident +
                                          "%)"
                                        : ""}
                                      {this.state.channel_fee_to_resident &&
                                      this.state.host_service_fee
                                        ? " - ($" +
                                          this.state.host_service_fee +
                                          "* " +
                                          this.state.channel_fee_to_resident +
                                          "%)"
                                        : ""}
                                      {" = "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </p>
                              <p className="media-body">
                                {this.state.resident_earning
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.resident_earning.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                OH Earnings{" "}
                                {this.state.set_channel_name ===
                                "Direct Booking" ? (
                                  <span className="reservation-optional">
                                    {this.state.total_oh_earning ? (
                                      <>
                                        {" "}
                                        (
                                        {this.state.total_nightly_rate_to_oh &&
                                        this.state.total_nightly_rate
                                          ? "Total Nightly Rate *" +
                                            this.state
                                              .total_nightly_rate_to_oh +
                                            "%"
                                          : ""}
                                        {this.state.cleaning_fee_to_oh &&
                                        this.state.cleaning_fee
                                          ? " + Cleaning Fee *" +
                                            this.state.cleaning_fee_to_oh +
                                            "%"
                                          : ""}
                                        {this.state.channel_fee_to_oh &&
                                        this.state.host_service_fee
                                          ? " - Channel Fee *" +
                                            this.state.channel_fee_to_oh +
                                            "%"
                                          : ""}
                                        {this.state.occupancy_tax_fee
                                          ? " + Occupancy Taxes"
                                          : ""}
                                        {this.state.service_fee
                                          ? " + Guest Service Fee"
                                          : ""}
                                        {this.state.payment_processing_fee
                                          ? " - Payment Processing Fee"
                                          : ""}
                                        )
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.total_oh_earning ? (
                                      <>
                                        {" = "}
                                        {this.state.total_nightly_rate_to_oh &&
                                        this.state.total_nightly_rate
                                          ? "($" +
                                            this.state.total_nightly_rate +
                                            "* " +
                                            this.state
                                              .total_nightly_rate_to_oh +
                                            "%)"
                                          : ""}
                                        {this.state.cleaning_fee_to_oh &&
                                        this.state.cleaning_fee
                                          ? " + ($" +
                                            this.state.cleaning_fee +
                                            "* " +
                                            this.state.cleaning_fee_to_oh +
                                            "%)"
                                          : ""}
                                        {this.state.channel_fee_to_oh &&
                                        this.state.host_service_fee
                                          ? " - ($" +
                                            this.state.host_service_fee +
                                            "* " +
                                            this.state.channel_fee_to_oh +
                                            "%)"
                                          : ""}
                                        {this.state.occupancy_tax_fee
                                          ? " + ($" +
                                            this.state.occupancy_tax_fee +
                                            ")"
                                          : ""}
                                        {this.state.service_fee
                                          ? " + ($" +
                                            this.state.service_fee +
                                            ")"
                                          : ""}
                                        {this.state.payment_processing_fee
                                          ? " - ($" +
                                            this.state.payment_processing_fee +
                                            ")"
                                          : ""}
                                        {" = "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                ) : (
                                  <span className="reservation-optional">
                                    {this.state.total_oh_earning ? (
                                      <>
                                        {" "}
                                        (
                                        {this.state.total_nightly_rate_to_oh &&
                                        this.state.total_nightly_rate
                                          ? "Total Nightly Rate *" +
                                            this.state
                                              .total_nightly_rate_to_oh +
                                            "%"
                                          : ""}
                                        {this.state.cleaning_fee_to_oh &&
                                        this.state.cleaning_fee
                                          ? " + Cleaning Fee *" +
                                            this.state.cleaning_fee_to_oh +
                                            "%"
                                          : ""}
                                        {this.state.channel_fee_to_oh &&
                                        this.state.host_service_fee
                                          ? " - Channel Fee *" +
                                            this.state.channel_fee_to_oh +
                                            "%"
                                          : ""}
                                        )
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.total_oh_earning ? (
                                      <>
                                        {" = "}
                                        {this.state.total_nightly_rate_to_oh &&
                                        this.state.total_nightly_rate
                                          ? "($" +
                                            this.state.total_nightly_rate +
                                            "* " +
                                            this.state
                                              .total_nightly_rate_to_oh +
                                            "%)"
                                          : ""}
                                        {this.state.cleaning_fee_to_oh &&
                                        this.state.cleaning_fee
                                          ? " + ($" +
                                            this.state.cleaning_fee +
                                            "* " +
                                            this.state.cleaning_fee_to_oh +
                                            "%)"
                                          : ""}
                                        {this.state.channel_fee_to_oh &&
                                        this.state.host_service_fee
                                          ? " - ($" +
                                            this.state.host_service_fee +
                                            "* " +
                                            this.state.channel_fee_to_oh +
                                            "%)"
                                          : ""}

                                        {" = "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                )}
                              </p>
                              <p className="media-body">
                                {this.state.total_oh_earning
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.total_oh_earning.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Property Earnings{" "}
                                <span className="reservation-optional">
                                  {this.state.total_property_earning ? (
                                    <>
                                      {" "}
                                      (
                                      {this.state
                                        .total_nightly_rate_to_property &&
                                      this.state.total_nightly_rate
                                        ? "Total Nightly Rate *" +
                                          this.state
                                            .total_nightly_rate_to_property +
                                          "%"
                                        : ""}
                                      {this.state.cleaning_fee_to_property &&
                                      this.state.cleaning_fee
                                        ? " + Cleaning Fee *" +
                                          this.state.cleaning_fee_to_property +
                                          "%"
                                        : ""}
                                      {this.state.channel_fee_to_property &&
                                      this.state.host_service_fee
                                        ? " - Channel Fee *" +
                                          this.state.channel_fee_to_property +
                                          "%"
                                        : ""}
                                      )
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.total_property_earning ? (
                                    <>
                                      {" = "}
                                      {this.state
                                        .total_nightly_rate_to_property &&
                                      this.state.total_nightly_rate
                                        ? "($" +
                                          this.state.total_nightly_rate +
                                          "* " +
                                          this.state
                                            .total_nightly_rate_to_property +
                                          "%)"
                                        : ""}
                                      {this.state.cleaning_fee_to_property &&
                                      this.state.cleaning_fee
                                        ? " + ($" +
                                          this.state.cleaning_fee +
                                          "* " +
                                          this.state.cleaning_fee_to_property +
                                          "%)"
                                        : ""}
                                      {this.state.channel_fee_to_property &&
                                      this.state.host_service_fee
                                        ? " - ($" +
                                          this.state.host_service_fee +
                                          "* " +
                                          this.state.channel_fee_to_property +
                                          "%)"
                                        : ""}
                                      {" = "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </p>
                              <p className="media-body">
                                {this.state.total_property_earning
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.total_property_earning.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Channel Earning{" "}
                                <span className="reservation-optional">
                                  {this.state.set_channel_name ===
                                  "Direct Booking"
                                    ? "(Guest Paid - Payment Processing Fee)"
                                    : "(Guest Service Fee + Occupancy Taxes + Channel Fee)"}
                                  {this.state.airbnb_earning ? (
                                    <>
                                      {" "}
                                      {" = "}
                                      {this.state.set_channel_name ===
                                      "Direct Booking" ? (
                                        <>
                                          {this.state.guest_paid
                                            ? "$" + this.state.guest_paid
                                            : ""}
                                          {this.state.payment_processing_fee
                                            ? " - $" +
                                              this.state.payment_processing_fee
                                            : ""}
                                        </>
                                      ) : (
                                        <>
                                          {this.state.service_fee
                                            ? "$" + this.state.service_fee
                                            : ""}{" "}
                                          {this.state.occupancy_tax_fee
                                            ? " + $" +
                                              this.state.occupancy_tax_fee
                                            : ""}{" "}
                                          {this.state.host_service_fee
                                            ? " + $" +
                                              this.state.host_service_fee
                                            : ""}
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {" = "}
                                </span>
                              </p>
                              <p className="media-body">
                                {this.state.airbnb_earning
                                  ? "$" +
                                    global.onKeyPressEvent.numberWithCommasForDecimal(
                                      this.state.airbnb_earning.replace(
                                        /\.00$/,
                                        ""
                                      )
                                    )
                                  : ""}
                              </p>
                            </div>
                          </div>
                        </>
                      )}

                      {this.state.rating != undefined &&
                      this.state.rating.length > 0 ? (
                        <React.Fragment>
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                            <h3 className="mg-l-0 mg-t-15 background-head">
                              Guest Reviews
                            </h3>
                          </div>
                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30  pd-r-0 mg-r-0">
                            <div className="row col-md-6 border-bottom-0">
                              {this.state.rating.map((rat, i) => {
                                return (
                                  <>
                                    <div
                                      className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6"
                                      key={i}
                                    >
                                      <p className="az-profile-name-text pl-0 text-capitalize">
                                        {rat.labal_name}
                                      </p>
                                      <p className="media-body">
                                        <StarRatings
                                          rating={rat.labal_rating}
                                          starRatedColor="rgb(255,165,52)"
                                          numberOfStars={5}
                                          starDimension="25px"
                                        />
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                            <div className="col-md-6">
                              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                <p className="az-profile-name-text pl-0">
                                  {" "}
                                  Comment{" "}
                                </p>
                                <p className="media-body Comment-box">
                                  {this.state.review ? this.state.review : ""}
                                </p>
                              </div>
                              <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  {" "}
                                  Show on property website{" "}
                                </p>
                                <p className="media-body">
                                  {this.state.is_publish === 1 ? "Yes" : "No"}
                                </p>
                              </div>
                            </div>
                          </div>{" "}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {/* <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0">
                          <h3 className="mg-l-0 mg-t-15 background-head">Status</h3>

                        </div> */}

                      {/* <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15">
                          <h3 className="mg-l-0 mg-t-15 background-head">
                            Cleaning Schedule
                          </h3>
                        </div>
                        <div className="row mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-45 pd-xs-l-30  pd-lg-r-15 pd-xs-r-0 mg-r-0">
                          <div className="table-responsive">
                            <Table hover className="">
                              <thead>
                                <tr>
                                  <th className="w-30">Scheduled On</th>
                                  <th className="w-30">Started On</th>
                                  <th className="w-30">Completed On</th>
                                  <th className="w-30">Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.inspectionDataTotal > 0 ? (
                                  this.state.inspectionData.map((option, i) => {
                                    return (
                                      <tr key={i}>
                                        <td>
                                          {option.scheduled_on
                                            ? Moment(option.scheduled_on).format(
                                                global.dateTimeFormat
                                              )
                                            : ""}
                                        </td>
                                        <td>
                                          {option.started_on
                                            ? Moment(option.scheduled_on).format(
                                                global.dateTimeFormat
                                              )
                                            : ""}
                                        </td>
                                        <td>
                                          {option.completed_on
                                            ? Moment(option.completed_on).format(
                                                global.dateTimeFormat
                                              )
                                            : ""}
                                        </td>
                                        <td>
                                          {option.inspection_status === 1
                                            ? "Completed"
                                            : "Pending"}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr>
                                    <td colSpan="9" align="center">
                                      There are no cleaning scheduled for this
                                      reservation.
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div> */}

                      {/* Turn over BNB start */}
                      <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                        <div className="background-head pd-b-15 d-flex justify-content-between align-items-center flex-wrap mg-b-30">
                          <h3 className="m-0">Turnoverbnb Cleaning Schedule</h3>
                          {/* {global.userPermissions.checkPermission(
                              "turnoverbnb-mapping-create-project"
                            ) &&
                              this.state.isProjectCreated === false && (
                                <div className="d-flex justify-content-end">
                                  <button
                                    className="btn-success ml-3 button-width"
                                    type="button"
                                    disabled={this.state.isProjectCreated}
                                    onClick={this.createProjectTBNB}
                                  >
                                    Create Project
                                  </button>
                                </div>
                              )} */}
                        </div>
                      </div>
                      {this.state.tbnbProjectData ? (
                        <React.Fragment>
                          {this.state.tbnbProjectDetail ? (
                            <React.Fragment>
                              <div className="row mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-xs-l-30  pd-lg-r-15 pd-xs-r-0 mg-r-0">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                                  <p className="az-profile-name-text pl-0">
                                    Schedule Start
                                  </p>
                                  <p className="media-body">
                                    {this.state.tbnbProjectDetail.start_at
                                      ? Moment(
                                          this.state.tbnbProjectDetail.start_at
                                        ).format(global.dateTimeFormat)
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                                  <p className="az-profile-name-text pl-0">
                                    Schedule Completion
                                  </p>
                                  <p className="media-body">
                                    {this.state.tbnbProjectDetail.end_at
                                      ? Moment(
                                          this.state.tbnbProjectDetail.end_at
                                        ).format(global.dateTimeFormat)
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                                  <p className="az-profile-name-text pl-0">
                                    Status
                                  </p>
                                  <p className="media-body">
                                    {this.state.tbnbProjectAssign ? (
                                      <React.Fragment>
                                        {/* {this.state.tbnbProjectAssign.asign_completed_at
                                        ? "Completed"
                                        : this.state.tbnbProjectAssign.asign_started_at
                                        ? "Started"
                                        : this.state.tbnbProjectAssign.asign_created_at
                                        ? "Assigned"
                                        : "Pending"
                                      } */}
                                        {this.state.tbnbProjectAssign
                                          .asign_completed_at
                                          ? "Completed"
                                          : this.state.tbnbProjectAssign
                                              .asign_created_at
                                          ? "Assigned"
                                          : "Pending"}
                                      </React.Fragment>
                                    ) : (
                                      "Pending"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          {this.state.tbnbProjectAssign ? (
                            <React.Fragment>
                              <div className="row mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-xs-l-30  pd-lg-r-15 pd-xs-r-0 mg-r-0">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                                  <p className="az-profile-name-text pl-0">
                                    Actual Start
                                  </p>
                                  <p className="media-body">
                                    {this.state.tbnbProjectAssign
                                      .asign_started_at
                                      ? Moment(
                                          this.state.tbnbProjectAssign
                                            .asign_started_at
                                        ).format(global.dateTimeFormat)
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                                  <p className="az-profile-name-text pl-0">
                                    Actual Completion
                                  </p>
                                  <p className="media-body">
                                    {this.state.tbnbProjectAssign
                                      .asign_completed_at
                                      ? Moment(
                                          this.state.tbnbProjectAssign
                                            .asign_completed_at
                                        ).format(global.dateTimeFormat)
                                      : ""}
                                  </p>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                                  <p className="az-profile-name-text pl-0">
                                    Time Taken
                                  </p>
                                  <p className="media-body">
                                    {this.state.tbnbProjectAssign.time_taken
                                      ? this.state.tbnbProjectAssign.time_taken
                                      : ""}
                                  </p>
                                </div>
                              </div>
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                          <div className="row mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-xs-l-30  pd-lg-r-15 pd-xs-r-0 mg-r-0">
                            {this.state.tbnbProjectAssign ? (
                              <React.Fragment>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                                  <p className="az-profile-name-text pl-0">
                                    Cleaner Name
                                  </p>
                                  <p className="media-body">
                                    {this.state.tbnbProjectAssign
                                      .contractor_first_name !== undefined &&
                                    this.state.tbnbProjectAssign
                                      .contractor_last_name !== undefined
                                      ? this.state.tbnbProjectAssign
                                          .contractor_first_name +
                                        " " +
                                        this.state.tbnbProjectAssign
                                          .contractor_last_name
                                      : ""}
                                  </p>
                                </div>
                              </React.Fragment>
                            ) : (
                              ""
                            )}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                              <p className="az-profile-name-text pl-0">
                                Turnoverbnb Project ID
                              </p>
                              <p className="media-body">
                                {this.state.tbnbProjectDetail.project_id}
                              </p>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4 pd-lg-l-15 pd-xs-l-0">
                              <p className="az-profile-name-text pl-0">
                                Cleaning Details
                              </p>
                              <p className="media-body ">
                                <Link
                                  to={this.state.cleaning_url}
                                  target="_blank"
                                  className="hand-cursor showmore link-url"
                                >
                                  Show More (
                                  {this.state.tbnbProjectDetail.problem_count
                                    ? this.state.tbnbProjectDetail.problem_count
                                    : 0}
                                  )
                                </Link>
                              </p>
                            </div>
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {/* Turn over BNB End */}

                      {/* key cafe access start */}
                      {this.state.keyCafeFlag === 1 ? (
                        <>
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                            <h3 className="mg-l-0 mg-t-15 background-head">
                              {this.state.LOQRON_enable === 1 && stats === true
                                ? "LOQRON Access"
                                : "Key Cafe Access"}
                              <span className="d-inline-flex align-items-start justify-content-start infosize">
                                {this.state.instruction !== "<p><br></p>" ? (
                                  <img
                                    src={infoicon}
                                    className="mg-l-10"
                                    onClick={this.openInstructionPopup}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            </h3>

                            {global.userPermissions.checkPermission(
                              "key-cafe-access-edit"
                            ) &&
                            this.state.email &&
                            dateDiff ? (
                              <>
                                <div className="pd-b-15 d-flex flex-wrap justify-content-end bothBtn align-items-center swiperBtns alertsettingBtn">
                                  <div className="d-flex">
                                    <button
                                      className="btn-success ml-3 button-width"
                                      type="button"
                                      disabled={
                                        this.state.submitsendAccess
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        this.sendAccess();
                                        this.sendAccess1();
                                      }}
                                    >
                                      {this.state.submitsendAccess
                                        ? global.loader
                                        : this.state.keycafe_access_id
                                        ? "Update Access"
                                        : "Create Access"}
                                    </button>
                                    {this.state.keycafe_access_id ? (
                                      <button
                                        className="btn-success ml-3 button-width"
                                        type="button"
                                        disabled={
                                          this.state.submitrevokeAccess
                                            ? true
                                            : false
                                        }
                                        onClick={() => {
                                          this.revokeAccess();
                                          this.revokeAccess1();
                                        }}
                                      >
                                        {this.state.submitrevokeAccess
                                          ? global.loader
                                          : "Revoke Access"}
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </div>

                          {global.userPermissions.checkPermission(
                            "key-cafe-access-view"
                          ) ? (
                            <>
                              <div className="row col-12 pd-lg-l-15 pd-sm-l-30">
                                {stats === false && (
                                  <React.Fragment>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0">
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                          <p className="az-profile-name-text pl-0">
                                            Key Status
                                          </p>
                                          <p className="media-body">
                                            {(this.state.ref_table_id == 1 ||
                                              this.state.ref_table_id == 2 ||
                                              this.state.ref_table_id == 3 ||
                                              this.state.ref_table_id == 4 ||
                                              this.state.ref_table_id == 0) &&
                                            this.state.current_key_status ===
                                              "DROPOFF"
                                              ? "Key in Locker"
                                              : this.state.ref_table_id == 1 &&
                                                this.state.current_key_status !=
                                                  undefined &&
                                                this.state.current_key_status !=
                                                  ""
                                              ? "Key with Guest"
                                              : this.state.ref_table_id == 2 &&
                                                this.state.current_key_status !=
                                                  undefined &&
                                                this.state.current_key_status !=
                                                  ""
                                              ? "Key with Cleaner"
                                              : this.state.ref_table_id == 3 &&
                                                this.state.current_key_status !=
                                                  undefined &&
                                                this.state.current_key_status !=
                                                  ""
                                              ? "Key with Resident"
                                              : this.state.ref_table_id == 4 &&
                                                this.state.current_key_status !=
                                                  undefined &&
                                                this.state.current_key_status !=
                                                  ""
                                              ? "Key with Vistor"
                                              : ""}
                                          </p>
                                        </div>

                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                          <p className="az-profile-name-text pl-0">
                                            Status Time
                                          </p>
                                          <p className="media-body">
                                            {this.state.key_status_updated_time
                                              ? this.state
                                                  .key_status_updated_time
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0">
                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                          <p className="az-profile-name-text pl-0">
                                            Key Status
                                          </p>
                                          <p className="media-body">
                                            {(this.state.ref_table_id1 == 1 ||
                                              this.state.ref_table_id1 == 2 ||
                                              this.state.ref_table_id1 == 3 ||
                                              this.state.ref_table_id1 == 4 ||
                                              this.state.ref_table_id1 == 0) &&
                                            this.state.current_key_status1 ===
                                              "DROPOFF"
                                              ? "Key in Locker"
                                              : this.state.ref_table_id1 == 1 &&
                                                this.state
                                                  .current_key_status1 !=
                                                  undefined &&
                                                this.state
                                                  .current_key_status1 != ""
                                              ? "Key with Guest"
                                              : this.state.ref_table_id1 == 2 &&
                                                this.state
                                                  .current_key_status1 !=
                                                  undefined &&
                                                this.state
                                                  .current_key_status1 != ""
                                              ? "Key with Cleaner"
                                              : this.state.ref_table_id1 == 3 &&
                                                this.state
                                                  .current_key_status1 !=
                                                  undefined &&
                                                this.state
                                                  .current_key_status1 != ""
                                              ? "Key with Resident"
                                              : this.state.ref_table_id1 == 4 &&
                                                this.state
                                                  .current_key_status1 !=
                                                  undefined &&
                                                this.state
                                                  .current_key_status1 != ""
                                              ? "Key with Vistor"
                                              : ""}
                                          </p>
                                        </div>

                                        <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                          <p className="az-profile-name-text pl-0">
                                            Status Time
                                          </p>
                                          <p className="media-body">
                                            {this.state.key_status_updated_time1
                                              ? this.state
                                                  .key_status_updated_time1
                                              : ""}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )}
                              </div>

                              <div className="row col-12 pd-lg-l-15 pd-sm-l-30">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15">
                                    <h3 className="mg-l-0 mg-t-15">Guest</h3>
                                  </div>

                                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Key Start Time
                                      </p>
                                      <p className="media-body">
                                        {this.state.access_start_date &&
                                        this.state.access_start_time
                                          ? Moment(
                                              this.state.access_start_date +
                                                " " +
                                                this.state.access_start_time
                                            ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Key End Time
                                      </p>
                                      <p className="media-body">
                                        {this.state.access_end_date &&
                                        this.state.acess_end_time
                                          ? Moment(
                                              this.state.access_end_date +
                                                " " +
                                                this.state.acess_end_time
                                            ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        {this.state.LOQRON_enable === 1 &&
                                        stats === true
                                          ? "LOQRON Access Code"
                                          : "Key Cafe Access Code"}
                                      </p>
                                      <p className="media-body">
                                        {this.state.booking_code
                                          ? this.state.booking_code
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Key ID{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.keycafe_key_id
                                          ? this.state.keycafe_key_id
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-xs-l-0 pd-lg-l-15">
                                    <h3 className="mg-l-0 mg-t-15">Cleaner</h3>
                                  </div>

                                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Key Start Time
                                      </p>
                                      <p className="media-body">
                                        {this.state.access_start_date1 &&
                                        this.state.access_start_time1
                                          ? Moment(
                                              this.state.access_start_date1 +
                                                " " +
                                                this.state.access_start_time1
                                            ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Key End Time
                                      </p>
                                      <p className="media-body">
                                        {this.state.access_end_date1 &&
                                        this.state.acess_end_time1
                                          ? Moment(
                                              this.state.access_end_date1 +
                                                " " +
                                                this.state.acess_end_time1
                                            ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        {this.state.LOQRON_enable === 1 &&
                                        stats === true
                                          ? "LOQRON Access Code"
                                          : "Key Cafe Access Code"}
                                      </p>
                                      <p className="media-body">
                                        {this.state.booking_code1
                                          ? this.state.booking_code1
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Key ID{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.keycafe_key_id1
                                          ? this.state.keycafe_key_id1
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {/* key cafe access end */}

                      {/* igloo access start */}
                      {this.state.lock_type === 3 ? (
                        <>
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                            <h3 className="mg-l-0 mg-t-15 background-head">
                              Igloo Access
                              <span className="d-inline-flex align-items-start justify-content-start infosize">
                                {this.state.instruction !== "<p><br></p>" ? (
                                  <img
                                    src={infoicon}
                                    className="mg-l-10"
                                    onClick={this.openInstructionPopup}
                                  />
                                ) : (
                                  ""
                                )}
                              </span>
                            </h3>
                          </div>

                          {global.userPermissions.checkPermission(
                            "igloo-list"
                          ) ? (
                            <>
                              <div className="row col-12 pd-lg-l-15 pd-sm-l-30">
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15">
                                    <h3 className="mg-l-0 mg-t-15">Guest</h3>
                                  </div>

                                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Key Start Time
                                      </p>
                                      <p className="media-body">
                                        {this.state.access_start_date &&
                                        this.state.access_start_time
                                          ? Moment(
                                              this.state.access_start_date +
                                                " " +
                                                this.state.access_start_time
                                            ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Key End Time
                                      </p>
                                      <p className="media-body">
                                        {this.state.access_end_date &&
                                        this.state.acess_end_time
                                          ? Moment(
                                              this.state.access_end_date +
                                                " " +
                                                this.state.acess_end_time
                                            ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Igloo Access Code
                                      </p>
                                      <p className="media-body">
                                        {this.state.booking_code
                                          ? this.state.booking_code
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Lock ID{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.serial_number
                                          ? this.state.serial_number
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-xs-r-0 pd-xs-l-0 pd-lg-l-15">
                                    <h3 className="mg-l-0 mg-t-15">Cleaner</h3>
                                  </div>

                                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Key Start Time
                                      </p>
                                      <p className="media-body">
                                        {this.state.access_start_date1 &&
                                        this.state.access_start_time1
                                          ? Moment(
                                              this.state.access_start_date1 +
                                                " " +
                                                this.state.access_start_time1
                                            ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Key End Time
                                      </p>
                                      <p className="media-body">
                                        {this.state.access_end_date1 &&
                                        this.state.acess_end_time1
                                          ? Moment(
                                              this.state.access_end_date1 +
                                                " " +
                                                this.state.acess_end_time1
                                            ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Igloo Access Code
                                      </p>
                                      <p className="media-body">
                                        {this.state.booking_code1
                                          ? this.state.booking_code1
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Lock ID{" "}
                                      </p>
                                      <p className="media-body">
                                        {this.state.serial_number1
                                          ? this.state.serial_number1
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                      {/* igloo access end */}

                      {/* Latch Access ---------------------------------- */}
                      {global.userPermissions.checkPermission(
                        "latch-access-view"
                      ) &&
                        this.state.latchAccess &&
                        this.state.latchAccess.length > 0 && (
                          <>
                            <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                              <div className="background-head pd-b-15 d-flex justify-content-between align-items-center flex-wrap mg-b-30">
                                <h3 className="m-0">
                                  Latch Access
                                  <span className="d-inline-flex align-items-start justify-content-start infosize">
                                    {this.state.instruction !==
                                    "<p><br></p>" ? (
                                      <img
                                        src={infoicon}
                                        className="mg-l-10"
                                        onClick={this.openInstructionPopup}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </h3>
                              </div>
                            </div>
                            <div className="row col-12 pd-lg-l-15 pd-sm-l-30">
                              {this.state.latchAccess.map((item, index) => (
                                <div
                                  key={index}
                                  className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-"
                                >
                                  <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0 pd-lg-r-15">
                                    <h3 className="mg-l-0 mg-t-15">
                                      {item.ref_table_id == 1
                                        ? "Guest"
                                        : item.ref_table_id == 3
                                        ? "Cleaner"
                                        : ""}
                                    </h3>
                                  </div>
                                  <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0  pd-lg-r-30 pd-xs-l-0 pd-lg-l-15 pd-xs-r-0">
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Start Time
                                      </p>
                                      <p className="media-body">
                                        {item.start_time
                                          ? Moment(item.start_time).format(
                                              global.dateTimeFormat
                                            )
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        End Time
                                      </p>
                                      <p className="media-body">
                                        {item.end_time
                                          ? Moment(item.end_time).format(
                                              global.dateTimeFormat
                                            )
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Door Name
                                      </p>
                                      <p className="media-body">
                                        {item.door_name}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Access Code
                                      </p>
                                      <p className="media-body">
                                        {item.access_code
                                          ? item.access_code
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Access Status
                                      </p>
                                      <p className="media-body">
                                        {item.status_name
                                          ? item.status_name
                                          : ""}
                                      </p>
                                    </div>
                                    <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4-">
                                      <p className="az-profile-name-text pl-0">
                                        Created at
                                      </p>
                                      <p className="media-body">
                                        {item.token_generated_at
                                          ? Moment(
                                              item.token_generated_at
                                            ).format(global.dateTimeFormat)
                                          : ""}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      {/* Latch Access ---------------------------------- */}
                      {global.userPermissions.checkPermission(
                        "guests-pricing-data-view"
                      ) && (
                        <>
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                            <h3 className="mg-l-0 mg-t-15 background-head">
                              Resolution Fees
                            </h3>
                          </div>
                          <div className="row mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-45 pd-xs-l-30 pd-sm-l-45 pd-sm-r-15  pd-lg-r-15 pd-xs-r-0 mg-r-0">
                            <div className="table-responsive resolution-fees-new">
                              <Table hover className="">
                                <thead>
                                  <tr>
                                    <th className="w-30">Resolution Item</th>
                                    <th className="w-35">Amount</th>
                                    <th className="w-10">OH %</th>
                                    <th className="w-10">Resident %</th>
                                    <th className="w-10 text-left">
                                      Property %
                                    </th>
                                    <th className="w-10 text-left">
                                      Payment Received
                                    </th>
                                    <th className="w-30 text-left">
                                      Payment Received Date
                                    </th>

                                    <th className="w-40 text-right">OH $</th>
                                    <th className="w-40 text-right">
                                      Resident $
                                    </th>
                                    <th className="w-40 text-right">
                                      Property $
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.reservationResolutionFeeData &&
                                  this.state.reservationResolutionFeeData
                                    .length > 0 ? (
                                    this.state.reservationResolutionFeeData.map(
                                      (option, i) => (
                                        <tr key={i}>
                                          <td className="w-30">
                                            {option.resolution_item
                                              ? option.resolution_item
                                              : ""}
                                          </td>
                                          <td className="w-35">
                                            {option.resolution_amount ? (
                                              <NumberFormat
                                                value={parseFloat(
                                                  option.resolution_amount
                                                )
                                                  .toFixed(2)
                                                  .replace(/\.00$/, "")
                                                  .replace(
                                                    /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                    ""
                                                  )}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td className="w-10">
                                            {option.to_oh ? (
                                              <NumberFormat
                                                value={parseFloat(option.to_oh)
                                                  .toFixed(2)
                                                  .replace(/\.00$/, "")
                                                  .replace(
                                                    /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                    ""
                                                  )}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={"%"}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </td>

                                          <td className="w-10">
                                            {option.to_resident ? (
                                              <NumberFormat
                                                value={parseFloat(
                                                  option.to_resident
                                                )
                                                  .toFixed(2)
                                                  .replace(/\.00$/, "")
                                                  .replace(
                                                    /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                    ""
                                                  )}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={"%"}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </td>

                                          <td className="w-10">
                                            {option.to_building ? (
                                              <NumberFormat
                                                value={parseFloat(
                                                  option.to_building
                                                )
                                                  .toFixed(2)
                                                  .replace(/\.00$/, "")
                                                  .replace(
                                                    /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                    ""
                                                  )}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                suffix={"%"}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td className="w-10">
                                            {option.payment_received &&
                                            option.payment_received === 1
                                              ? "Yes"
                                              : "No"}
                                          </td>
                                          <td className="w-30">
                                            {option.payment_received_date_string
                                              ? Moment(
                                                  option.payment_received_date_string
                                                ).format(global.dateFormat)
                                              : ""}
                                          </td>
                                          <td className="w-40 text-right">
                                            {option.to_oh_amount ? (
                                              <NumberFormat
                                                value={parseFloat(
                                                  option.to_oh_amount
                                                )
                                                  .toFixed(2)
                                                  .replace(/\.00$/, "")
                                                  .replace(
                                                    /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                    ""
                                                  )}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td className="w-40 text-right">
                                            {option.to_resident_amount ? (
                                              <NumberFormat
                                                value={parseFloat(
                                                  option.to_resident_amount
                                                )
                                                  .toFixed(2)
                                                  .replace(/\.00$/, "")
                                                  .replace(
                                                    /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                    ""
                                                  )}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td className="w-40 text-right">
                                            {option.to_building_amount ? (
                                              <NumberFormat
                                                value={parseFloat(
                                                  option.to_building_amount
                                                )
                                                  .toFixed(2)
                                                  .replace(/\.00$/, "")
                                                  .replace(
                                                    /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                    ""
                                                  )}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                prefix={"$"}
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : (
                                    <tr>
                                      <td
                                        colSpan="9"
                                        align="center"
                                        className="text-center"
                                      >
                                        There are no resolution fees added in
                                        the system.
                                      </td>
                                    </tr>
                                  )}
                                  {this.state.total_resolution_fee ? (
                                    <tr>
                                      <td>
                                        <b>Total</b>
                                      </td>
                                      <td>
                                        <b>
                                          {this.state.total_resolution_fee ? (
                                            <NumberFormat
                                              value={parseFloat(
                                                this.state.total_resolution_fee
                                              )
                                                .toFixed(2)
                                                .replace(/\.00$/, "")
                                                .replace(
                                                  /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                  ""
                                                )}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"$"}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </b>
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>

                                      <td></td>
                                      <td></td>
                                      <td className="text-right">
                                        <b>
                                          {this.state.to_oh_total ? (
                                            <NumberFormat
                                              value={parseFloat(
                                                this.state.to_oh_total
                                              )
                                                .toFixed(2)
                                                .replace(/\.00$/, "")
                                                .replace(
                                                  /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                  ""
                                                )}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"$"}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </b>
                                      </td>
                                      <td className="text-right">
                                        <b>
                                          {this.state.to_resident_total ? (
                                            <NumberFormat
                                              value={parseFloat(
                                                this.state.to_resident_total
                                              )
                                                .toFixed(2)
                                                .replace(/\.00$/, "")
                                                .replace(
                                                  /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                  ""
                                                )}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"$"}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </b>
                                      </td>
                                      <td className="text-right">
                                        <b>
                                          {this.state.to_building_total ? (
                                            <NumberFormat
                                              value={parseFloat(
                                                this.state.to_building_total
                                              )
                                                .toFixed(2)
                                                .replace(/\.00$/, "")
                                                .replace(
                                                  /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                                  ""
                                                )}
                                              displayType={"text"}
                                              thousandSeparator={true}
                                              prefix={"$"}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </b>
                                      </td>
                                    </tr>
                                  ) : (
                                    ""
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </>
                      )}
                      {global.userPermissions.checkPermission(
                        "guests-pricing-data-view"
                      ) && (
                        <>
                          <div className="col-lg-12 pd-l-30 pd-r-30 pd-xs-l-15 pd-lg-l-30 pd-xs-r-0 pd-lg-r-15 pd-sm-l-30 pd-sm-r-15">
                            <h3 className="mg-l-0 mg-t-15 background-head">
                              Total Payout
                            </h3>
                          </div>
                          <div className="row col-md-12 mg-xs-t-0 mg-sm-t-0 mg-md-t-0 mg-lg-t-0 mg-xl-t-0 pd-lg-l-30 pd-sm-l-30 pd-r-0 mg-r-0">
                            {/* <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                                <p className="az-profile-name-text pl-0">
                                  OH Payout{" "}
                                  <span className="reservation-optional">
                                    {this.state.oh_earning_resolution ||
                                    this.state.to_oh_total ? (
                                      <>
                                        {" "}
                                        (
                                        {this.state.total_nightly_rate_to_oh &&
                                        this.state.total_nightly_rate
                                          ? "Total Nightly Rate *" +
                                            this.state.total_nightly_rate_to_oh +
                                            "%"
                                          : ""}
                                        {this.state.cleaning_fee_to_oh &&
                                        this.state.cleaning_fee
                                          ? " + Cleaning Fee *" +
                                            this.state.cleaning_fee_to_oh +
                                            "%"
                                          : ""}
                                        {this.state.channel_fee_to_oh &&
                                        this.state.host_service_fee
                                          ? " - Channel Fee *" +
                                            this.state.channel_fee_to_oh +
                                            "%"
                                          : ""}
                                        {this.state.to_oh_total
                                          ? " + OH Resolution Fee"
                                          : ""}
                                        )
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {this.state.total_oh_earning ? (
                                      <>
                                        {" = "}
                                        {this.state.total_nightly_rate_to_oh &&
                                        this.state.total_nightly_rate
                                          ? "($" +
                                            this.state.total_nightly_rate +
                                            "* " +
                                            this.state.total_nightly_rate_to_oh +
                                            "%)"
                                          : ""}
                                        {this.state.cleaning_fee_to_oh &&
                                        this.state.cleaning_fee
                                          ? " + ($" +
                                            this.state.cleaning_fee +
                                            "* " +
                                            this.state.cleaning_fee_to_oh +
                                            "%)"
                                          : ""}
                                        {this.state.channel_fee_to_oh &&
                                        this.state.host_service_fee
                                          ? " - ($" +
                                            this.state.host_service_fee +
                                            "* " +
                                            this.state.channel_fee_to_oh +
                                            "%)"
                                          : ""}
                                        {this.state.to_oh_total
                                          ? " + $" + this.state.to_oh_total
                                          : ""}

                                        {" = "}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </p>
                                <p className="media-body">
                                  {this.state.oh_earning_resolution
                                    ? "$" +
                                      global.onKeyPressEvent.numberWithCommasForDecimal(
                                        this.state.oh_earning_resolution.replace(
                                          /\.00$/,
                                          ""
                                        )
                                      )
                                    : ""}
                                </p>
                              </div> */}
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Resident Payout{" "}
                                <span className="reservation-optional">
                                  {(this.state.resident_earning_resolution ||
                                    this.state.to_resident_total) &&
                                  this.state.resident_earning_resolution > 0 ? (
                                    <>
                                      {" "}
                                      (
                                      {this.state
                                        .total_nightly_rate_to_resident &&
                                      this.state.total_nightly_rate
                                        ? "Total Nightly Rate *" +
                                          this.state
                                            .total_nightly_rate_to_resident +
                                          "%"
                                        : ""}
                                      {this.state.cleaning_fee_to_resident &&
                                      this.state.cleaning_fee
                                        ? "+ Cleaning Fee *" +
                                          this.state.cleaning_fee_to_resident +
                                          "%"
                                        : ""}
                                      {this.state.channel_fee_to_resident &&
                                      this.state.host_service_fee
                                        ? " - Channel Fee *" +
                                          this.state.channel_fee_to_resident +
                                          "%"
                                        : ""}
                                      {this.state.to_resident_total
                                        ? " + Resident Resolution Fee"
                                        : ""}
                                      )
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.resident_earning &&
                                  this.state.resident_earning > 0 ? (
                                    <>
                                      {" = "}
                                      {this.state
                                        .total_nightly_rate_to_resident &&
                                      this.state.total_nightly_rate
                                        ? "($" +
                                          this.state.total_nightly_rate +
                                          "* " +
                                          this.state
                                            .total_nightly_rate_to_resident +
                                          "%)"
                                        : ""}
                                      {this.state.cleaning_fee_to_resident &&
                                      this.state.cleaning_fee
                                        ? " + ($" +
                                          this.state.cleaning_fee +
                                          "* " +
                                          this.state.cleaning_fee_to_resident +
                                          "%)"
                                        : ""}
                                      {this.state.channel_fee_to_resident &&
                                      this.state.host_service_fee
                                        ? " - ($" +
                                          this.state.host_service_fee +
                                          "* " +
                                          this.state.channel_fee_to_resident +
                                          "%)"
                                        : ""}
                                      {this.state.to_resident_total
                                        ? " + $" + this.state.to_resident_total
                                        : ""}
                                      {" = "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </p>
                              <p className="media-body">
                                {this.state.resident_earning_resolution &&
                                this.state.resident_earning_resolution > 0 ? (
                                  <NumberFormat
                                    value={parseFloat(
                                      this.state.resident_earning_resolution
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                      .replace(
                                        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                        ""
                                      )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>

                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-4">
                              <p className="az-profile-name-text pl-0">
                                Property Payout{" "}
                                <span className="reservation-optional">
                                  {(this.state.property_earning_resolution ||
                                    this.state.to_building_total) &&
                                  this.state.total_property_earning > 0 ? (
                                    <>
                                      {" "}
                                      (
                                      {this.state
                                        .total_nightly_rate_to_property &&
                                      this.state.total_nightly_rate
                                        ? "Total Nightly Rate *" +
                                          this.state
                                            .total_nightly_rate_to_property +
                                          "%"
                                        : ""}
                                      {this.state.cleaning_fee_to_property &&
                                      this.state.cleaning_fee
                                        ? " + Cleaning Fee *" +
                                          this.state.cleaning_fee_to_property +
                                          "%"
                                        : ""}
                                      {this.state.channel_fee_to_property &&
                                      this.state.host_service_fee
                                        ? " - Channel Fee *" +
                                          this.state.channel_fee_to_property +
                                          "%"
                                        : ""}
                                      {this.state.to_building_total
                                        ? " + Property Resolution Fee"
                                        : ""}
                                      )
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.total_property_earning &&
                                  this.state.total_property_earning > 0 ? (
                                    <>
                                      {" = "}
                                      {this.state
                                        .total_nightly_rate_to_property &&
                                      this.state.total_nightly_rate
                                        ? "($" +
                                          this.state.total_nightly_rate +
                                          "* " +
                                          this.state
                                            .total_nightly_rate_to_property +
                                          "%)"
                                        : ""}
                                      {this.state.cleaning_fee_to_property &&
                                      this.state.cleaning_fee
                                        ? " + ($" +
                                          this.state.cleaning_fee +
                                          "* " +
                                          this.state.cleaning_fee_to_property +
                                          "%)"
                                        : ""}
                                      {this.state.channel_fee_to_property &&
                                      this.state.host_service_fee
                                        ? " - ($" +
                                          this.state.host_service_fee +
                                          "* " +
                                          this.state.channel_fee_to_property +
                                          "%)"
                                        : ""}
                                      {this.state.to_building_total
                                        ? " + $" + this.state.to_building_total
                                        : ""}
                                      {" = "}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </p>
                              <p className="media-body">
                                {this.state.property_earning_resolution &&
                                this.state.property_earning_resolution > 0 ? (
                                  <NumberFormat
                                    value={parseFloat(
                                      this.state.property_earning_resolution
                                    )
                                      .toFixed(2)
                                      .replace(/\.00$/, "")
                                      .replace(
                                        /[&#,+()$~%'":*?<>{}A-Za-z]/gi,
                                        ""
                                      )}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                  />
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      )}
                      {/* </>
                        )} */}
                    </div>
                  </div>

                  <div
                    className={
                      this.state.activity_box == true
                        ? "activity_section d-none"
                        : "activity_section pd-r-30"
                    }
                  >
                    <span
                      variant="outline-light"
                      className="hand-cursor activity_close d-none"
                    >
                      <img
                        src={closeIcon}
                        className="closeicon m-0"
                        onClick={() => this.closeActivity()}
                      />
                    </span>
                    <ReservationActivity
                      reservation_slug={this.state.reservation_slug}
                      property_slug={this.state.property_slug}
                      outside_property_slug={this.state.outside_property_slug}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <Modal
          show={this.state.toggleValue}
          className="modal_right_fixed effect-slide-in-right"
        >
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.activityStream()}
              >
                {global.closee}
              </span>
              <ReservationActivity
                reservation_slug={this.state.reservation_slug}
                property_slug={this.state.property_slug}
                outside_property_slug={this.state.outside_property_slug}
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.deleteReservationModal} centered>
          <Modal.Body className="width_402 p-4 border_radius_14">
            <div className="text-center">
              <span
                className="welcomeClose"
                onClick={() => this.closedeleteReservationModal()}
              >
                {global.closee}
              </span>
              <img src={modaldeleteicon} alt="modal-delete-icon" />
              <h3>Delete the Option?</h3>
              <p>
                Do you really want to delete this option? This process cannot be
                undone.
              </p>
              <div className="d-flex justify-content-center mt-3 row">
                <button
                  type="button"
                  className="m-0 btn btn-outline-light btn-block"
                  onClick={() => this.closedeleteReservationModal()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="m-0 btn btn btn-danger btn-block"
                  onClick={() => this.deleteReservation(this.state.slug)}
                  disabled={this.state.isSubmit ? true : false}
                >
                  {this.state.isSubmit ? global.loader : "Delete"}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.instructionPopup}
          centered
          className="modal-large arrivalinstructionmodalbox"
        >
          <Modal.Body className="width_402 p-4 border_radius_14 instruction_key_cafe">
            <div className="">
              <span
                className="welcomeClose"
                onClick={() => this.closeInstructionPopup()}
              >
                {global.closee}
              </span>

              <h3 className="text-center">
                {this.state.lock_type === 3
                  ? "Igloo Access"
                  : this.state.lock_type === 1
                  ? this.state.LOQRON_enable === 1 && stats === true
                    ? "LOQRON Access"
                    : "Key Cafe Access"
                  : this.state.lock_type === 2
                  ? "Latch Access"
                  : ""}
              </h3>
              <div className="instructionbody">
                {this.state.instruction
                  ? renderHTML(this.state.instruction)
                  : ""}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.arrivalinstructionPopup}
          centered
          className="modal-large arrivalinstructionmodalbox"
        >
          <Modal.Body className="width_402 p-4 border_radius_14 instruction_key_cafe">
            <div className="">
              <span
                className="welcomeClose"
                onClick={() => this.closeArrivalInstructionPopup()}
              >
                {global.closee}
              </span>
              <h3 className="text-center">Arrival Instructions</h3>
              <div className="instructionbody">
                {this.state.arrival_instruction
                  ? renderHTML(this.state.arrival_instruction)
                  : ""}
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.isGuestImageModal}
          centered
          className="modal-large agreementdocModal"
        >
          <Modal.Body className="width_402 p-4 border_radius_14 ">
            <div className="">
              {" "}
              <span
                className="welcomeClose"
                onClick={() => this.showGuestImages()}
              >
                {global.closee}
              </span>
              {this.state.front_image_url &&
                this.state.back_image_url &&
                this.state.selfi_image_url && (
                  <>
                    <div className="row">
                      <div className="col-md-4">
                        <h3> Front Image</h3>
                        <img
                          src={this.state.front_image_url}
                          alt="Front_image"
                          className="doc_upld_guest"
                          onClick={(e) =>
                            window.open(this.state.front_image_url, "_blank")
                          }
                        />
                      </div>

                      <div className="col-md-4">
                        <h3> Back Image</h3>
                        <img
                          src={this.state.back_image_url}
                          alt="Back_image"
                          className="doc_upld_guest"
                          onClick={(e) =>
                            window.open(this.state.back_image_url, "_blank")
                          }
                        />
                      </div>

                      <div className="col-md-4">
                        <h3> Selfie Image</h3>
                        <img
                          src={this.state.selfi_image_url}
                          alt="Selfi_image"
                          className="selfi_img"
                          onClick={(e) =>
                            window.open(this.state.selfi_image_url, "_blank")
                          }
                        />
                      </div>
                    </div>
                    <div>
                      <p>
                        Verified on: &nbsp;
                        {this.state.activated_at
                          ? moment(moment.utc(this.state.activated_at).toDate())
                              .local()
                              .format(global.dateTimeFormat)
                          : ""}
                      </p>
                    </div>
                    <div>
                      <p>
                        Guest agreement PDF:{" "}
                        {receipt_pdf_url !== null ? (
                          <img
                            src={pdfIcon}
                            alt="receipt_pdf"
                            className="receipt_pdf_guest"
                            onClick={(e) =>
                              window.open(receipt_pdf_url, "_blank")
                            }
                          />
                        ) : (
                          "N/A"
                        )}
                      </p>
                    </div>
                  </>
                )}
            </div>
          </Modal.Body>
        </Modal>
      </main>
    );
  }
}

export default ReservationView;
